/* eslint-disable no-useless-concat */
import { httpClient } from "../../../../../utils/HttpClient"

// GET ------------------------------------------------------------
const getTaskSearchFetch = async (value) => {
    try {
        const result = await httpClient.post("task/get-task-search", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPriorityAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-priority-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getTaskProcessAllFetch = async (value) => {
    try {
        const result = await httpClient.post("category/get-task-process-all", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getEmployeeAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-employee-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getTaskOpenStatusAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-task-open-status-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getTaskAllFetch = async (value) => {
    try {
        const result = await httpClient.post("category/get-task-all", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getReasonsInspectTaskFailureByIdFetch = async (value) => {
    try {
        const result = await httpClient.post("task/get-reasons-inspect-task-failure-by-id", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

// INSERT ---------------------------------------------------------
const insertTaskFetch = async (value) => {
    try {
        const result = await httpClient.post("task/insert-task", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertTransactionPaidEmployeeFetch = async (master, list) => {
    try {
        const result = await httpClient.post("payment/insert-transaction-paid-employee", { master, list })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

// UPDATE ---------------------------------------------------------
const updateTaskByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-task-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateTaskOpenStatusByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-task-open-status-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateTaskEployeeCostByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-task-employee-cost-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateTaskOperationCostByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-task-operation-cost-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateTaskOperationDiscountByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-task-operation-discount-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateTaskEstimateTimeByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-task-estimate-time-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateTaskProgressPercentageByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-task-progress-percent-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateTaskNameByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-task-name-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateTaskOwnerByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-task-owner-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateTaskEmployeePositionByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-task-employee-position-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateTaskDragDropByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-task-drag-drop-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateTaskOpenPaidEmployeeByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-task-open-paid-employee-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateTaskProcessByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-task-process-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateReasonsInspectTaskFailureByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-reasons-inspect-task-failure-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateReasonsInspectTaskFailureImageByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-reasons-inspect-task-failure-image-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateReasonsInspectTaskFailureVideoByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-reasons-inspect-task-failure-video-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateReasonsInspectTaskFailureFileByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-reasons-inspect-task-failure-file-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateAmountTaskFailureByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("task/update-amount-task-failure-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

// DELETE ---------------------------------------------------------
const deleteTaskByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("task/delete-task-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteReasonsInspectTaskFailureByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("task/delete-reasons-inspect-task-failure-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteReasonsInspectTaskFailureImageByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("task/delete-reasons-inspect-task-failure-image-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteReasonsInspectTaskFailureVideoByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("task/delete-reasons-inspect-task-failure-video-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteReasonsInspectTaskFailureFileByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("task/delete-reasons-inspect-task-failure-file-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getTaskSearchFetch,
    getPriorityAllFetch,
    getTaskProcessAllFetch,
    getEmployeeAllFetch,
    getTaskOpenStatusAllFetch,
    getTaskAllFetch,
    getReasonsInspectTaskFailureByIdFetch,

    // insert
    insertTaskFetch,
    insertTransactionPaidEmployeeFetch,

    // update
    updateTaskByIdFetch,
    updateTaskOpenStatusByIdFetch,
    updateTaskOpenPaidEmployeeByIdFetch,
    updateTaskEployeeCostByIdFetch,
    updateTaskOperationCostByIdFetch,
    updateTaskOperationDiscountByIdFetch,
    updateTaskEstimateTimeByIdFetch,
    updateTaskDragDropByIdFetch,
    updateTaskProgressPercentageByIdFetch,
    updateTaskNameByIdFetch,
    updateTaskOwnerByIdFetch,
    updateTaskEmployeePositionByIdFetch,
    updateTaskProcessByIdFetch,
    updateReasonsInspectTaskFailureByIdFetch,
    updateReasonsInspectTaskFailureImageByIdFetch,
    updateReasonsInspectTaskFailureVideoByIdFetch,
    updateReasonsInspectTaskFailureFileByIdFetch,
    updateAmountTaskFailureByIdFetch,

    // delete
    deleteTaskByIdFetch,
    deleteReasonsInspectTaskFailureByIdFetch,
    deleteReasonsInspectTaskFailureImageByIdFetch,
    deleteReasonsInspectTaskFailureVideoByIdFetch,
    deleteReasonsInspectTaskFailureFileByIdFetch,
}
