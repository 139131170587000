/* eslint-disable import/no-anonymous-default-export */
import { 
    HTTP_SEARCH_BLOG_TITLE_FETCHING, 
    HTTP_SEARCH_BLOG_TITLE_SUCCESS, 
    HTTP_SEARCH_BLOG_TITLE_FAILED 
} from "../constants"

const initialState = {
    title : null,
    isActive: false
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

        case HTTP_SEARCH_BLOG_TITLE_FETCHING:
            return { ...state, resultSearchBlogTitle : null, isFetching : true, isError : false }
            
        case HTTP_SEARCH_BLOG_TITLE_SUCCESS:
            return { ...state, resultSearchBlogTitle : payload, isFetching : false, isError : false }

        case HTTP_SEARCH_BLOG_TITLE_FAILED:
            return { ...state, resultSearchBlogTitle : null, isFetching : false, isError : true }

        default:
            return state
    }
}
