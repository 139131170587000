/* eslint-disable no-useless-concat */
import { httpClient } from "../../../../../utils/HttpClient"

// GET --------------------------------------------------
const getDocumentSearchFetch = async (value) => {
    try {
        const result = await httpClient.post("document/get-document-search", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getDocumentItemSearchFetch = async (value) => {
    try {
        const result = await httpClient.post("document/get-document-item-search", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getDocumentOpenTypeAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-document-open-type-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getDocumentItemTypeAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-document-item-type-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getDocumentOpenStatusAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-document-open-status-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getDocumentTypeAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-document-type-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getDocumentTaskByIdFetch = async (value) => {
    try {
        const result = await httpClient.post("document/get-document-task-by-id", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getDocumentCaseByIdFetch = async (value) => {
    try {
        const result = await httpClient.post("document/get-document-case-by-id", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCountDocumentByTypeIdFetch = async (value) => {
    try {
        const result = await httpClient.post("document/get-count-document--by-type-id", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getGenerateDocumentFetch = async (data, type) => {
    try {
        const result = await httpClient.post("document/generate-document", { data, type })
        if (result.data.isSuccess) {
            return result.data.url
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

// INSERT -----------------------------------------------
const insertDocumentFetch = async (document, document_item) => {
    try {
        const result = await httpClient.post("document/insert-document", { document, document_item })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertDocumentItemByIdFetch = async (value) => {
    try {
        const result = await httpClient.post("document/insert-document-item-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertSingleDocumentItemByIdFetch = async (value) => {
    try {
        const result = await httpClient.post("document/insert-single-document-item-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

// UPDATE -----------------------------------------------
const updateDocumentByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("document/update-document-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateDocumentItemByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("document/update-document-item-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateDocumentItemDragDropByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("document/update-document-item-drag-drop-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

// DELETE -----------------------------------------------
const deleteDocumentByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("document/delete-document-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteDocumentItemByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("document/delete-document-item-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getDocumentSearchFetch,
    getDocumentItemSearchFetch,
    getDocumentOpenTypeAllFetch,
    getDocumentItemTypeAllFetch,
    getDocumentOpenStatusAllFetch,
    getDocumentTypeAllFetch,
    getDocumentTaskByIdFetch,
    getDocumentCaseByIdFetch,
    getCountDocumentByTypeIdFetch,
    getGenerateDocumentFetch,

    // insert
    insertDocumentFetch,
    insertDocumentItemByIdFetch,
    insertSingleDocumentItemByIdFetch,

    // update,
    updateDocumentByIdFetch,
    updateDocumentItemByIdFetch,
    updateDocumentItemDragDropByIdFetch,

    // delete
    deleteDocumentByIdFetch,
    deleteDocumentItemByIdFetch
}