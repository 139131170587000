import "./styles/App.css"
import "./styles/App.less"
import { ConfigProvider } from 'antd'
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom"
import themeAntd from './styles/themeAntd'

// import Home from "./modules/home"
// import Login from "./modules/login"
// import Register from "./modules/register"
// import LoginDetect from "./modules/login/components/loginDetect"
// import Account from "./modules/account"
// import BlogManage from "./modules/blog/blogManage"
// import Blog from "./modules/blog"
// import PrivacyPolicy from "./modules/policy/components/privacyPolicy"
// import ServiceCancelPolicy from "./modules/policy/components/serviceCancelPolicy"
// import RefundPolicy from "./modules/policy/components/refundPolicy"
// import TermsBeingInstructor from "./modules/policy/components/termsBeingInstructor"
// import TermsOfUse from "./modules/policy/components/termsOfUse"

import LoginERP from "./modules/erp/login"
import ERP from "./modules/erp"

const App = ({ authorized, firebase, user }) => (
    <ConfigProvider theme={{ token: themeAntd }}>
        <Router>
            <Routes>
                {/* <Route exact path="/" element={<Home authorized={authorized} firebase={firebase} user={user} />} />
                <Route exact path="/login-detect" Component={() => (<LoginDetect authorized={authorized} firebase={firebase} user={user} />)} />
                <Route exact path="/register" Component={Register} />
                <Route exact path="/account" Component={() => (<Account authorized={authorized} firebase={firebase} user={user} />)} />
                <Route exact path="/blog-manage" element={<BlogManage authorized={authorized} firebase={firebase} user={user} />} />
                <Route exact path="/blog" element={<Blog authorized={authorized} firebase={firebase} user={user} />} />
                <Route exact path="/privacy-policy" Component={() => (<PrivacyPolicy firebase={firebase} user={user} />)} />
                <Route exact path="/service-cancel-policy" Component={() => (<ServiceCancelPolicy firebase={firebase} user={user} />)} />
                <Route exact path="/refund-policy" Component={() => (<RefundPolicy firebase={firebase} user={user} />)} />
                <Route exact path="/terms-being-instructor" Component={() => (<TermsBeingInstructor firebase={firebase} user={user} />)} />
                <Route exact path="/terms-of-use" Component={() => (<TermsOfUse firebase={firebase} user={user} />)} /> */}

                <Route exact path="/" Component={() => (<LoginERP />)} />
                <Route exact path="/erp" Component={ERP} />

                <Route path="/:id">Page not found!</Route>
            </Routes>
        </Router>
    </ConfigProvider>
)

export default App
