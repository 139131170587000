/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef, createContext, useContext, useMemo, useCallback } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Select,
    InputNumber,
    theme,
    Steps,
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import {
    deleteDocumentItemByIdFetch,
    getDocumentTypeAllFetch,
    getDocumentCaseByIdFetch,
    getDocumentItemTypeAllFetch,
    getDocumentOpenStatusAllFetch,
    getDocumentOpenTypeAllFetch,
    getDocumentSearchFetch,
    getDocumentTaskByIdFetch,
    insertDocumentFetch,
    updateDocumentByIdFetch,
    deleteDocumentByIdFetch,
    updateDocumentItemByIdFetch,
    getDocumentItemSearchFetch,
    getGenerateDocumentFetch,
    // insertDocumentItemFetch,
    insertDocumentItemByIdFetch,
    updateDocumentItemDragDropByIdFetch,
    getCountDocumentByTypeIdFetch,
    insertSingleDocumentItemByIdFetch
} from './API'
import { getBankAccountPaymentFetch, getProjectAllFetch } from '../employeeCost/API'
import { getCustomerAllFetch, getPaymentInstallmentAllFetch, getPaymentProcessByIdFetch, getProjectPhaseByIdFetch } from '../project/API'
import dayjs from 'dayjs'
import {
    DndContext,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { HolderOutlined } from '@ant-design/icons'
import { CSS } from '@dnd-kit/utilities'

import "./css/index.css"
import { calculateDaysDifference } from '../../../../common/mamager/DateMamager'

const { TextArea } = Input
const { Option } = Select
const { RangePicker } = DatePicker
const { Column } = Table
const formatDate = "DD/MM/YYYY"

const formDocumentDefault = {
    name: undefined,
    createdAt: undefined,
    documentId: undefined,
    documentCode: undefined,
    documentTypeId: undefined,
    projectId: undefined,
    documentOpenTypeId: undefined,
    documentItemTypeId: undefined,
    documentOpenStatusId: undefined,
    paymentInstallmentId: undefined,
    paymentProcessId: undefined,
    projectPhaseId: undefined,
    isActive: undefined
}

export default function ManageDocument() {

    const [document, setDocument] = useState([])
    const documentItemRef = useRef([])
    const [documentItem, setDocumentItem] = useState([])

    const [documentLoading, setDocumentLoading] = useState(false)
    const [documentItemLoading, setDocumentItemLoading] = useState(false)

    const [documentCreate, setDocumentCreate] = useState(formDocumentDefault)
    const groupedDocumentRef = useRef(null)
    const docPdfRef = useRef(null)

    const [formDocument] = Form.useForm()
    const [formDocumentSearch] = Form.useForm()

    const [formDocumentItem] = Form.useForm()
    const [formDocumentItemSearch] = Form.useForm()

    const [pageDocumentCurrent, setPageDocumentCurrent] = useState(1)

    const [isDisplayCustomerInfo, setIsDisplayCustomerInfo] = useState(undefined)

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const [modalDocumentItem, setModalDocumentItem] = useState({
        isShow: false,
        title: null
    })

    const [modalDocumentPreview, setModalDocumentPreview] = useState({
        isShow: false,
        title: null
    })

    const [documentOpenType, setDocumentOpenType] = useState([])
    const [documentItemType, setDocumentItemType] = useState([])
    const [documentOpenStatus, setDocumentOpenStatus] = useState([])
    const [customer, setCustomer] = useState([])
    const [paymentProcess, setPaymentProcess] = useState([])
    const [paymentInstallment, setPaymentInstallment] = useState([])
    const [projectPhase, setProjectPhase] = useState([])

    const documentTypeRef = useRef([])
    const bankAccountPaymentRef = useRef(null)
    const projectRef = useRef([])

    const [documentGroupItem, setDocumentGroupItem] = useState(
        Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i))
    )

    const pjTargetRef = useRef({
        projectId: null,
        projectName: null,
        projectTypeId: null,
        projectTypeName: null,
        customerInfo: null,
        packageInfo: null,
        paymentInfo: null
    })

    const auth = JSON.parse(sessionStorage.getItem('auth'))
    // console.log("auth : ", auth)

    const documentItemPreparation = async (document) => {
        let findProject = projectRef.current.filter(fill => fill.projectId === document.projectId)[0]

        setIsDisplayCustomerInfo(document.isDisplayCustomerInfo)

        await getProjectPhaseById(document.projectId)
        await getPaymentProcessById(document?.payment_process?.paymentInstallmentId)

        pjTargetRef.current = {
            projectId: document.projectId,
            projectName: findProject.name,
            projectTypeId: findProject.projectTypeId,
            projectTypeName: findProject.project_type.name,
            developmentDuration: dayDiff(findProject.developmentStart, findProject.developmentEnd),
            customerInfo: findProject.customer,
            packageInfo: findProject.package,
            paymentInfo: findProject.payment
        }

        let obj = {
            documentId: document.documentId,
            documentCode: document.documentCode,
            documentOpenTypeId: document.documentOpenTypeId,
            documentItemTypeId: document.documentItemTypeId,
            documentOpenStatusId: document.documentOpenStatusId,
            paymentProcessId: document.paymentProcessId,
            paymentInstallmentId: document?.payment_process?.paymentInstallmentId,
            documentTypeId: document.documentTypeId,
            projectPhaseId: document.projectPhaseId,
            name: document.name,
            isActive: document.isActive,
            createdAt: moment(document.createdAt).format(formatDate),
            projectId: document.projectId,
        }
        setDocumentCreate(obj)

        const docItem = await reloadDocumentItem(document.documentId)

        return {
            docItem,
            docOption: obj
        }
    }

    useEffect(() => {

    }, [documentCreate])

    const getDocumentBySearch = async (search) => {
        setDocumentLoading(true)

        const result = await getDocumentSearchFetch(search)
        // console.log("search : ", search)
        // console.log("getDocumentSearchFetch : ", result)

        let tempDocument = [];
        result?.map((val, index) => {
            tempDocument.push({
                index: (index + 1),
                name: val.name ?? "-",

                documentOpenTypeId: val.documentOpenTypeId ?? "-",
                documentOpenType: val?.document_open_type?.name ?? "-",

                documentItemTypeId: val.documentItemTypeId ?? "-",
                documentItemType: val?.document_item_type?.name ?? "-",

                documentOpenStatusId: val.documentOpenStatusId ?? "-",
                documentOpenStatus: val?.document_open_status?.name ?? "-",

                project: val?.project?.name ?? "-",
                projectId: val.projectId ?? "-",

                customer: val?.customer?.nickname ?? "-",
                customerId: val.customerId ?? "-",

                paymentProcess: val?.payment_process?.name ?? "-",
                paymentProcessId: val.paymentProcessId ?? "-",

                documentType: val?.document_type?.name ?? "-",
                documentTypeId: val.documentTypeId ?? "-",

                operationCostSum: val?.operationCostSum ? parseFloat(val.operationCostSum) : 0,
                operationDiscountSum: val?.operationDiscountSum ? parseFloat(val.operationDiscountSum) : 0,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 28,
                                padding: 0,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white'
                            }}
                            onClick={async () => {
                                countDocumentItemRef.current = 0
                                await documentItemPreparation(val)

                                setModal({ isShow: true, title: "edit" })
                            }}
                            size="small"
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 15, height: 15 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleDocumentDelete(val.documentId)

                                // reload
                                await reloadDocumentSearch()
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 28,
                                    padding: 0
                                }}
                                size="small"
                            >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 15, height: 15 }} />
                                </div>
                            </Button>{"  "}
                        </Popconfirm>

                        {val.urlPdf ?
                            <Button
                                style={{
                                    width: 28,
                                    padding: 0,
                                    backgroundColor: "#0066ff",
                                    border: "1px solid #0066ff",
                                    color: 'white'
                                }}
                                onClick={async () => {
                                    window.open(val.urlPdf, "blank")
                                }}
                                loading={documentLoading}
                                size="small"
                            >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Icon icon="teenyicons:pdf-outline" style={{ color: "white", width: 15, height: 15 }} />
                                </div>
                            </Button>
                            : []
                        }
                    </>
            });
        });
        setDocument(tempDocument)

        setDocumentLoading(false)
    }

    const addHoursToISO = (isoString, hours) => {
        const date = new Date(isoString)
        date.setTime(date.getTime() + hours * 60 * 60 * 1000) // เพิ่มชั่วโมงในหน่วยมิลลิวินาที
        return date.toISOString() // แสดงผลในรูปแบบ ISO อีกครั้งหลังจากเพิ่มเวลา
    }

    const onDocumentFinish = async (values) => {
        setDocumentLoading(true)

        const [day, month, year] = values.createdAt.split("/") // แยกส่วนวัน เดือน ปี
        const isoDate = new Date(`${month}/${day}/${year}`) // สลับตำแหน่ง DD/MM/YYYY -> MM/DD/YYYY
        const dateSVR = addHoursToISO(isoDate, 7)

        // console.log("docPdfRef.current : ", docPdfRef.current)
        const urlPdf = await getGenerateDocumentFetch(
            docPdfRef.current.data,
            docPdfRef.current.type
        )

        let document = {
            documentId: values.documentId ?? null,
            documentCode: docPdfRef?.current?.data?.document_code ?? null,
            documentOpenTypeId: values.documentOpenTypeId ?? null,
            documentItemTypeId: values.documentItemTypeId ?? null,
            documentOpenStatusId: values.documentOpenStatusId ?? null,
            projectId: pjTargetRef.current.projectId ?? null,
            projectTypeId: pjTargetRef.current.projectTypeId,
            customerId: pjTargetRef.current.customerInfo.customerId,
            paymentProcessId: documentCreate.paymentProcessId ?? null,
            documentTypeId: values.documentTypeId ?? null,
            operationCostSum: docPdfRef?.current?.data?.price_all ? parseFloat(docPdfRef.current.data.price_all) : 0,
            operationDiscountSum: docPdfRef?.current?.data?.discount_all ? parseFloat(docPdfRef.current.data.discount_all) : 0,
            name: values.name ?? null,
            urlPdf: urlPdf ?? null,
            isDisplayCustomerInfo: isDisplayCustomerInfo ?? null,
            projectPhaseId: values.projectPhaseId ?? null,
            createdAt: dateSVR ?? null,
            isActive: values.isActive
        }

        let document_item_add = documentItem.filter(fnd => String(fnd.documentItemId)?.includes("new-document-item"))
        let document_item_update = documentItem.filter(fnd => !String(fnd.documentItemId)?.includes("new-document-item"))

        // console.log("document_code : ", docPdfRef.current.data.document_code)
        // console.log("document : ", document)
        // console.log("document_item_add : ", document_item_add)
        // console.log("document_item_update : ", document_item_update)

        if (modal.title === "add") {
            let result = await insertDocumentFetch(document, document_item_add)
            if (result?.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
                // set default
                setFormDocumentDefault()
                setModal({ isShow: false, title: null })
                setDocumentLoading(false)
            } else {
                Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit") {
            let resultDoc = { isSuccess: true }
            let resultDocItemInsert = { isSuccess: true }
            let resultDocItemUpdate = { isSuccess: true }

            if (document) {
                resultDoc = await updateDocumentByIdFetch(document)
            }

            if (document_item_add?.length > 0) {
                resultDocItemInsert = await insertDocumentItemByIdFetch(document_item_add)
            }

            if (document_item_update?.length > 0) {
                resultDocItemUpdate = await updateDocumentItemByIdFetch(document_item_update)
            }

            if (
                resultDoc.isSuccess &&
                resultDocItemInsert.isSuccess &&
                resultDocItemUpdate.isSuccess
            ) {
                Notification('success', 'เเก้ไขสำเร็จ')
                // set default
                setFormDocumentDefault()
                setModal({ isShow: false, title: null })
                setDocumentLoading(false)
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }
        
        // reload
        await reloadDocumentSearch()
    }

    const onDocumentItemFinish = async (values) => {
        setDocumentItemLoading(true)

        let projectPhaseFill = projectPhase.filter(fill => fill.projectPhaseId === documentCreate.projectPhaseId)[0]

        if (modalDocumentItem.title === "add") {
            setTimeout(async () => {
                countDocumentItemRef.current++
                let tmpDocumentItem = documentItemRef.current

                if (documentCreate.documentId) {
                    let obj = {
                        index: countDocumentItemRef.current,
                        key: countDocumentItemRef.current,
                        documentId: documentCreate.documentId,
                        documentGroupItem: values.documentGroupItem ?? "-",
                        isDisplay: values.isNameDisplay ?? "-",
                        projectId: pjTargetRef.current.projectId,
                        taskCode: null,
                        caseCode: null,
                        name: values.name ?? "-",
                        operationCost: values?.operationCost ? parseFloat(values.operationCost) : 0,
                        operationDiscount: values?.operationDiscount ? parseFloat(values.operationDiscount) : 0,
                        projectPhaseId: documentCreate.projectPhaseId,
                        projectPhase: projectPhaseFill ? `${projectPhaseFill.name} (Phase/Round ${projectPhaseFill.phase})` : "-",
                        isActive: true
                    }

                    const result = await insertSingleDocumentItemByIdFetch(obj)

                    if (result.isSuccess) {
                        tmpDocumentItem.push({
                            ...obj,
                            documentItemId: result.formData.documentItemId,
                            operator: genOperationDocumentItem(values, result.formData.documentItemId)
                        })
                        // console.log("tmpDocumentItem : ", tmpDocumentItem)

                        Notification('success', 'สร้างสำเร็จ')
                    } else {
                        Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
                    }
                } else {
                    const documentItemId = ("new-document-item " + countDocumentItemRef.current)
                    let obj = {
                        index: countDocumentItemRef.current,
                        key: countDocumentItemRef.current,
                        documentItemId,
                        documentId: documentCreate?.documentId ?? "new-document",
                        documentGroupItem: values.documentGroupItem ?? "-",
                        isNameDisplay: values.isNameDisplay ?? "-",
                        projectId: pjTargetRef.current.projectId,
                        taskCode: null,
                        caseCode: null,
                        name: values.name ?? "-",
                        operationCost: values?.operationCost ? parseFloat(values.operationCost) : 0,
                        operationDiscount: values?.operationDiscount ? parseFloat(values.operationDiscount) : 0,
                        projectPhaseId: documentCreate.projectPhaseId,
                        projectPhase: projectPhaseFill ? `${projectPhaseFill.name} (Phase/Round ${projectPhaseFill.phase})` : "-",
                        isActive: true,
                        operator: genOperationDocumentItem(values, documentItemId)
                    }
                    tmpDocumentItem.push(obj)
                    // console.log("tmpDocumentItem : ", tmpDocumentItem)

                    Notification('success', 'สร้างเเละบันทึกลงฐานข้อมูลสำเร็จ')
                }

                // console.log("tmpDocumentItem : ", tmpDocumentItem, projectPhaseFill)
                documentItemRef.current = tmpDocumentItem
                setDocumentItem(tmpDocumentItem)

                // set default
                setFormDocumentItemDefault()

                setDocumentItemLoading(false)

            }, 100)
        } else if (modalDocumentItem.title === "edit") {

            const tmpDocumentItem = documentItemRef.current.map(item => item.documentItemId === values.documentItemId ? {
                ...item,
                documentGroupItem: values.documentGroupItem ?? "-",
                isNameDisplay: values.isNameDisplay ?? "-",
                name: values.name ?? "-",
                operationCost: values.operationCost ?? "-",
                operationDiscount: values.operationDiscount ?? "-",
                isActive: values.isActive ?? "-",
            } : item)
            documentItemRef.current = tmpDocumentItem
            setDocumentItem(tmpDocumentItem)

            if (documentCreate.documentId) {
                // result = await updateDocumentItemByIdFetch(document_item_update)
                // console.log("values.documentItemId : ", values.documentItemId)

                const fillDocumentItem = documentItemRef.current.map(fill => fill.documentItemId === values.documentItemId)[0]

                let obj = {
                    documentId: fillDocumentItem.documentId,
                    documentItemId: values.documentItemId,
                    projectId: fillDocumentItem.projectId,
                    taskCode: fillDocumentItem.taskCode,
                    caseCode: fillDocumentItem.caseCode,
                    documentGroupItem: values.documentGroupItem ?? null,
                    isNameDisplay: values.isNameDisplay ?? null,
                    name: values.name ?? null,
                    operationCost: values.operationCost ?? null,
                    operationDiscount: values.operationDiscount ?? null,
                    projectPhaseId: documentCreate.paymentProcessId,
                    projectPhase: projectPhaseFill ? `${projectPhaseFill.name} (Phase/Round ${projectPhaseFill.phase})` : "-",
                    isActive: values.isActive ?? null
                }

                const result = await updateDocumentItemByIdFetch([obj])
                if (result.isSuccess) {
                    Notification('success', 'เเก้ไขเเละบันทึกลงฐานข้อมูลสำเร็จ')
                } else {
                    Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
                }
            } else {
                Notification('success', 'เเก้ไขสำเร็จ')
            }

            // set default
            setFormDocumentItemDefault()

            setDocumentItemLoading(false)
        }
    }

    const documentSearchRef = useRef({
        name: null,
        documentCode: null,
        documentOpenTypeId: null,
        documentItemTypeId: null,
        documentOpenStatusId: null,
        documentTypeId: null,
        projectId: null,
        customerId: null,
        paymentProcessId: null,
        startedDate: null,
        endDate: null
    })

    const onDocumentSearchFinish = async (values) => {
        // console.log("values : ", values)
        
        // let name = values?.name ?? null;
        // let documentCode = values?.documentCode ?? null;
        // let documentOpenTypeId = values?.documentOpenType ?? null;
        // let documentItemTypeId = values?.documentItemType ?? null;
        // let documentOpenStatusId = values?.documentOpenStatus ?? null;
        let documentTypeId = values?.documentType ?? null;
        let projectId = values?.project ?? null;
        let customerId = values?.customer ?? null;
        let paymentProcessId = values?.paymentProcess ?? null;
        // let startedDate = values?.dateRange ? values.dateRange[0] : null
        // let endDate = values?.dateRange ? values.dateRange[1] : null

        let objSearch = {
            // name,
            // documentCode,
            // documentOpenTypeId,
            // documentItemTypeId,
            // documentOpenStatusId,
            documentTypeId,
            projectId,
            customerId,
            paymentProcessId,
            // startedDate,
            // endDate
        }
        // console.log("onDocumentSearchFinish : ", objSearch)
        await getDocumentBySearch(objSearch)
    }

    const handleDocumentDelete = async (id) => {
        const result = await deleteDocumentByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagineDocument = (n) => {
        setPageDocumentCurrent(n.current)
    }

    const setFormDocumentDefault = () => {
        setDocumentCreate(formDocumentDefault)
        setDocumentItem([])
        setCurrent(0)
    }

    const setFormDocumentItemDefault = () => {
        formDocumentItem.resetFields()

        setModalDocumentItem({
            isShow: false,
            title: null
        })
    }

    const setFormDocumentPreviewDefault = () => {
        setModalDocumentPreview({
            isShow: false,
            title: null
        })
    }

    const getDocumentOpenTypeAll = async () => {
        const result = await getDocumentOpenTypeAllFetch()
        // console.log("getDocumentOpenTypeAllFetch : ", result)
        setDocumentOpenType(result)
    }

    const getDocumentItemTypeAll = async () => {
        const result = await getDocumentItemTypeAllFetch()
        // console.log("getDocumentItemTypeAllFetch : ", result)
        setDocumentItemType(result)
    }

    const getDocumentOpenStatusAll = async (id) => {
        const result = await getDocumentOpenStatusAllFetch()
        // console.log("getDocumentOpenStatusAllFetch : ", result)
        setDocumentOpenStatus(result)
    }

    const getDocumentTypeAll = async () => {
        const result = await getDocumentTypeAllFetch()
        // console.log("getDocumentTypeAllFetch : ", result)
        documentTypeRef.current = result
        return result
    }

    const getProjectAll = async () => {
        let obj = {
            // workProcessId: [1, 2, 3, 4],
            // paymentProcessId: [1, 2, 3, 4, 9, 10, 11, 15, 16, 19]
        }
        let isShowAll = true // false
        const result = await getProjectAllFetch(obj, isShowAll)
        // console.log("getProjectAllFetch : ", result)
        projectRef.current = result
    }

    const getCustomerAll = async () => {
        const result = await getCustomerAllFetch()
        // console.log("getCustomerAllFetch : ", result)
        setCustomer(result)
    }

    const getPaymentInstallmentAll = async () => {
        const result = await getPaymentInstallmentAllFetch()
        // console.log("getPaymentInstallmentAllFetch : ", result)
        setPaymentInstallment(result)
    }

    const getPaymentProcessById = async (paymentInstallmentId) => {
        const obj = {
            isUseOnDocument: true,
            paymentInstallmentId: (paymentInstallmentId ?? null)
        }
        const result = await getPaymentProcessByIdFetch(obj)
        // console.log("getPaymentProcessByIdFetch : ", result)
        setPaymentProcess(result)
    }

    const getCountDocumentByTypeId = async (documentTypeId) => {
        const result = await getCountDocumentByTypeIdFetch({
            documentTypeId
        })
        // console.log("getCountDocumentByTypeIdFetch : ", result)
        return result?.countRow ?? null
    }

    const getBankAccountPayment = async () => {
        const result = await getBankAccountPaymentFetch()
        // console.log("getBankAccountPaymentFetch : ", result)
        bankAccountPaymentRef.current = result
    }

    const getProjectPhaseById = async (projectId) => {
        if (projectId) {
            const result = await getProjectPhaseByIdFetch({ projectId })
            // console.log("getProjectPhaseByIdFetch : ", result)
            setProjectPhase(result)            
        }
    }

    const getBaseApi = async () => {
        await getDocumentOpenTypeAll()
        await getDocumentItemTypeAll()
        await getDocumentOpenStatusAll()
        await getDocumentTypeAll()
        await getProjectAll()
        await getCustomerAll()
        await getPaymentInstallmentAll()
        await getPaymentProcessById()
        await getBankAccountPayment()
    }

    const reloadDocumentSearch = async () => {
        await getDocumentBySearch({
            name: documentSearchRef.current.name,
            documentCode: documentSearchRef.current.documentCode,
            documentOpenTypeId: documentSearchRef.current.documentOpenTypeId,
            documentItemTypeId: documentSearchRef.current.documentItemTypeId,
            documentOpenStatusId: documentSearchRef.current.documentOpenStatusId,
            documentTypeId: documentSearchRef.current.documentTypeId,
            projectId: documentSearchRef.current.projectId,
            customerId: documentSearchRef.current.customerId,
            paymentProcessId: documentSearchRef.current.paymentProcessId,
            startedDate: documentSearchRef.current.startedDate,
            endDate: documentSearchRef.current.endDate
        })
    }

    const reloadDocumentItem = async (documentId) => { // document.documentId
        const result = await getDocumentItemSearchFetch({ documentId })
        // console.log("getDocumentItemSearchFetch : ", result)

        let tmpDocumentItem = []
        result.map(item => {
            countDocumentItemRef.current++
            tmpDocumentItem.push({
                index: countDocumentItemRef.current,
                key: countDocumentItemRef.current,
                documentItemId: item?.documentItemId,
                documentId: item?.documentId,
                documentGroupItem: item.documentGroupItem,
                isNameDisplay: item.isNameDisplay,
                projectId: document.projectId,
                taskCode: item.taskCode,
                caseCode: item.caseCode,
                name: item.name ?? "-",
                operationCost: item?.operationCost ? parseFloat(item.operationCost) : 0,
                operationDiscount: item?.operationDiscount ? parseFloat(item.operationDiscount) : 0,
                projectPhaseId: item.projectPhaseId,
                projectPhase: item?.project_phase ? `${item.project_phase.name} (Phase/Round ${item.project_phase.phase})` : "-",
                isActive: true,
                operator: genOperationDocumentItem(item, item?.documentItemId)
            })
        })
        // console.log("tmpDocumentItem : ", tmpDocumentItem)
        documentItemRef.current = tmpDocumentItem
        setDocumentItem(tmpDocumentItem)

        return result
    }

    useEffect(() => {
        reloadDocumentSearch()
        getBaseApi()
    }, [])

    // -----------------------------------------------------------------------

    const steps = [
        {
            title: 'หัวเอกสาร', // เลือกประเภาเอกสาร (Ex. ใบเสนอราคา)
            content: 'step1'
        },
        {
            title: 'โปรเจกต์', // เลือกโปรเจกต์ (Ex. Kingdom Store) เเละ งานใหม่, งานเดิม
            content: 'step2'
        },
        {
            title: 'เนื้อหาเอกสาร', // ลงรายละเอียดข้อมูล (ใหม่ : สร้างรายการใหม่, เก่า : ดึงรายการที่มีอยู่นำมาใช้)
            content: 'step3'
        },
        {
            title: 'การใช้งาน', // การอนุมัติ เเละการใช้งาน
            content: 'step4'
        }
    ]

    const { token } = theme.useToken()
    const [current, setCurrent] = useState(0)

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title
    }))

    const contentStyle = {
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        marginTop: 16,
        padding: 16
    }

    const next = async () => {
        setCurrent(current + 1)
    }

    const prev = () => {
        setCurrent(current - 1)
    }

    // -----------------------------------------------------------------------

    const countDocumentItemRef = useRef(0)

    const genOperationDocumentItem = (data, documentItemId) => {
        return (
            <>
                <Button
                    style={{
                        width: 28,
                        padding: 0,
                        backgroundColor: "orange",
                        border: "1px solid orange",
                        color: 'white'
                    }}
                    onClick={async () => {
                        let fillDocumentItem = documentItemRef.current.filter(x => x.documentItemId === documentItemId)[0]
                        formDocumentItem.setFieldsValue({
                            documentItemId: fillDocumentItem ? fillDocumentItem.documentItemId : documentItemId,
                            documentId: fillDocumentItem ? fillDocumentItem.documentId : "waiting to be built together",
                            documentGroupItem: fillDocumentItem ? fillDocumentItem.documentGroupItem : "A",
                            isNameDisplay: fillDocumentItem ? fillDocumentItem.isNameDisplay : false,
                            name: fillDocumentItem ? fillDocumentItem.name : (data.name ?? undefined),
                            operationCost: fillDocumentItem ? fillDocumentItem.operationCost : (data.operationCost ?? undefined),
                            operationDiscount: fillDocumentItem ? fillDocumentItem.operationDiscount : (data.operationDiscount ?? undefined),
                            isActive: fillDocumentItem ? fillDocumentItem.isActive : true
                        })

                        setModalDocumentItem({ isShow: true, title: "edit" })
                    }}
                    size="small"
                >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Icon icon="typcn:edit" style={{ color: "white", width: 15, height: 15 }} />
                    </div>
                </Button>{"  "}

                <Popconfirm
                    title="คุณยืนยันลบหรือไม่ ?"
                    okText={<span style={{ width: 50 }}>ใช่</span>}
                    onConfirm={async (e) => {
                        await handleDocumentItemDelete(documentItemId)
                    }}
                    cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                >
                    <Button
                        danger
                        type="primary"
                        style={{
                            width: 28,
                            padding: 0,
                            // borderRadius: 50
                        }}
                        size="small"
                    >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 15, height: 15 }} />
                        </div>
                    </Button>
                </Popconfirm>
            </>
        )
    }

    const getTaskById = async () => {
        setDocumentItemLoading(true)

        const obj = {
            projectId: pjTargetRef.current.projectId,
            projectPhaseId: documentCreate.projectPhaseId
        }
        // console.log("task obj : ", obj)

        const result = await getDocumentTaskByIdFetch(obj)
        // console.log("getDocumentTaskByIdFetch : ", result)

        let tmpDocumentItem = []
        result?.map((val, index) => {
            countDocumentItemRef.current++
            const documentItemId = ("new-document-item " + countDocumentItemRef.current)
            tmpDocumentItem.push({
                index: countDocumentItemRef.current,
                key: countDocumentItemRef.current,
                documentItemId,
                documentId: "new-document",
                documentGroupItem: "A",
                isNameDisplay: true,
                projectId: pjTargetRef.current.projectId,
                taskCode: val.taskCode,
                caseCode: null,
                name: val.name,
                operationCost: val?.operationCost?.toFixed(2) ?? 0,
                operationDiscount: val.operationDiscount,
                projectPhaseId: val.projectPhaseId,
                projectPhase: val?.project_phase ? `${val.project_phase.name} (Phase/Round ${val.project_phase.phase})` : "-",
                isActive: true,
                operator: genOperationDocumentItem(val, documentItemId)
            })
        })
        // console.log("tmpDocumentItem : ", tmpDocumentItem)
        documentItemRef.current = tmpDocumentItem
        setDocumentItem(tmpDocumentItem)

        setDocumentItemLoading(false)
    }

    const getCaseById = async () => {
        setDocumentItemLoading(true)

        const obj = {
            projectId: pjTargetRef.current.projectId,
            projectPhaseId: documentCreate.projectPhaseId
        }
        // console.log("case obj : ", obj)

        const result = await getDocumentCaseByIdFetch(obj)
        // console.log("getDocumentCaseByIdFetch : ", result)

        let tmpDocumentItem = []
        result?.map((val, index) => {
            countDocumentItemRef.current++
            const documentItemId = ("new-document-item " + countDocumentItemRef.current)
            tmpDocumentItem.push({
                index: countDocumentItemRef.current,
                key: countDocumentItemRef.current,
                documentItemId,
                documentId: "new-document",
                documentGroupItem: "A",
                isNameDisplay: true,
                projectId: pjTargetRef.current.projectId,
                taskCode: null,
                caseCode: val.caseCode,
                name: val.name,
                operationCost: val?.operationCost?.toFixed(2) ?? 0,
                operationDiscount: val.operationDiscount,
                completionDate: val.completionDate,
                projectPhaseId: val.projectPhaseId,
                projectPhase: val?.project_phase ? `${val.project_phase.name} (Phase/Round ${val.project_phase.phase})` : "-",
                isActive: true,
                operator: genOperationDocumentItem(val, documentItemId)
            })
        })
        // console.log("tmpDocumentItem : ", tmpDocumentItem)
        documentItemRef.current = tmpDocumentItem
        setDocumentItem(tmpDocumentItem)

        setDocumentItemLoading(false)
    }

    const handleDocumentItemDelete = async (id) => {
        const newDocumentItem = documentItemRef.current.filter((fill) => fill.documentItemId !== id)
        documentItemRef.current = newDocumentItem
        setDocumentItem(newDocumentItem)
        Notification('success', 'ลบสำเร็จ')

        if (!String(id).includes("new-document-item")) {
            const result = await deleteDocumentItemByIdFetch(id)
            if (result.isSuccess) {
                Notification('success', 'ลบออกจากฐานข้อมูลสำเร็จ')
            } else {
                Notification('error', 'ไม่สามารถลบออกจากฐานข้อมูลได้')
            }
        }
    }

    const customizeDocument = async (docItem, docOption, pjTarget, docType) => {
        // STEP1 ------------------------------------------------
        const grouped = customizeDocumentGrouped(docItem)
        groupedDocumentRef.current = grouped
        // console.log("grouped ----- : ", grouped)

        // STEP2 ------------------------------------------------
        const format = await customizeDocumentFormat(grouped, docOption, pjTarget, docType)
        docPdfRef.current = format
        // console.log("format --- : ", format)
    }

    const customizeDocumentGrouped = (docItem) => { // STEP 1 : จัดกลุ่มของ รายการเอกสาร
        return docItem.reduce((result, item) => {
            const group = item.documentGroupItem
            if (!result[group]) {
                result[group] = []
            }
            result[group].push(item)
            return result
        }, {})
    }
    
    const customizeDocumentFormat = async (grouped, docOption, pjTarget, docType) => { // STEP 2 : วางโครงสร้างสำหรับ เเสดงในไฟล์เอกสาร
        let index = 0
        let document = []
        let price_all = 0
        let discount_all = 0

        // console.log("docOption --- : ", docOption)
        let countDocument = await getCountDocumentByTypeId(docOption?.documentTypeId)
        let document_code = docOption.documentId ?
            docOption.documentCode
            :
            generateDocumentCode(String(countDocument), docOption?.documentTypeId)

        let create_date = docOption.createdAt

        // DOCUMENT TYPE -------------------------------------------------------------------------------
        let findDocumentType = docType?.filter(flt => flt.documentTypeId === docOption?.documentTypeId)[0]
        let document_type = {
            document_type_id: findDocumentType.documentTypeId,
            name: findDocumentType.name
        }

        // CUSTOMER ------------------------------------------------------------------------------------
        let ctmIf = pjTarget.customerInfo
        let customer = isDisplayCustomerInfo ? {
            infomation: {
                uid: ctmIf.uid,
                tax_identification_number: ctmIf.taxIdentificationNumber,
                person_type: ctmIf.person_type.name,
                person_type_id: ctmIf.personTypeId,
                prefix: ctmIf.prefix.name,
                first_name: ctmIf.firstName,
                last_name: ctmIf.lastName,
                juristic: {
                    company_name: ctmIf.juristic.companyName,
                    is_head_office: ctmIf.juristic.isHeadOffice,
                    branch: ctmIf.juristic.branch
                }
            },
            address: {
                address: ctmIf.address,
                district: ctmIf.district,
                province: ctmIf.province,
                zip_code: ctmIf.zipCode
            },
            contact: {
                email: ctmIf.email,
                phone_number: ctmIf.phoneNumber,
            }
        } : {
            infomation: {
                person_type_id: ctmIf.personTypeId
            }
        }

        // PROVIDER ------------------------------------------------------------------------------------
        let provider = {
            infomation: {
                tax_identification_number: "0105565051257",
                company_name: "บริษัท กู๊ดเนส โกบอล กรุ๊ป จำกัด",
                is_head_office: true,
                bidder: {
                    bidder_id: 1,
                    name: "นายฐิติพงศ์ อ่อนจันทร์"
                },
                company_director: {
                    company_director_id: 1,
                    name: "นายสหรัฐ มาสู่"
                }
            },
            address: {
                address: "เลขที่ 110 ซอยวิภาวดีรังสิต 4 ถนนวิภาวดีรังสิต เเขวงรัชดาภิเษก",
                district: "เขตดินเเดง",
                province: "กรุงเทพมหานคร",
                zip_code: "10400",
            },
            contact: {
                email: "thitiphong.oon@softpomarket.com",
                phone_number: "064-245-1994"
            },
        }

        // PROJECT -------------------------------------------------------------------------------------
        let pctIf = pjTarget.packageInfo
        let project = {
            projectId: pjTarget.projectId,
            name: pjTarget.projectName,
            project_size: pctIf.package_size.name,
            package: pctIf.name
        }

        if (
            docOption?.documentTypeId === 1 ||
            docOption?.documentTypeId === 2
        ) {
            // BANK ACCOUNT PAYMENT ------------------------------------------------------------------------
            let bank_account_payment = {
                bank_name: bankAccountPaymentRef.current.bankName,
                bank_number: bankAccountPaymentRef.current.bankNumber,
                bank_account: bankAccountPaymentRef.current.bankAccount
            }

            // DOCUMENT ITEM -------------------------------------------------------------------------------
            Object.entries(grouped).map(([group, items]) => {
                index += 1
                let price_sum = 0
                let discount_sum = 0

                items.map(item => {
                    price_sum += parseFloat(item.operationCost ?? 0)
                    discount_sum += parseFloat(item.operationDiscount ?? 0)
                })

                price_all += price_sum
                discount_all += discount_sum

                let document_item = []
                items.map((item, index) => {
                    if (item.isNameDisplay) {
                        // console.log("X --- : ", index, item.name)
                        document_item.push({
                            name: (index !== 0 ? "- " : "") + item.name,
                            price: item.operationCost,
                            discount: item.operationDiscount,
                            is_not_display: item.isNameDisplay,
                        })
                    }
                })

                document.push({
                    index: index,
                    price_sum,
                    discount_sum,
                    document_item
                })
            })

            // REMARK --------------------------------------------------------------------------------------
            let remark = []
            let daysDiff = null
            if (docOption?.documentTypeId === 1) {
                if (documentCreate.documentItemTypeId === 1 || documentCreate.documentOpenTypeId === 1) { // task || document_open_type = ใหม่
                    let projectPhaseFill = projectPhase.filter(fill => fill.projectPhaseId === documentCreate.projectPhaseId)[0]
                    if (projectPhaseFill) {
                        daysDiff = calculateDaysDifference(projectPhaseFill.developmentStart, projectPhaseFill.goLiveDate).days
                    }
                } else if (documentCreate.documentItemTypeId === 2) { // case
                    function findLatestCompletionDate(data) {
                        return data.reduce((latest, item) => {
                            const currentCompletionDate = new Date(item.completionDate);
                            return currentCompletionDate > new Date(latest.completionDate) ? item : latest;
                        });
                    }
                    const latestCompletionDateItem = findLatestCompletionDate(documentItemRef.current);
                    daysDiff = calculateDaysDifference(new Date(), latestCompletionDateItem.completionDate).days
                    if (daysDiff) {
                        daysDiff++
                    }
                }

                remark.push({
                    name: "ชำระเงินมัดจำเเรก ก่อนเริ่มดำเนินงาน"
                })

                remark.push({
                    name: "เริ่มดำเนินการวันที่ (นับจากวันที่ชำระเงิน)"
                })

                if (daysDiff) {
                    remark.push({
                        name: `ระยะเวลาการดำเนินงาน ภายใน ${daysDiff} วัน`
                    })
                }

            } else {
                remark.push({
                    name: ``
                })
            }

            //SUMMARY PRICE --------------------------------------------------------------------------------
            let summaryPrice = calculateSummaryPrice(
                price_all,
                discount_all,
                pjTargetRef?.current?.paymentInfo
            )

            // SIGNATURE -----------------------------------------------------------------------------------
            let signature = [
                {
                    url: "",
                    name: isDisplayCustomerInfo ? `${customer.infomation.prefix}${customer.infomation.first_name} ${customer.infomation.last_name}` : null,
                    type: `ผู้ใช้บริการ ${ctmIf.personTypeId === 2 ? (" ในนาม " + (ctmIf.juristic.companyName ?? ".........................")) : ""}`
                },
                {
                    url: "",
                    name: `${provider.infomation.bidder.name}`,
                    type: "ผู้เสนอราคา"
                },
                {
                    url: "",
                    name: `${provider.infomation.company_director.name}`,
                    type: "กรรมการบริษัท กู๊ดเนส โกบอล กรุ๊ป จำกัด"
                }
            ]

            return {
                data: {
                    document_type,
                    document_code,
                    create_date,
                    customer,
                    provider,
                    project,
                    document,
                    bank_account_payment,
                    summaryPrice,
                    signature,
                    remark,
                    price_all,
                    discount_all,
                    isDisplayCustomerInfo
                },
                type: findDocumentType.key
            }
        } else if (docOption?.documentTypeId === 3) {
            return null
        } else if (docOption?.documentTypeId === 4) {
            return null
        }

        return null
    }

    const calculateSummaryPrice = (priceAll, discountAll, payment) => {
        const paymentInstallmentOption = paymentInstallment.filter(fill => fill.paymentInstallmentId === documentCreate.paymentInstallmentId)[0]
        const paymentProcessOption = paymentProcess.filter(fill => fill.paymentProcessId === documentCreate.paymentProcessId)[0]
        let obj = []

        // expensesTotal
        obj.push({
            name: "ค่าใช้จ่ายรวม",
            price: priceAll - discountAll
        })

        // discountTotal
        obj.push({
            name: "ส่วนลด",
            price: discountAll
        })

        // cumPctReceived
        if (paymentProcessOption.cumInstallment > 0) {
            obj.push({
                name: `ชำระเงินมัดจำก่อนหน้านี้เเล้ว จำนวน ${paymentProcessOption.cumInstallment} งวด ${paymentProcessOption.cumPctReceived * 100}%`,
                price: paymentProcessOption.cumPctReceived * (priceAll - discountAll)
            })
        }

        // currentDeposit
        let isLastDeposit = (paymentProcessOption.cumInstallment + 1 === paymentInstallmentOption.amount) ? true : false
        obj.push({
            name: isLastDeposit ?
                paymentProcessOption.cumInstallment > 0 ? `ชำระเงินมัดจำงวดสุดท้าย` : `จำนวนเงินที่ต้องชำระ`
                :
                (paymentProcessOption.cumInstallment === 0 ?
                    `ชำระเงินมัดจำงวดเเรก ${(paymentProcessOption.percent * 100).toFixed(2)}%`
                    :
                    `ชำระเงินมัดจำ งวดที่ ${paymentProcessOption.cumInstallment + 1} รอบนี้ ${paymentProcessOption.percent * 100}%`
                ),
            price: paymentProcessOption.percent * (priceAll - discountAll)
        })

        return obj
    }

    const generateDocumentCode = (number, documentTypeId) => {

        let codeAhead = ""
        if (documentTypeId === 1) { // ใบเสนอราคา
            codeAhead = 'QT' // Quotation
        } else if (documentTypeId === 2) { // ใบเเจ้งหนี้
            codeAhead = 'INV' // Invoice
        } else if (documentTypeId === 3) { // ใบเสร็จรับเงิน
            codeAhead = 'REC' // Receipt
        } else if (documentTypeId === 4) { // ใบต่ออายุการใช้งาน 
            codeAhead = 'REN' // Renewal
        }

        const currentDate = new Date()
        // ปรับเวลาเป็นโซนประเทศไทย (GMT+7)
        const thailandOffset = 7 * 60 * 60 * 1000 // 7 ชั่วโมง
        const thailandDate = new Date(currentDate.getTime() + thailandOffset)

        const year = thailandDate.getFullYear()
        const month = (thailandDate.getMonth() + 1).toString().padStart(2, '0') // เดือน (เริ่มจาก 0)
        const day = thailandDate.getDate().toString().padStart(2, '0') // วันที่

        const formattedDate = `${year}${month}${day}` // รูปแบบ YYYYMMDD
        const formattedNumber = number.toString().padStart(6, '0') // แปลงหมายเลขใบเสนอราคาเป็น 6 หลัก เช่น 001

        return `${codeAhead}${formattedDate}-${formattedNumber}`
    }

    const genDocumentPreview = () => {
        let docPdf = docPdfRef.current.data
        return (
            <div>
                <div style={{ display: "flex", paddingBottom: 12 }}>
                    <div style={{ width: "60%" }}>
                        <label>{docPdf.provider.infomation.company_name ?? "-"}</label><br />
                        <label>ที่อยู่ {docPdf.provider.address.address ?? "-"}</label><br />
                        <label>{docPdf.provider.address.district ?? "-"} {docPdf.provider.address.province ?? "-"} {docPdf.provider.address.zip_code ?? "-"}</label><br />
                        <label>เลขประจำตัวผู้เสียภาษี {docPdf.provider.infomation.tax_identification_number ?? "-"}</label><br />
                        <label>Email {docPdf.provider.contact.email ?? "-"}</label><br />
                        <label>Tel {docPdf.provider.contact.phone_number ?? "-"}</label>
                    </div>

                    <div style={{ width: "40%", alignItems: "flex-start", textAlign: "right" }}>
                        <label style={{ fontWeight: 600 }}>{docPdf.document_type.name ?? "-"}</label><br />
                        <label>เลขที่ {docPdf.document_code ?? "-"}</label><br />
                        <label>วันที่ {docPdf.create_date ?? "-"}</label>
                    </div>
                </div>

                <div style={{ display: "flex", paddingBottom: 12 }}>
                    <div style={{ width: "60%" }}>
                        <label style={{ fontWeight: 600 }}>ลูกค้า</label><br />
                        {docPdf.customer.infomation.person_type_id === 1 ?
                            <>
                                {isDisplayCustomerInfo ?
                                    <>
                                        <label>{docPdf.customer.infomation.prefix ?? "-"} </label>
                                        <label>{docPdf.customer.infomation.first_name ?? "-"} </label>
                                        <label>{docPdf.customer.infomation.last_name ?? "-"}</label><br />
                                    </>
                                    :
                                    <>
                                        <div className="dot-line"><span>ชื่อ</span></div>
                                    </>
                                }
                            </>
                            : []
                        }

                        {docPdf.customer.infomation.person_type_id === 2 ?
                            <>
                                {isDisplayCustomerInfo ?
                                    <>
                                        <label>{docPdf.customer.infomation.prefix ?? "-"} </label>
                                        <label>{docPdf.customer.infomation.first_name ?? "-"} </label>
                                        <label>{docPdf.customer.infomation.last_name ?? "-"}</label><br />
                                        <label>{docPdf.customer.infomation.juristic.company_name ?? "-"}</label><br />
                                    </>
                                    :
                                    <>
                                        <div className="dot-line"><span>บริษัท/ห้างหุ้นส่วน</span></div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Icon icon="pixelarticons:checkbox-on" style={{ paddingBottom: 4, width: 16, height: 16, paddingRight: 6 }} />
                                            <div className="dot-line"><span>สำนักงานใหญ่</span></div>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Icon icon="pixelarticons:checkbox-on" style={{ paddingBottom: 4, width: 16, height: 16, paddingRight: 6 }} />
                                            <div className="dot-line"><span>สาขา</span></div>
                                        </div>
                                    </>
                                }
                            </>
                            : []
                        }

                        {isDisplayCustomerInfo ?
                            <>
                                <label>ที่อยู่ {docPdf.customer.address.address ?? ""}</label><br />
                                <label>{docPdf.customer.address.district ?? "-"} {docPdf.customer.address.province ?? "-"} {docPdf.customer.address.zip_code ?? "-"}</label><br />
                                <label>เลขประจำตัวผู้เสียภาษี {docPdf.customer.infomation.tax_identification_number ?? ""}</label><br />
                                <label>Email {docPdf.customer.contact.email ?? "-"}</label><br />
                                <label>Tel {docPdf.customer.contact.phone_number ?? "-"}</label>
                            </>
                            :
                            <>
                                <div className="dot-line"><span>ที่อยู่</span></div>
                                <div className="dot-line"><span>.</span></div>
                                <div className="dot-line"><span>เลขประจำตัวผู้เสียภาษี</span></div>
                                <div className="dot-line"><span>Email</span></div>
                                <div className="dot-line"><span>Tel</span></div>
                            </>
                        }
                    </div>
                    <div style={{ width: "40%", paddingLeft: 12 }}>
                        <label style={{ fontWeight: 600 }}>ชื่องาน</label><br />
                        <label>{docPdf.project.name ?? "-"}</label><br />
                        <label>Package {docPdf.project.package ?? "-"} ({docPdf.project.project_size ?? "-"})</label>
                    </div>
                </div>

                <div>
                    <div style={{ display: "flex", backgroundColor: "#EEEEEE", padding: 4 }}>
                        <div style={{ width: "10%", textAlign: "center" }}><label>No.</label></div>
                        <div style={{ width: "50%" }}><label>รายการ</label></div>
                        <div style={{ width: "20%", textAlign: "right" }}><label>ส่วนลด</label></div>
                        <div style={{ width: "20%", textAlign: "right" }}><label>ราคา</label></div>
                    </div>
                    {docPdf.document.map((doc, iv_itm_index) => (
                        <div key={iv_itm_index} style={{ display: "flex", borderBottom: "1px solid #EEEEEE", padding: 4 }}>
                            <div style={{ width: "10%", textAlign: "center" }}><label>{doc.index}</label></div>
                            <div style={{ width: "50%", display: "grid" }}>
                                {doc.document_item.map(doc_itm => {
                                    let name = doc_itm.name
                                    return (
                                        <>
                                            {doc_itm.is_not_display ? <label>{name ?? "-"}</label> : null}
                                        </>
                                    )
                                })}
                            </div>

                            <div style={{ width: "20%", textAlign: "right" }}>
                                <label style={{ float: "right" }}>
                                    {Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(doc.discount_sum))} บาท
                                </label>
                            </div>
                            <div style={{ width: "20%", textAlign: "right" }}>
                                <label style={{ float: "right" }}>
                                    {Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(doc.price_sum))} บาท
                                </label>
                            </div>
                        </div>
                    )
                    )}
                </div>

                <div style={{ paddingTop: 12 }}>
                    {docPdf.summaryPrice?.map((smy, index) => (
                        <div key={index} style={{ display: "flex" }}>
                            <div style={{ width: "80%" }}>
                                <label style={{ float: "right" }}>{smy.name}</label>
                            </div>
                            <div style={{ width: "20%" }}>
                                <label style={{ float: "right" }}>
                                    {Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(smy.price))} บาท
                                </label>
                            </div>
                        </div>
                    ))}
                </div>

                <div style={{ paddingTop: 12 }}>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "50%", display: "grid", border: "2px solid #EEEEEE", padding: 6 }}>
                            <label style={{ fontWeight: 600, textDecoration: "underline" }}>หมายเหตุ</label>
                            {docPdf.remark.map((item, index) => (
                                <label key={index} style={{ float: "right" }}>- {item.name}</label>
                            ))}
                        </div>
                        <div style={{
                            width: "50%",
                            display: "grid",
                            borderRight: "2px solid #EEEEEE",
                            borderTop: "2px solid #EEEEEE",
                            borderBottom: "2px solid #EEEEEE",
                            padding: 6
                        }}>
                            <label style={{ fontWeight: 600, textDecoration: "underline" }}>บัญชีธนาคาร</label>
                            <label style={{ float: "right" }}>{docPdf.bank_account_payment.bank_account}</label>
                            <label style={{ float: "right" }}>{docPdf.bank_account_payment.bank_name}</label>
                            <label style={{ float: "right" }}>{docPdf.bank_account_payment.bank_number}</label>
                        </div>
                    </div>
                </div>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {docPdf.signature?.map((snt, index) => (
                        <div style={{ width: "50%" }}>
                            <div style={{ paddingTop: 60, paddingLeft: 40, paddingRight: 40, display: "grid", textAlign: "center" }}>
                                <div className="dot-line"><span></span></div>
                                <div style={{ paddingTop: 8 }}>
                                    {snt.name ?
                                        <span>{`${snt.name}`}</span>
                                        :
                                        <span>{`( ................................................ )`}</span>
                                    }
                                </div>
                                <label style={{ paddingTop: 8 }}>{snt.type}</label>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const dayDiff = (startDate, endDate) => {
        const start = new Date(startDate)
        const end = new Date(endDate)

        // ตั้งค่าเวลาให้เป็นเที่ยงคืน (00:00:00) เพื่อละเวลาจากการคำนวณ
        const day1 = new Date(start.getFullYear(), start.getMonth(), start.getDate())
        const day2 = new Date(end.getFullYear(), end.getMonth(), end.getDate())

        // หาความแตกต่างของเวลาเป็น milliseconds
        const timeDiff = day2 - day1

        // แปลงความแตกต่างจาก milliseconds เป็นวัน
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24))

        // แปลงความแตกต่างจาก milliseconds เป็นชั่วโมง (ส่วนที่เหลือจากวัน)
        // const hoursDiff = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))

        return daysDiff
    }

    // -----------------------------------------------------------------------

    const RowContext = React.createContext({})

    const onDragEnd = async ({ active, over }) => {
        if (active.id !== over?.id) {

            let docItemIds = []
            setDocumentItem((prevState) => {
                const activeIndex = prevState.findIndex((i) => i.key === active?.id)
                const overIndex = prevState.findIndex((i) => i.key === over?.id)
                // console.log("prevState : ", prevState)

                const dataSort = arrayMove(prevState, activeIndex, overIndex)
                docItemIds = dataSort.map(({ documentItemId }) => ({ documentItemId }))
                return arrayMove(prevState, activeIndex, overIndex)
            })

            // console.log("documentCreate.documentId : ", documentCreate.documentId)
            if (documentCreate.documentId) {
                // console.log("docItemIds : ", docItemIds)

                let obj = {
                    docItemIds,
                    documentId: documentCreate.documentId,
                }

                await updateDocumentItemDragDropByIdFetch(obj)

                // reload
                countDocumentItemRef.current = 0
                await reloadDocumentItem(documentCreate.documentId)
            }
        }
    }

    const DragHandle = () => {
        const { setActivatorNodeRef, listeners } = useContext(RowContext)
        return (
            <Button
                type="text"
                size="small"
                icon={<HolderOutlined />}
                style={{
                    cursor: 'move'
                }}
                ref={setActivatorNodeRef}
                {...listeners}
            />
        )
    }

    const Rows = (props) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            setActivatorNodeRef,
            transform,
            transition,
            isDragging,
        } = useSortable({
            id: props['data-row-key']
        })

        const style = {
            ...props.style,
            transform: CSS.Translate.toString(transform),
            transition,
            ...(isDragging
                ? {
                    position: 'relative',
                    zIndex: 9999
                }
                : {})
        }

        const contextValue = useMemo(
            () => ({
                setActivatorNodeRef,
                listeners
            }),
            [setActivatorNodeRef, listeners]
        )

        return (
            <RowContext.Provider value={contextValue}>
                <tr {...props} ref={setNodeRef} style={style} {...attributes} />
            </RowContext.Provider>
        )
    }

    // -----------------------------------------------------------------------

    const textareaRefs = useRef([]);

    const setRef = useCallback((el, index) => {
        if (el) {
            textareaRefs.current[index] = el;
            requestAnimationFrame(() => {
                el.style.height = '24px'; // ความสูงเริ่มต้นสำหรับ 1 แถว
                if (el.scrollHeight > 24) {
                    el.style.height = `${el.scrollHeight}px`; // ปรับขนาดตามเนื้อหา
                }
            });
        }
    }, []);

    const genTaskNameChange = (tags, record, index) => {

        const handleBlur = async (e) => {
            if (tags !== e.target.value) {
                let tmpDocumentItem = documentItem.map(item => item.documentItemId === record.documentItemId ? {
                    ...item,
                    name: e.target.value
                } : item)
                documentItemRef.current = tmpDocumentItem
                setDocumentItem(tmpDocumentItem)
            }
        };

        const handleChange = (e) => {
            const target = textareaRefs.current[index];
            target.style.height = '24px'; // รีเซ็ตความสูงเริ่มต้นสำหรับ 1 แถว
            requestAnimationFrame(() => {
                target.style.height = `${target.scrollHeight}px`; // ปรับขนาดตามเนื้อหา
            });
        };

        return (
            <textarea
                ref={el => setRef(el, index)}
                className='doc-name-edit'
                defaultValue={tags}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        )
    }

    return (
        <Row>
            <Col span={3}>
                <label>จัดการ Document </label>
            </Col>

            <Col span={21}>
                <Form form={formDocumentSearch} layout="vertical" onFinish={onDocumentSearchFinish}>
                    <Row gutter={[24, 0]}>
                        {/* <Col span={6}>
                            <Form.Item name="name">
                                <Input style={{ width: '100%' }} placeholder="ชื่อ Document" />
                            </Form.Item>
                        </Col> */}

                        {/* <Col span={4}>
                            <Form.Item name="documentOpenType">
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                    placeholder="IV open type"
                                >
                                    {documentOpenType?.map((item, index) => (
                                        <Option key={index} value={item.documentOpenTypeId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col> */}

                        {/* <Col span={4}>
                            <Form.Item name="documentItemType">
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                    placeholder="IV item type"
                                >
                                    {documentItemType?.map((item, index) => (
                                        <Option key={index} value={item.documentItemTypeId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col> */}

                        {/* <Col span={4}>
                            <Form.Item name="documentOpenStatus">
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                    placeholder="IV open status"
                                >
                                    {documentOpenStatus?.map((item, index) => (
                                        <Option key={index} value={item.documentOpenStatusId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col> */}

                        <Col span={6}>
                            <Form.Item name="project">
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                    placeholder="ชื่อ Project"
                                >
                                    {projectRef.current?.map((item, index) => (
                                        <Option key={index} value={item.projectId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item name="documentType">
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                    placeholder="Document type"
                                >
                                    {documentTypeRef.current?.map((item, index) => (
                                        <Option key={index} value={item.documentTypeId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item name="customer">
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                    placeholder="ชื่อ Customer"
                                >
                                    {customer?.map((item, index) => (
                                        <Option key={index} value={item.customerId}>{item.nickname}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name="paymentProcess">
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                    placeholder="Payment process"
                                >
                                    {paymentProcess?.map((item, index) => (
                                        <Option key={index} value={item.paymentProcessId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* <Col span={6}>
                            <Form.Item name="dateRange">
                                <RangePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </Col> */}

                        <Col span={3}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <div style={{ paddingLeft: 10 }}>
                                    <Button
                                        style={{ float: 'right', width: 40 }}
                                        type="primary"
                                        onClick={() => formDocumentSearch.submit()}
                                    >
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div style={{ display: "flex", alignItems: "center", }}>
                                                <Icon icon="ion:search" style={{ width: 18, height: 18 }} />
                                            </div>
                                        </div>
                                    </Button>
                                </div>

                                <div style={{ paddingLeft: 10 }}>
                                    <Button
                                        type="primary"
                                        style={{ float: 'right', width: 40 }}
                                        onClick={() => {
                                            setFormDocumentDefault()
                                            setModal({
                                                isShow: true,
                                                title: "add"
                                            })
                                        }}
                                    >
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div style={{ display: "flex", alignItems: "center", }}>
                                                <Icon icon="mingcute:add-fill" style={{ width: 18, height: 18 }} />
                                            </div>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Col>

            <Col span={24}>
                <Table
                    loading={documentLoading}
                    dataSource={document}
                    pagination={{
                        current: pageDocumentCurrent,
                        pageSize: 10,
                        total: document.length
                    }}
                    onChange={(n) => onPagineDocument(n)}
                >
                    <Column title="No." dataIndex="index" key="index" width={"2%"} align={'center'} />
                    <Column title="ชื่อ Doc" dataIndex="name" key="name" width={"12%"} />
                    <Column title="ชื่อ Project" dataIndex="project" key="project" width={"6%"} />
                    <Column title="IV item type" dataIndex="documentItemType" key="documentItemType" width={"4%"} align="center" />
                    <Column title="IV open status" dataIndex="documentOpenStatus" key="documentOpenStatus" width={"5%"} align="center" />
                    <Column title="Payment process" dataIndex="paymentProcess" key="paymentProcess" width={"9%"} />
                    <Column title="Doc type" dataIndex="documentType" key="documentType" width={"5%"} />
                    <Column
                        title="ค่าใช้จ่าย"
                        dataIndex="operationCostSum"
                        key="operationCostSum"
                        width={"4%"}
                        align="center"
                        render={(tags, record) => (
                            <label style={{ float: "right" }}>
                                {Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(tags)} บาท
                            </label>
                        )}
                    />
                    {/* <Column
                        title="ส่วนลด"
                        dataIndex="operationDiscountSum"
                        key="operationDiscountSum"
                        width={"4%"}
                        align="center"
                        render={(tags, record) => (
                            <label style={{ float: "right" }}>
                                {Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(tags)} บาท
                            </label>
                        )}
                    /> */}
                    <Column title={<FaCog />} dataIndex="operator" key="operator" width={"6%"} align={'center'} />
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {
                    // default
                    setFormDocumentDefault()

                    setModal({ isShow: false, title: null })
                }}
                style={{ top: 8 }}
                width={"100%"}
                footer={[
                    current > 0 && (
                        <Button
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={() => prev()}
                        >
                            ย้อนกลับ
                        </Button>
                    ),
                    current < steps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                            ถัดไป
                        </Button>
                    ),
                    current === steps.length - 1 && (
                        <Button
                            type="primary"
                            onClick={async () => {
                                await customizeDocument(documentItem, documentCreate, pjTargetRef.current, documentTypeRef.current)
                                await onDocumentFinish(documentCreate)
                            }}
                            loading={documentLoading}
                        >
                            ยืนยัน
                        </Button>
                    )
                ]}
            >
                <Steps current={current} items={items} />
                <div style={contentStyle}>
                    {steps[current].content === "step1" ? // เลือกประเภาเอกสาร (Ex. ใบเสนอราคา)
                        <Row gutter={[24, 0]}>
                            <Col span={4}>
                                <div style={{ paddingBottom: 6 }}>
                                    <label>ประเภทเอกสาร</label>
                                </div>
                                <Select
                                    value={documentCreate.documentTypeId}
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                    disabled={documentCreate.documentId}
                                    onChange={(e) => {
                                        setDocumentCreate(prevState => ({ ...prevState, documentTypeId: e }))
                                        // console.log("e --- : ", e, documentCreate)
                                    }}
                                >
                                    {documentTypeRef.current?.map((item, index) => (
                                        <Option key={index} value={item.documentTypeId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Col>

                            <Col span={6}>
                                <div style={{ paddingBottom: 6 }}>
                                    <label>ชื่อเอกสาร</label>
                                </div>
                                <Input
                                    value={documentCreate.name}
                                    onChange={(e) => {
                                        setDocumentCreate(prevState => ({ ...prevState, name: e.target.value }))
                                        // console.log("e --- : ", e.target.value, documentCreate)
                                    }}
                                />
                            </Col>

                            <Col span={4}>
                                <div style={{ paddingBottom: 6 }}>
                                    <label>วันที่สร้างเอกสาร</label>
                                </div>
                                <DatePicker
                                    value={documentCreate.createdAt ? dayjs(documentCreate.createdAt, formatDate) : undefined}
                                    format={formatDate}  // กำหนด format ที่ DatePicker ใช้แสดง
                                    style={{ width: "100%" }}
                                    onChange={(date) => {
                                        if (date) {
                                            const datePk = date.format(formatDate)
                                            setDocumentCreate(prevState => ({ ...prevState, createdAt: datePk }))
                                        } else {
                                            setDocumentCreate(prevState => ({ ...prevState, createdAt: '' })) // เคลียร์ค่าเมื่อไม่มีการเลือกวันที่
                                        }
                                    }}
                                />
                            </Col>

                            <Col span={4}>
                                <div style={{ paddingBottom: 6 }}>
                                    <label>เเสดงข้อมูลลูกค้า</label>
                                </div>
                                <Select
                                    defaultValue={isDisplayCustomerInfo}
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                    onChange={(e) => {
                                        setIsDisplayCustomerInfo(e)
                                    }}
                                >
                                    <Option key={0} value={true}>เเสดง</Option>
                                    <Option key={1} value={false}>ไม่เเสดง</Option>
                                </Select>
                            </Col>

                        </Row>
                        : []
                    }

                    {steps[current].content === "step2" ? // เลือกโปรเจกต์ (Ex. Kingdom Store) เเละงานใหม่, งานเดิม
                        <Row gutter={[24, 0]}>
                            <Col span={6}>
                                <div style={{ paddingBottom: 6 }}>
                                    <label>Project</label>
                                </div>
                                <Select
                                    value={documentCreate.projectId}
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                    disabled={documentCreate.documentId}
                                    onChange={async (e, index, record) => {
                                        let findProject = projectRef.current.filter(fill => fill.projectId === e)[0]
                                        // console.log("findProject : ", findProject)
                                        pjTargetRef.current = {
                                            projectId: e ?? null,
                                            projectName: findProject?.name ?? null,
                                            projectTypeId: findProject?.projectTypeId ?? null,
                                            projectTypeName: findProject?.project_type?.name ?? null,

                                            developmentDuration: (findProject?.developmentStart && findProject?.developmentEnd) ? dayDiff(findProject.developmentStart, findProject.developmentEnd) : null,

                                            customerInfo: findProject?.customer ?? null,
                                            packageInfo: findProject?.package ?? null,
                                            paymentInfo: findProject?.payment ?? null
                                        }

                                        await getProjectPhaseById(e)

                                        setDocumentCreate(prevState => ({ ...prevState, projectId: e, projectPhaseId: null }))
                                    }}
                                >
                                    {projectRef.current?.map((item, index) => (
                                        <Option key={index} value={item.projectId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Col>

                            <Col span={4}>
                                <div style={{ paddingBottom: 6 }}>
                                    <label>Project phase/round</label>
                                </div>
                                <Select
                                    value={documentCreate.projectPhaseId}
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    placeholder=""
                                    allowClear
                                    disabled={documentCreate.documentId || !documentCreate.projectId}
                                    onChange={async (e) => {

                                        let projectPhaseFill = projectPhase.filter(fill => fill.projectPhaseId === e)[0]
                                        setDocumentCreate(prevState => ({ ...prevState, paymentInstallmentId: projectPhaseFill.paymentInstallmentId }))
                                        setDocumentCreate(prevState => ({ ...prevState, paymentProcessId: projectPhaseFill.paymentProcessId }))
                                        await getPaymentProcessById(projectPhaseFill.paymentInstallmentId)
                                        // console.log("e --- : ", e, projectPhaseFill)

                                        setDocumentCreate(prevState => ({
                                            ...prevState,
                                            projectPhaseId: e,
                                            documentItemTypeId: null
                                        }))
                                        setDocumentItem([])
                                    }}
                                >
                                    {projectPhase?.map((item, index) => (
                                        <Option key={index} value={item.projectPhaseId}>{item.name} (Phase/Round {item.phase})</Option>
                                    ))}
                                </Select>
                            </Col>

                            <Col span={4}>
                                <div style={{ paddingBottom: 6 }}>
                                    <label>Document open type</label>
                                </div>
                                <Select
                                    value={documentCreate.documentOpenTypeId}
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                    disabled={documentCreate.documentId || !documentCreate.projectId}
                                    onChange={(e) => {
                                        setDocumentCreate(prevState => ({ ...prevState, documentOpenTypeId: e }))
                                        // console.log("e --- : ", e, documentCreate)
                                    }}
                                >
                                    {documentOpenType?.map((item, index) => (
                                        <Option key={index} value={item.documentOpenTypeId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                        : []
                    }

                    {steps[current].content === "step3" ? // ลงรายละเอียดข้อมูล (ใหม่ : สร้างรายการใหม่, เก่า : ดึงรายการที่มีอยู่นำมาใช้)
                        <Row gutter={[24, 24]}>
                            {documentCreate?.documentOpenTypeId === 2 ?
                                <>
                                    <Col span={4}>
                                        <Select
                                            value={documentCreate.paymentInstallmentId}
                                            showSearch
                                            placeholder="การเเบ่งจ่าย"
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                            disabled={documentCreate.documentId}
                                            onChange={async (e) => {
                                                setDocumentCreate(prevState => ({ ...prevState, paymentInstallmentId: e, paymentProcessId: null }))
                                                await getPaymentProcessById(e)
                                            }}
                                        >
                                            {paymentInstallment?.map((item, index) => (
                                                <Option key={index} value={item.paymentInstallmentId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Col>

                                    <Col span={6}>
                                        <Select
                                            value={documentCreate.paymentProcessId}
                                            showSearch
                                            placeholder="การชำระเงิน"
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                            disabled={documentCreate.documentId || !documentCreate.paymentInstallmentId}
                                            onChange={async (e) => {
                                                setDocumentCreate(prevState => ({ ...prevState, paymentProcessId: e }))
                                            }}
                                        >
                                            {paymentProcess?.map((item, index) => (
                                                <Option key={index} value={item.paymentProcessId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Col>

                                    <Col span={4}>
                                        <Select
                                            value={documentCreate.documentItemTypeId}
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                            placeholder="เลือก Document item type"
                                            disabled={documentCreate.documentId}
                                            onChange={async (e) => {
                                                setDocumentCreate(prevState => ({ ...prevState, documentItemTypeId: e }))
                                                // console.log("e --- : ", e, documentCreate)
                                                countDocumentItemRef.current = 0
                                                if (e === 1) { // task
                                                    await getTaskById()
                                                } else if (e === 2) { // case
                                                    await getCaseById()
                                                }
                                            }}
                                        >
                                            {documentItemType?.map((item, index) => (
                                                <Option key={index} value={item.documentItemTypeId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Col>
                                </>
                                : []
                            }

                            <Col span={(documentCreate?.documentOpenTypeId === 2 ? 10 : 24)}>
                                <div style={{ display: "flex", float: 'right' }}>
                                    <div style={{ paddingLeft: 10 }}>
                                        <Button
                                            type="primary"
                                            onClick={async () => {
                                                await customizeDocument(documentItem, documentCreate, pjTargetRef.current, documentTypeRef.current)
                                                setModalDocumentPreview({
                                                    isShow: true,
                                                    title: "Preview document"
                                                })
                                            }}
                                        >
                                            Preview
                                        </Button>
                                    </div>

                                    <div style={{ paddingLeft: 10 }}>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                formDocumentItem.setFieldsValue({
                                                    isNameDisplay: true,
                                                    isActive: true,
                                                    operationDiscount: 0,
                                                    operationCost: 0
                                                })

                                                setModalDocumentItem({
                                                    isShow: true,
                                                    title: "add"
                                                })
                                            }}
                                        >
                                            เพิ่ม Document item
                                        </Button>
                                    </div>
                                </div>
                            </Col>

                            <Col span={24}>
                                {!documentItemLoading ?
                                    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                                        <SortableContext items={documentItem.map((i) => i.key)} strategy={verticalListSortingStrategy}>
                                            <Table
                                                loading={documentItemLoading}
                                                dataSource={documentItem}
                                                pagination={false}
                                                components={{
                                                    body: { row: Rows }
                                                }}
                                                rowKey="key"
                                            >
                                                <Column key="sort" align={'center'} width={"3%"} render={(tags, record) => (<DragHandle />)} />
                                                {/* <Column title="No." dataIndex="index" key="index" width={"3%"} align={'center'} /> */}
                                                <Column
                                                    title="ชื่อ IV item"
                                                    dataIndex="name"
                                                    key="name"
                                                    width={"34%"}
                                                    render={(tags, record) => (
                                                        <>
                                                            {genTaskNameChange(tags, record, record.index)}
                                                        </>
                                                    )}
                                                />
                                                <Column
                                                    title="IV Group item"
                                                    dataIndex="documentGroupItem"
                                                    key="documentGroupItem"
                                                    width={"8%"}
                                                    render={(tags, record) => (
                                                        <>
                                                            <Select
                                                                defaultValue={tags}
                                                                showSearch
                                                                size="small"
                                                                style={{ width: '100%' }}
                                                                optionFilterProp="children"
                                                                allowClear
                                                                onChange={(e) => {
                                                                    let tmpDocumentItem = documentItem.map(item => item.documentItemId === record.documentItemId ? {
                                                                        ...item,
                                                                        documentGroupItem: e
                                                                    } : item)
                                                                    documentItemRef.current = tmpDocumentItem
                                                                    setDocumentItem(tmpDocumentItem)
                                                                }}
                                                            >
                                                                {documentGroupItem?.map((letter, index) => (
                                                                    <Option key={index} value={letter}>{letter}</Option>
                                                                ))}
                                                            </Select>
                                                        </>
                                                    )}
                                                />

                                                <Column
                                                    title="Project phase/round"
                                                    dataIndex="projectPhase"
                                                    key="projectPhase"
                                                    width={"14%"}
                                                />

                                                <Column
                                                    title="ค่าดำเนินงาน"
                                                    dataIndex="operationCost"
                                                    key="operationCost"
                                                    width={"8%"}
                                                    render={(tags, record) => (
                                                        <>
                                                            <InputNumber
                                                                defaultValue={tags}
                                                                size="small"
                                                                decimalSeparator={"."}
                                                                step={"0.01"}
                                                                style={{ border: "0px", padding: 0, margin: 0, borderRadius: 0, width: "100%" }}
                                                                onBlur={(e) => {
                                                                    let tmpDocumentItem = documentItem.map(item => item.documentItemId === record.documentItemId ? {
                                                                        ...item,
                                                                        operationCost: e.target.value
                                                                    } : item)
                                                                    documentItemRef.current = tmpDocumentItem
                                                                    setDocumentItem(tmpDocumentItem)
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                    align="center"
                                                />
                                                <Column
                                                    title="ส่วนลด"
                                                    dataIndex="operationDiscount"
                                                    key="operationDiscount"
                                                    width={"8%"}
                                                    render={(tags, record) => (
                                                        <>
                                                            <InputNumber
                                                                defaultValue={tags}
                                                                size="small"
                                                                decimalSeparator={"."}
                                                                step={"0.01"}
                                                                style={{ border: "0px", padding: 0, margin: 0, borderRadius: 0, width: "100%" }}
                                                                onBlur={(e) => {
                                                                    let tmpDocumentItem = documentItem.map(item => item.documentItemId === record.documentItemId ? {
                                                                        ...item,
                                                                        operationDiscount: e.target.value
                                                                    } : item)
                                                                    documentItemRef.current = tmpDocumentItem
                                                                    setDocumentItem(tmpDocumentItem)
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                    align="center"
                                                />
                                                <Column
                                                    title="การเเสดง"
                                                    dataIndex="isNameDisplay"
                                                    key="isNameDisplay"
                                                    width={"7%"}
                                                    render={(tags, record) => (
                                                        <>
                                                            <Select
                                                                defaultValue={tags}
                                                                showSearch
                                                                style={{ width: '100%' }}
                                                                optionFilterProp="children"
                                                                size="small"
                                                                allowClear
                                                                onChange={(e) => {
                                                                    let tmpDocumentItem = documentItem.map(item => item.documentItemId === record.documentItemId ? {
                                                                        ...item,
                                                                        isNameDisplay: e
                                                                    } : item)
                                                                    documentItemRef.current = tmpDocumentItem
                                                                    setDocumentItem(tmpDocumentItem)
                                                                }}
                                                            >
                                                                <Option key={0} value={true}>เเสดง</Option>
                                                                <Option key={1} value={false}>ไม่เเสดง</Option>
                                                            </Select>
                                                        </>
                                                    )}
                                                />
                                                <Column
                                                    title="รหัส Task"
                                                    dataIndex="taskCode"
                                                    key="taskCode"
                                                    width={"6%"}
                                                    align={'center'}
                                                    render={(tags, record) => (
                                                        <>
                                                            <label>{tags ? tags : "-"}</label>
                                                        </>
                                                    )}
                                                />
                                                <Column
                                                    title="รหัส Case"
                                                    dataIndex="caseCode"
                                                    key="caseCode"
                                                    width={"6%"}
                                                    align={'center'}
                                                    render={(tags, record) => (
                                                        <>
                                                            <label>{tags ? tags : "-"}</label>
                                                        </>
                                                    )}
                                                />
                                                <Column title={<FaCog />} dataIndex="operator" key="operator" width={"6%"} align={'center'} />
                                            </Table>
                                        </SortableContext>
                                    </DndContext>
                                    : []
                                }
                            </Col>
                        </Row>
                        : []
                    }

                    {steps[current].content === "step4" ? // การอนุมัติ เเละการใช้งาน
                        <Row gutter={[24, 0]}>
                            <Col span={4}>
                                <div style={{ paddingBottom: 6 }}>
                                    <label>Document open status</label>
                                </div>
                                <Select
                                    value={documentCreate.documentOpenStatusId}
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                    onChange={(e) => {
                                        setDocumentCreate(prevState => ({ ...prevState, documentOpenStatusId: e }))
                                        // console.log("e --- : ", e, documentCreate)
                                    }}
                                >
                                    {documentOpenStatus?.map((item, index) => (
                                        <Option key={index} value={item.documentOpenStatusId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Col>

                            <Col span={4}>
                                <div style={{ paddingBottom: 6 }}>
                                    <label>สถานะการใช้งาน</label>
                                </div>
                                <Select
                                    value={documentCreate.isActive}
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                    onChange={(e) => {
                                        setDocumentCreate(prevState => ({ ...prevState, isActive: e }))
                                        // console.log("e --- : ", e, documentCreate)
                                    }}
                                >
                                    <Option key={0} value={true}>เปิดการใช้งาน</Option>
                                    <Option key={1} value={false}>ปิดการใช้งาน</Option>
                                </Select>
                            </Col>
                        </Row>
                        : []
                    }
                </div>
            </Modal>

            <Modal
                title={<strong><label className="topic-color-bold">{modalDocumentItem.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modalDocumentItem.isShow}
                onCancel={() => {
                    // default
                    setFormDocumentItemDefault()
                }}
                width={650}
                onOk={() => {
                    formDocumentItem.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={formDocumentItem}
                    onFinish={onDocumentItemFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="documentItemId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item name="documentId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="name"
                                label={"ชื่อ Document item"}
                                rules={[{ required: true, message: 'กรุณากรอกชื่อ Document item' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="documentGroupItem"
                                label="Document item group"
                                rules={[{ required: true, message: 'กรุณาเลือก Document item group' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    {documentGroupItem?.map((letter, index) => (
                                        <Option key={index} value={letter}>{letter}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="isNameDisplay"
                                label={"การเเสดงชื่อรายการ"}
                                rules={[{ required: true, message: 'กรุณากรอกสถานะการเเสดงชื่อรายการ' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    <Option key={0} value={true}>เเสดง</Option>
                                    <Option key={1} value={false}>ไม่เเสดง</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="operationCost"
                                label={"ค่าดำเนินงาน"}
                            >
                                <InputNumber
                                    decimalSeparator={"."}
                                    step={"0.01"}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="operationDiscount"
                                label={"ส่วนลดดำเนินงาน"}
                            >
                                <InputNumber
                                    decimalSeparator={"."}
                                    step={"0.01"}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="isActive"
                                label="สถานะการใช้งาน"
                                rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    <Option key={0} value={true}>เปิดการใช้งาน</Option>
                                    <Option key={1} value={false}>ปิดการใช้งาน</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal
                title={<strong><label className="topic-color-bold">{modalDocumentPreview.title}</label></strong>}
                visible={modalDocumentPreview.isShow}
                onCancel={() => {
                    // default
                    setFormDocumentPreviewDefault()
                }}
                style={{ top: 8 }}
                width={650}
                footer={false}
            >
                {modalDocumentPreview.isShow ? genDocumentPreview() : []}
            </Modal>
        </Row>
    )
}