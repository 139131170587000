import moment from "moment"
import dayjs from 'dayjs'

const calculateDaysDifference = (date1, date2) => {
    const start = dayjs(date1)
    const end = dayjs(date2)

    const days = end.diff(start, 'day')
    const hours = end.diff(start.add(days, 'day'), 'hour');
    const minutes = end.diff(start.add(days, 'day').add(hours, 'hour'), 'minute');
    const seconds = end.diff(start.add(days, 'day').add(hours, 'hour').add(minutes, 'minute'), 'second');

    return {
        days,
        hours,
        minutes,
        seconds
    }
}

const convertToThaiTime = (dateString) => {
    // แปลง string วันที่เป็น Date object
    const date = new Date(dateString);

    // ใช้ toLocaleString เพื่อแปลงเวลาให้ตรงกับ Time Zone ของประเทศไทย
    const options = {
        timeZone: 'Asia/Bangkok',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };

    return date.toLocaleString('th-TH', options);
};

const genMonthName = (number) => {
    let month = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
    return month[number];
}

const modifyDate = (data) => {
    let dateSplit = data.split('-');
    if (data.search('-') === -1) {
        dateSplit = data.split("/");
        // format = "dd/mm/yyyy"
        return Number(dateSplit[0]) + " " + genMonthName(Number(dateSplit[1]) - 1) + " " + (Number(dateSplit[2]) + 543);
    } else {
        // format = "yyyy-mm-dd"
        return Number(dateSplit[2]) + " " + genMonthName(Number(dateSplit[1]) - 1) + " " + (Number(dateSplit[0]) + 543);
    }
}

const genDay = () => {
    let tmpDay = [];
    for (let i = 1; i <= 31; i++) {
        tmpDay.push({
            label: i,
            value: i
        });
    }

    return tmpDay;
}

const genMonth = () => {
    let tmpMonth = [
        { label: "มกราคม", value: 1 },
        { label: "กุมภาพันธ์", value: 2 },
        { label: "มีนาคม", value: 3 },
        { label: "เมษายน", value: 4 },
        { label: "พฤษภาคม", value: 5 },
        { label: "มิถุนายน", value: 6 },
        { label: "กรกฎาคม", value: 7 },
        { label: "สิงหาคม", value: 8 },
        { label: "กันยายน", value: 9 },
        { label: "ตุลาคม", value: 10 },
        { label: "พฤศจิกายน", value: 11 },
        { label: "ธันวาคม", value: 12 },
    ];

    return tmpMonth;
}

const genYear = (move) => {

    let tmpYear = [];
    let a = moment().format('YYYY');
    let yearCurrent = (Number(a) + 543) - 1;
    if (move === "regress") {
        for (let i = yearCurrent; i > (yearCurrent - 100); i--) {
            tmpYear.push({
                label: i + 1,
                value: i + 1
            });
        }
    } else if (move === "go-ahead") {
        for (let i = yearCurrent; i < (yearCurrent + 3); i++) {
            tmpYear.push({
                label: i + 1,
                value: i + 1
            });
        }
    }

    return tmpYear;
}

export {
    calculateDaysDifference,
    convertToThaiTime,
    modifyDate,
    genDay,
    genMonth,
    genYear
}