export const HTTP_SEARCH_BLOG_CATEGORY_FETCHING = "HTTP_SEARCH_BLOG_CATEGORY_FETCHING"
export const HTTP_SEARCH_BLOG_CATEGORY_SUCCESS = "HTTP_SEARCH_BLOG_CATEGORY_SUCCESS"
export const HTTP_SEARCH_BLOG_CATEGORY_FAILED = "HTTP_SEARCH_BLOG_CATEGORY_FAILED"

export const HTTP_SEARCH_BLOG_TITLE_FETCHING = "HTTP_SEARCH_BLOG_TITLE_FETCHING"
export const HTTP_SEARCH_BLOG_TITLE_SUCCESS = "HTTP_SEARCH_BLOG_TITLE_SUCCESS"
export const HTTP_SEARCH_BLOG_TITLE_FAILED = "HTTP_SEARCH_BLOG_TITLE_FAILED"

///////////////////////// Localization Begin /////////////////////////
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK"
export const NETWORK_CONNECTION_MESSAGE = "NETWORK_CONNECTION_MESSAGE"

export const serverUrl = process.env.REACT_APP_API_URL

export const apiServerUrl = serverUrl + "/api/v1"
