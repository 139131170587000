/* eslint-disable no-useless-concat */
import { httpClient } from "../../../../../utils/HttpClient"

const getProjectSearchFetch = async (value, isPermitAll) => {
    try {
        const result = await httpClient.post("project/get-project-search", { value, isPermitAll })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPaymentProcessByIdFetch = async (value) => {
    try {
        const result = await httpClient.post("category/get-payment-process-by-id", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getBusinessAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-business-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getProjectTypeByIdFetch = async (value) => {
    try {
        const result = await httpClient.post("category/get-project-type-by-id", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getWorkProcessByIdFetch = async (value) => {
    try {
        const result = await httpClient.post("category/get-work-process-by-id", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCustomerAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-customer-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPackageByIdFetch = async (value) => {
    try {
        const result = await httpClient.post("category/get-package-by-id", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPaymentInstallmentAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-payment-installment-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getProjectPhaseSearch = async (value) => {
    try {
        const result = await httpClient.post("project/get-project-phase-search", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getProjectPhaseByIdFetch = async (value) => {
    try {
        const result = await httpClient.post("project/get-project-phase-by-id", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getServiceAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-service-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertProjectFetch = async (value) => {
    try {
        const result = await httpClient.post("project/insert-project", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertProjectPhaseFetch = async (value) => {
    try {
        const result = await httpClient.post("project/insert-project-phase", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateProjectByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("project/update-project-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateProjectPhaseByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("project/update-project-phase-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteProjectByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("project/delete-project-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteProjectPhaseByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("project/delete-project-phase-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getProjectSearchFetch,
    getPaymentProcessByIdFetch,
    getBusinessAllFetch,
    getProjectTypeByIdFetch,
    getWorkProcessByIdFetch,
    getCustomerAllFetch,
    getPackageByIdFetch,
    getPaymentInstallmentAllFetch,
    getProjectPhaseSearch,
    getProjectPhaseByIdFetch,
    getServiceAllFetch,

    // insert
    insertProjectFetch,
    insertProjectPhaseFetch,

    // update
    updateProjectByIdFetch,
    updateProjectPhaseByIdFetch,

    // delete
    deleteProjectByIdFetch,
    deleteProjectPhaseByIdFetch
}
