/* eslint-disable no-useless-concat */
import { httpClient } from "../../../../../utils/HttpClient"

const getTransactionPaidEmployeeMasterSearchFetch = async (value, isPermitAll) => {
    try {
        const result = await httpClient.post("payment/get-transaction-paid-employee-master-search", { value, isPermitAll })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPaidEmployeeStatusAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-paid-employee-status-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getTransactionPaidEmployeeTypeAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-transaction-paid-employee-type-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getProjectAllFetch = async (value, isShowAll) => {
    try {
        const result = await httpClient.post("category/get-project-all", { value, isShowAll })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getBankAccountPaymentFetch = async () => {
    try {
        const result = await httpClient.get("payment/get-bank-account-payment")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updatePaidEmployeeStatusByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("payment/update-paid-employee-status-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteTransactionPaidEmployeeByIdFetch = async (id, employeeId, projectId, transactionPaidEmployeeTypeId) => {
    try {
        const result = await httpClient.delete(
            "payment/delete-transaction-paid-employee-by-id" + `/${id}?employeeId=${employeeId}&projectId=${projectId}&transactionPaidEmployeeTypeId=${transactionPaidEmployeeTypeId}`
        )
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getTransactionPaidEmployeeMasterSearchFetch,
    getPaidEmployeeStatusAllFetch,
    getTransactionPaidEmployeeTypeAllFetch,
    getProjectAllFetch,
    getBankAccountPaymentFetch,

    // insert

    // update
    updatePaidEmployeeStatusByIdFetch,

    // delete
    deleteTransactionPaidEmployeeByIdFetch
}