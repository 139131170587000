export const downloadFile = async (fileUrl, fileName) => {
    try {
        const response = await fetch(fileUrl, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error('Failed to fetch file');
        }

        // Convert the response to a Blob (binary large object)
        const blob = await response.blob();

        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link element
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName; // ชื่อไฟล์ที่ต้องการให้ดาวน์โหลด
        document.body.appendChild(a);

        // Programmatically click the link to trigger the download
        a.click();

        // Clean up and remove the link
        a.remove();
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};