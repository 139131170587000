import './fonts.css'

const themeAntd = {
    colorPrimary: "#429321",
    fontSize: 14,
    fontFamily: "IBMPlexSansThai-Regular",

    rateStarColor: "white", // สีของดาวที่ยังไม่ได้เลือก
    rateStarSelectedColor: "#429321", // สีของดาวที่ถูกเลือก
    rateStarSelectedBg: "transparent", // สีพื้นหลังของดาวที่ถูกเลือก
}

export default themeAntd