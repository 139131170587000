/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-duplicate-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useEffect } from 'react'
import {
    Button,
    Layout,
    Menu
} from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { useNavigate } from 'react-router-dom'
import ManageEmployee from './manage/employee'
import ManageCustomer from './manage/customer'
import ManageProject from './manage/project'
import { permission } from '../../common/mamager/PermissionManager'
import ManageEmployeeCost from './manage/employeeCost'
import ManageCase from './manage/case'
import ManageDocument from './manage/document'

const { Sider } = Layout
// const { SubMenu } = Menu

export default function ERP() {
    const navigate = useNavigate()

    const [selectedMenuItem, setSelectedMenuItem] = useState("manage-project")

    const [auth, setAuth] = useState(null)
    const [loading, setLoading] = useState(true)
    // const auth = JSON.parse(sessionStorage.getItem('auth'))
    // console.log("auth : ", auth)



    const componentsSwitch = (type) => {
        switch (type) {
            case "manage-project":
                return <ManageProject />
            case "manage-case":
                return <ManageCase />
            case "manage-employee":
                return <ManageEmployee />
            case "manage-customer":
                return <ManageCustomer />
            case "manage-employee-cost":
                return <ManageEmployeeCost />
            case "manage-document":
                return <ManageDocument />
            default:
                return null
        }
    }

    const getBaseApi = async () => {
        setLoading(true)

        setAuth(JSON.parse(sessionStorage.getItem('auth')))

        setLoading(false)
    }

    // if (!auth) {
    //     return null
    // }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <div style={{ backgroundColor: '#ECECEC' }}>
            <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                <PageHeader
                    title={
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <label style={{ paddingBottom: 0, marginBottom: 0, color: "white", fontSize: 20, paddingRight: 12 }}>Soft Po Market - ERP</label>
                            <label style={{ paddingBottom: 0, marginBottom: 0, color: "white", fontSize: 16, fontWeight: "initial" }}>ผู้ใช้งาน : {auth?.firstname} ({auth?.nickname})</label>
                        </div>
                    }
                    extra={[
                        <Button
                            key="signout"
                            type="default"
                            onClick={() => {
                                navigate("/")
                            }}
                            style={{ position: 'absolute', marginTop: -15, marginLeft: -115 }}
                        >ออกจากระบบ</Button>
                    ]}
                    style={{ backgroundColor: 'black' }}
                >
                </PageHeader>
                {!loading ?
                    <Layout>
                        {/* {console.log("auth --- : ", auth)} */}
                        <Sider
                            width={200}
                            className="site-layout-background"
                        >
                            <Menu
                                defaultSelectedKeys={['manage-project']}
                                defaultOpenKeys={['manage-employee']}
                                mode="inline"
                                selectedKeys={selectedMenuItem}
                                onClick={async (e) => {
                                    setSelectedMenuItem(e.key)
                                }}
                                style={{ height: '100%' }}
                            >
                                <Menu.Item key="manage-project">
                                    <label style={{ paddingLeft: 0, marginBottom: 0, cursor: 'pointer' }}>
                                        {permission("erp", "ERP-0001") ? "จัดการ Project" : "Project ที่รับมอบหมาย"} {/* // [ERP-0001] Manage project */}
                                    </label>
                                </Menu.Item>

                                <Menu.Item key="manage-case">
                                    <label style={{ paddingLeft: 0, marginBottom: 0, cursor: 'pointer' }}>
                                        {permission("erp", "ERP-0002") ? "จัดการ Case" : "Case ที่รับมอบหมาย"} {/* // [ERP-0002] Manage case */}
                                    </label>
                                </Menu.Item>

                                {permission("erp", "ERP-0003") ? // [ERP-0003] Manage employee
                                    <Menu.Item key="manage-employee">
                                        <label style={{ paddingLeft: 0, marginBottom: 0, cursor: 'pointer' }}>จัดการพนักงาน</label>
                                    </Menu.Item>
                                    : null
                                }

                                {permission("erp", "ERP-0004") ? // [ERP-0004] Manage customer
                                    <Menu.Item key="manage-customer">
                                        <label style={{ paddingLeft: 0, marginBottom: 0, cursor: 'pointer' }}>จัดการลูกค้า</label>
                                    </Menu.Item>
                                    : null
                                }

                                <Menu.Item key="manage-employee-cost">
                                    <label style={{ paddingLeft: 0, marginBottom: 0, cursor: 'pointer' }}>
                                        {permission("erp", "ERP-0005") ? "จัดการค่าใช้จ่ายพนักงาน" : "รายได้ค่าตอบเเทน"} {/* // [ERP-0005] manage employee expenses */}
                                    </label>
                                </Menu.Item>

                                {permission("erp", "ERP-0006") ? // [ERP-0006] Manage document
                                    <Menu.Item key="manage-document">
                                        <label style={{ paddingLeft: 0, marginBottom: 0, cursor: 'pointer' }}>จัดการ Document</label>
                                    </Menu.Item>
                                    : null
                                }

                            </Menu>
                        </Sider>
                        <article style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                            <div style={{ padding: 20 }}>
                                {componentsSwitch(selectedMenuItem)}
                            </div>
                        </article>
                    </Layout>
                    : []
                }
            </div>
        </div>
    )
}
