/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Select,
    InputNumber,
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import {
    deleteEmployeeByIdFetch,
    getBankAllFetch,
    getEmployeeDepartmentAllFetch,
    getEmployeeLevelAllFetch,
    getEmployeePositionAllFetch,
    getEmployeeSearchFetch,
    insertEmployeeFetch,
    updateEmployeeByIdFetch
} from './API'
import dayjs from 'dayjs'

const { TextArea } = Input;
const { Option } = Select
const { RangePicker } = DatePicker
const { Column } = Table
const formatDate = "DD/MM/YYYY"

export default function ManageEmployee() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const [employeeLevel, setEmployeeLevel] = useState(null)
    const [employeeDepartment, setEmployeeDepartment] = useState(null)
    const [employeePosition, setEmployeePosition] = useState(null)
    const [bank, setBank] = useState(null)

    const auth = JSON.parse(sessionStorage.getItem('auth'))
    // console.log("auth : ", auth)

    const getEmployeeBySearch = async (search) => {
        setLoading(true)

        const result = await getEmployeeSearchFetch(search)
        // console.log("getEmployeeSearchFetch : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                firstname: (val.firstname && val.lastname) ? (val.firstname + " " + val.lastname) : "-",
                nickname: val.nickname ?? "-",
                isActive: val.isActive,
                emailCompany: val.emailCompany ?? "-",
                employeeDepartment: val.employee_department.name ?? "-",
                employeePosition: val.employee_position.name ?? "-",
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                // updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 28,
                                padding: 0,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                // borderRadius: 50
                            }}
                            onClick={async () => {
                                form.setFieldsValue({
                                    employeeId: val.employeeId,
                                    employeeLevel: val.employeeLevelId,
                                    employeeDepartment: val.employeeDepartmentId,
                                    employeePosition: val.employeePositionId,
                                    firstname: val.firstname,
                                    lastname: val.lastname,
                                    nickname: val.nickname,
                                    emailCompany: val.emailCompany,
                                    emailPersonal: val.emailPersonal,
                                    username: val.employee_auth.username,
                                    bank: val.bankId,
                                    bankNumber: val.bankNumber,
                                    bankAccount: val.bankAccount,
                                    gitBranchFomat: val.gitBranchFomat,
                                    incomeHourly: val.incomeHourly,
                                    incomeWeekly: val.incomeWeekly,
                                    incomeMonthly: val.incomeMonthly,
                                    employmentContractStart: val.employmentContractStart ? dayjs(val.employmentContractStart) : undefined,
                                    employmentContractEnd: val.employmentContractEnd ? dayjs(val.employmentContractEnd) : undefined,
                                    isActive: val.isActive,
                                })

                                await getEmployeePositionAll(val.employeeDepartmentId)

                                setModal({ isShow: true, title: "edit" })
                            }}
                            size="small"
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 15, height: 15 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleEmployeeDelete(val.employeeId)

                                // reload
                                await getEmployeeBySearch({
                                    firstname: null,
                                    lastname: null,
                                    nickname: null,
                                    employeeLevelId: null,
                                    employeeDepartmentId: null,
                                    startedDate: null,
                                    endDate: null
                                })
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 28,
                                    padding: 0,
                                    // borderRadius: 50
                                }}
                                size="small"
                            >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 15, height: 15 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            });
        });
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let employee = {
            employeeId: values.employeeId ?? null,
            employeeLevelId: values.employeeLevel ?? null,
            employeeDepartmentId: values.employeeDepartment ?? null,
            employeePositionId: values.employeePosition ?? null,
            firstname: values.firstname ?? null,
            lastname: values.lastname ?? null,
            nickname: values.nickname ?? null,
            emailCompany: values.emailCompany ?? null,
            emailPersonal: values.emailPersonal ?? null,
            bankId: values.bank ?? null,
            bankNumber: values.bankNumber ?? null,
            bankAccount: values.bankAccount ?? null,
            gitBranchFomat: values.gitBranchFomat ?? null,
            incomeHourly: values.incomeHourly ?? null,
            incomeWeekly: values.incomeWeekly ?? null,
            incomeMonthly: values.incomeMonthly ?? null,
            employmentContractStart: values.employmentContractStart ?? null,
            employmentContractEnd: values.employmentContractEnd ?? null,
            isActive: values.isActive
        }

        let employeeAuth = {
            username: values.username ?? null,
            password: values.password ?? null,
        }

        if (values.password === values.passwordAgain) {
            if (modal.title === "add") {
                const result = await insertEmployeeFetch(employee, employeeAuth)
                if (result?.isSuccess) {
                    Notification('success', 'สร้างสำเร็จ')
                    // set default
                    setFormDefault()
                } else {
                    Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
                }
            } else if (modal.title === "edit") {
                const result = await updateEmployeeByIdFetch(employee)
                if (result.isSuccess) {
                    Notification('success', 'เเก้ไขสำเร็จ')
                    // set default
                    setFormDefault()
                } else {
                    Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
                }
            }
        } else {
            Notification('error', 'รหัสผ่านไม่ตรงกัน')
        }

        // reload
        getEmployeeBySearch({
            firstname: null,
            lastname: null,
            nickname: null,
            employeeLevelId: null,
            employeeDepartmentId: null,
            startedDate: null,
            endDate: null
        })
    }

    const onSearchFinish = async (values) => {
        let firstname = values?.firstname ?? null
        let employeeDepartmentId = values?.employeeDepartment ?? null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null

        let objSearch = {
            firstname,
            lastname: null,
            nickname: null,
            employeeLevelId: null,
            employeeDepartmentId,
            startedDate,
            endDate
        }
        // console.log("onSearchFinish : ", objSearch)
        await getEmployeeBySearch(objSearch)
    }

    const handleEmployeeDelete = async (id) => {
        const result = await deleteEmployeeByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setModal({
            isShow: false,
            title: null,
        })
    }

    const getEmployeeLevelAll = async () => {
        const result = await getEmployeeLevelAllFetch()
        // console.log("getEmployeeLevelAllFetch : ", result)
        setEmployeeLevel(result)
    }

    const getEmployeeDepartmentAll = async () => {
        const result = await getEmployeeDepartmentAllFetch()
        // console.log("getEmployeeDepartmentAllFetch : ", result)
        setEmployeeDepartment(result)
    }

    const getEmployeePositionAll = async (id) => {
        let obj = {
            employeeDepartmentId: id
        }
        const result = await getEmployeePositionAllFetch(obj)
        // console.log("getEmployeePositionAllFetch : ", result)
        setEmployeePosition(result)
    }

    const getBankAll = async () => {
        const result = await getBankAllFetch()
        // console.log("getBankAllFetch : ", result)
        setBank(result)
    }

    const getBaseApi = async () => {
        await getEmployeeLevelAll()
        await getEmployeeDepartmentAll()
        await getEmployeePositionAll()
        await getBankAll()
    }

    useEffect(() => {
        getEmployeeBySearch({
            firstname: null,
            lastname: null,
            nickname: null,
            employeeLevelId: null,
            employeeDepartmentId: null,
            startedDate: null,
            endDate: null
        })
        getBaseApi()
    }, [])

    return (
        <Row>
            <Col span={6}>
                <label>จัดการพนักงาน ({list?.length} คน)</label>
            </Col>
            <Col span={18} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ชื่อจริงพนักงาน"
                                name="firstname"
                            >
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                name="employeeDepartment"
                                label="เเผนกพนักงาน"
                            >
                                <Select
                                    showSearch
                                    style={{ width: 160 }}
                                    optionFilterProp="children"
                                    allowClear
                                // onChange={async (e) => {
                                //     await getEmployeePositionAll(e)
                                //     form.setFieldValue("employeePosition", null, true)
                                // }}
                                >
                                    {employeeDepartment?.map((item, index) => (
                                        <Option key={index} value={item.employeeDepartmentId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item label="ช่วงวันที่สร้าง" name="dateRange">
                                <RangePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => formSearch.submit()}
                            >
                                ค้นหา
                            </Button>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                type="primary"
                                style={{ float: 'right' }}
                                onClick={() => {
                                    form.resetFields()
                                    setModal({
                                        isShow: true,
                                        title: "add"
                                    })
                                }}
                            >เพิ่มรายการ</Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    // columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                    <Column title="No." dataIndex="index" key="index" width={"4%"} align={'center'} />
                    <Column title="ชื่อจริง-นามสกุล" dataIndex="firstname" key="firstname" width={"19%"} />
                    <Column title="ชื่อเล่น" dataIndex="nickname" key="nickname" width={"8%"} />
                    <Column title="อีเมลบริษัท" dataIndex="emailCompany" key="emailCompany" width={"21%"} />
                    <Column title="เเผนก" dataIndex="employeeDepartment" key="employeeDepartment" width={"10%"} />
                    <Column title="ตำเเหน่ง" dataIndex="employeePosition" key="employeePosition" width={"10%"} />
                    <Column
                        title="สถานะ"
                        dataIndex="isActive"
                        key="isActive"
                        width={"10%"}
                        render={(tags, record) => (
                            <>
                                {record.isActive ?
                                    <label>เปิดการใช้งาน</label>
                                    :
                                    <label>ปิดการใช้งาน</label>
                                }
                            </>
                        )}
                        align={'center'}
                    />
                    <Column title="วันที่สร้าง" dataIndex="createdAt" key="createdAt" width={"10%"} align={'center'} />
                    {/* <Column title="วันที่เเก้ไข" dataIndex="updatedAt" key="updatedAt" width={"10%"} /> */}
                    <Column title={<FaCog />} dataIndex="operator" key="operator" width={"14%"} align={'center'} />
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault()
                }}
                width={650}
                onOk={() => {
                    form.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={24}>
                            <Form.Item name="employeeId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="firstname"
                                label="ชื่อจริงพนักงาน"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อจริงพนักงาน' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="lastname"
                                label="นามสกุลพนักงาน"
                                rules={[{ required: true, message: 'กรุณากรอกนามสกุลพนักงาน' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="nickname"
                                label="ชื่อเล่น (คุณ...)"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อเล่น' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="employeeLevel"
                                label="ระดับพนักงาน"
                                rules={[{ required: true, message: 'กรุณาเลือกระดับพนักงาน' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    {employeeLevel?.map((item, index) => (
                                        <Option key={index} value={item.employeeLevelId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="employeeDepartment"
                                label="เเผนกพนักงาน"
                                rules={[{ required: true, message: 'กรุณาเลือกเเผนกพนักงาน' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                    onChange={async (e) => {
                                        await getEmployeePositionAll(e)
                                        form.setFieldValue("employeePosition", null, true)
                                    }}
                                >
                                    {employeeDepartment?.map((item, index) => (
                                        <Option key={index} value={item.employeeDepartmentId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="employeePosition"
                                label="ตำเเหน่งพนักงาน"
                                rules={[{ required: true, message: 'กรุณาเลือกตำเเหน่งพนักงาน' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    {employeePosition?.map((item, index) => (
                                        <Option key={index} value={item.employeePositionId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="username"
                                label={"ชื่อผู้ใช้"}
                                rules={[{ required: true, message: 'กรุณากรอกชื่อผู้ใช้' }]}
                            >
                                <Input disabled={modal.title === "add" ? false : true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="emailCompany"
                                label={"อีเมลบริษัท"}
                                rules={[{ required: true, message: 'กรุณากรอกอีเมลบริษัท' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="emailPersonal"
                                label={"อีเมลส่วนตัว"}
                                rules={[{ required: true, message: 'กรุณากรอกอีเมลส่วนตัว' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        {modal.title === "add" ?
                            <>
                                <Col span={12}>
                                    <Form.Item
                                        name="password"
                                        label={modal.title === "add" ? "รหัสผ่าน" : "รหัสผ่านใหม่"}
                                        rules={[{ required: true, message: "กรุณากรอกรหัสผ่าน" }]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="passwordAgain"
                                        label={"รหัสผ่าน อีกครั้ง"}
                                        rules={[{ required: true, message: "กรุณากรอกรหัสผ่านใหม่ อีกครั้ง" }]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </Col>
                            </>
                            : []
                        }

                        <Col span={12}>
                            <Form.Item
                                name="bank"
                                label="ธนาคาร"
                                rules={[{ required: true, message: 'กรุณาเลือกธนาคาร' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    {bank?.map((item, index) => (
                                        <Option key={index} value={item.bankId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="bankNumber"
                                label={"เลขบัญชีธนาคาร"}
                                rules={[{ required: true, message: 'กรุณากรอกเลขบัญชีธนาคาร' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="bankAccount"
                                label={"ชื่อบัญชีธนาคาร"}
                                rules={[{ required: true, message: 'กรุณากรอกชื่อบัญชีธนาคาร' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="incomeHourly"
                                label={"รายได้เเบบรายชั่วโมง"}
                            >
                                <InputNumber
                                    decimalSeparator={"."}
                                    step={"0.01"}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="incomeWeekly"
                                label={"รายได้เเบบรายเดือน"}
                            >
                                <InputNumber
                                    decimalSeparator={"."}
                                    step={"0.01"}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="incomeMonthly"
                                label={"รายได้เเบบรายปี"}
                            >
                                <InputNumber
                                    decimalSeparator={"."}
                                    step={"0.01"}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="employmentContractStart"
                                label={"วันเริ่มสัญญา"}
                                // rules={[{ required: true, message: 'กรุณากรอกวันเริ่มสัญญา' }]}
                            >
                                <DatePicker
                                    showTime
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="employmentContractEnd"
                                label={"วันหมดสัญญา"}
                                // rules={[{ required: true, message: 'กรุณากรอกวันหมดสัญญา' }]}
                            >
                                <DatePicker
                                    showTime
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="gitBranchFomat"
                                label={"Git Branch ของพนักงานท่านนี้"}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="isActive"
                                label="สถานะการใช้งาน"
                                rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    <Option key={0} value={true}>เปิดการใช้งาน</Option>
                                    <Option key={1} value={false}>ปิดการใช้งาน</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Row>
    )
}