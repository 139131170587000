import { Notification } from "../../../../../common/components/notification"
import { getDetectOpenCaseFetch } from "../API"

export const detectOpenCase = async (customerId, projectId) => {

    if (customerId && projectId) {
        let result = await getDetectOpenCaseFetch({
            customerId, 
            projectId
        })
        if(!result?.isCanOpenCase) { // Over limit
            Notification('error', 'Case ที่เปิดดำเนินการอยู่นี้เกิน Limit เเล้ว จึงไม่สามารถเปิดเคสนี้เพิ่มได้ โปรดรอเคียร์ Case ก่อนหน้าเหล่านั้นให้เเล้วเสร็จก่อน!')
        }
        return result
    } else {
        return {
            isCanOpenCase: true,
            amountCase: 0,
            list: []
        }
    }
}