/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Select,
    InputNumber,
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import {
    deleteCustomerByIdFetch,
    getCustomerSearchFetch,
    getGenderAllFetch,
    getPersonTypeAllFetch,
    getPrefixAllFetch,
    insertCustomerFetch,
    updateCustomerByIdFetch,
} from './API'

const { TextArea } = Input;
const { Option } = Select
const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "4%",
        align: 'center'
    },
    {
        title: 'รหัสลูกค้า',
        dataIndex: 'uid',
        width: "10%",
    },
    {
        title: 'ชื่อเล่นลูกค้า',
        dataIndex: 'nickname',
        width: "30%",
    },
    {
        title: 'ประเภทบุคคล',
        dataIndex: 'personType',
        width: "16%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: 'สถานะ',
        width: "10%",
        render: (text, record) => {
            return (
                <>
                    {record.isActive ?
                        <label>เปิดการใช้งาน</label>
                        :
                        <label>ปิดการใช้งาน</label>
                    }
                </>
            )
        }
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "15%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function ManageCustomer() {

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    const [pageCurrent, setPageCurrent] = useState(1)

    const [personTypeSelected, setPersonTypeSelected] = useState(false)

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const [personType, setPersonType] = useState([])
    const [prefix, setPrefix] = useState([])
    const [gender, setGender] = useState([])

    const auth = JSON.parse(sessionStorage.getItem('auth'))
    // console.log("auth : ", auth)

    const getCustomerBySearch = async (search) => {
        setLoading(true)

        const result = await getCustomerSearchFetch(search)
        // console.log("getCustomerSearchFetch : ", result)

        let tempList = [];
        result?.map((val, index) => {
            tempList.push({
                index: (index + 1),
                uid: val.uid,
                nickname: val.nickname,
                personType: val?.person_type?.name ?? "-",
                personTypeId: val.personTypeId,
                isActive: val.isActive,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 28,
                                padding: 0,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white'
                            }}
                            onClick={() => {
                                form.setFieldsValue({
                                    customerId: val.customerId,

                                    // ข้อมูลส่วนตัว
                                    uid: val.uid,
                                    nickname: val.nickname,
                                    taxIdentificationNumber: val.taxIdentificationNumber,
                                    prefix: val.prefixId,
                                    firstName: val.firstName,
                                    lastName: val.lastName,

                                    // ช่องทางติดต่อ
                                    line: val.line,
                                    facebook: val.facebook,
                                    phoneNumber: val.phoneNumber,
                                    email: val.email,

                                    // ที่อยู่
                                    address: val.address,
                                    district: val.district,
                                    province: val.province,
                                    zipCode: val.zipCode,

                                    // ประเภทบุคคล
                                    personType: val.personTypeId,
                                    gender: val.natural.genderId,
                                    companyName: val.juristic.companyName,
                                    isHeadOffice: val.juristic.isHeadOffice,
                                    branch: val.juristic.branch,

                                    isActive: val.isActive
                                })
                                setPersonTypeSelected(val.personTypeId)

                                setModal({ isShow: true, title: "edit" })
                            }}
                            size="small"
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 15, height: 15 }} />
                            </div>
                        </Button>{"  "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await handleCustomerDelete(val.customerId)

                                // reload
                                await getCustomerBySearch({
                                    uid: null,
                                    facebook: null,
                                    line: null,
                                    startedDate: null,
                                    endDate: null
                                })
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 28,
                                    padding: 0
                                }}
                                size="small"
                            >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 15, height: 15 }} />
                                </div>
                            </Button>
                        </Popconfirm>
                    </>
            });
        });
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let obj = {
            customerId: values.customerId ?? null,

            // ข้อมูลส่วนตัว
            uid: values.uid ?? null,
            nickname: values.nickname ?? null,
            prefixId: values.prefix ?? null,
            firstName: values.firstName ?? null,
            lastName: values.lastName ?? null,
            taxIdentificationNumber: values.taxIdentificationNumber ?? null,

            // ช่องทางติดต่อ
            line: values.line ?? null,
            facebook: values.facebook ?? null,
            phoneNumber: values.phoneNumber ?? null,
            email: values.email ?? null,

            // ที่อยู่
            address: values.address ?? null,
            district: values.district ?? null,
            province: values.province ?? null,
            zipCode: values.zipCode ?? null,

            // ประเภทบุคคล
            personTypeId: values.personType ?? null,
            genderId: values.gender ?? null,
            // -------------------------------------
            companyName: values.companyName ?? null,
            isHeadOffice: values.isHeadOffice ?? null,
            branch: values.branch ?? null
        }

        if (modal.title === "add") {
            const result = await insertCustomerFetch(obj)
            if (result?.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
                // set default
                setFormDefault()
            } else {
                Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit") {
            const result = await updateCustomerByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
                // set default
                setFormDefault()
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        getCustomerBySearch({
            uid: null,
            facebook: null,
            line: null,
            startedDate: null,
            endDate: null
        })
    }

    const onSearchFinish = async (values) => {
        let uid = values?.uid ? values.uid : null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null

        let objSearch = {
            uid,
            facebook: null,
            line: null,
            startedDate,
            endDate
        }
        // console.log("onSearchFinish : ", objSearch)
        await getCustomerBySearch(objSearch)
    }

    const handleCustomerDelete = async (id) => {
        const result = await deleteCustomerByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setModal({
            isShow: false,
            title: null,
        })
    }

    const getPersonTypeAll = async () => {
        const result = await getPersonTypeAllFetch()
        // console.log("getPersonTypeAllFetch : ", result)
        setPersonType(result)
    }

    const getPrefixAll = async () => {
        const result = await getPrefixAllFetch()
        // console.log("getPrefixAllFetch : ", result)
        setPrefix(result)
    }

    const getGenderAll = async () => {
        const result = await getGenderAllFetch()
        // console.log("getGenderAllFetch : ", result)
        setGender(result)
    }

    const getBaseApi = async () => {
        await getPersonTypeAll()
        await getPrefixAll()
        await getGenderAll()
    }

    useEffect(() => {
        getCustomerBySearch({
            uid: null,
            facebook: null,
            line: null,
            startedDate: null,
            endDate: null
        })
        getBaseApi()
    }, [])

    return (
        <Row>
            <Col span={6}>
                <label>จัดการพนักงาน</label>
            </Col>
            <Col span={18} style={{ paddingBottom: 20 }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="รหัสลูกค้า"
                                name="uid"
                                style={{ width: 100 }}
                            >
                                <Input />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                name="personType"
                                label="ประเภทบุคคล"
                            >
                                <Select
                                    showSearch
                                    style={{ width: 120 }}
                                    optionFilterProp="children"
                                    allowClear
                                    onChange={(e) => {
                                        setPersonTypeSelected(e)
                                    }}
                                >
                                    {personType?.map((item, index) => (
                                        <Option key={index} value={item.personTypeId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ช่วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => formSearch.submit()}
                            >
                                ค้นหา
                            </Button>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                type="primary"
                                style={{ float: 'right' }}
                                onClick={() => {
                                    const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                                    const lowerCase = "abcdefghijklmnopqrstuvwxyz";
                                    const numbers = "0123456789";
                                    const allCharacters = upperCase + lowerCase + numbers;

                                    let uid = "";

                                    // สุ่มให้มีตัวพิมพ์ใหญ่ ตัวพิมพ์เล็ก และตัวเลข อย่างน้อยอย่างละ 1 ตัว
                                    uid += upperCase[Math.floor(Math.random() * upperCase.length)];
                                    uid += lowerCase[Math.floor(Math.random() * lowerCase.length)];
                                    uid += numbers[Math.floor(Math.random() * numbers.length)];

                                    // สุ่มอักขระที่เหลือจาก allCharacters
                                    for (let i = 3; i < 6; i++) {
                                        uid += allCharacters[Math.floor(Math.random() * allCharacters.length)];
                                    }

                                    // สลับตำแหน่งอักขระใน uid เพื่อไม่ให้เรียงตัวอักษรเดิมเสมอ
                                    uid = uid.split('').sort(() => 0.5 - Math.random()).join('');

                                    form.resetFields()
                                    form.setFieldsValue({
                                        uid,
                                        isActive: true
                                    })

                                    setModal({
                                        isShow: true,
                                        title: "add"
                                    })
                                }}
                            >เพิ่มรายการ</Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข้อมูลลูกค้า" : "เเก้ไขข้อมูลลูกค้า"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault()
                }}
                width={"100%"}
                style={{
                    top: 8,
                    padding: 0
                }}
                onOk={() => {
                    form.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    {/* ข้อมูลส่วนตัว */}
                    <div style={{ borderLeft: "2px solid #EEEEEE", borderRight: "2px solid #EEEEEE", borderTop: "2px solid #EEEEEE", paddingTop: 6, paddingLeft: 12, paddingRight: 12 }}>
                        <Row gutter={[12, 0]}>
                            <Col span={24}>
                                <Form.Item name="customerId" style={{ display: "none" }}>
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item
                                    name="uid"
                                    label="รหัสลูกค้า"
                                    // rules={[{ required: true, message: 'กรุณากรอกรหัสลูกค้า' }]}
                                >
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item
                                    name="nickname"
                                    label="ชื่อเล่นลูกค้า"
                                    rules={[{ required: true, message: 'กรุณาเลือกชื่อเล่นลูกค้า' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name="taxIdentificationNumber" label="เลขประจำตัวผู้เสียภาษี" >
                                    <InputNumber style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name="prefix" label="คำนำหน้า" >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        allowClear
                                        onChange={(e) => {
                                            setPersonTypeSelected(e)
                                        }}
                                    >
                                        {prefix?.map((item, index) => (
                                            <Option key={index} value={item.prefixId}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name="firstName" label="ชื่อจริง" >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name="lastName" label="นามสกุล" >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>

                    {/* ช่องทางติดต่อ */}
                    <div style={{ borderLeft: "2px solid #EEEEEE", borderRight: "2px solid #EEEEEE", borderTop: "2px solid #EEEEEE", paddingTop: 6, paddingLeft: 12, paddingRight: 12 }}>
                        <Row gutter={[12, 0]}>
                            <Col span={6}>
                                <Form.Item name="facebook" label="Facebook" >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name="line" label="Line" >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name="phoneNumber" label="เบอร์โทร" >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name="email" label="อีเมล" >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>

                    {/* ที่อยู่ */}
                    <div style={{ borderLeft: "2px solid #EEEEEE", borderRight: "2px solid #EEEEEE", borderTop: "2px solid #EEEEEE", paddingTop: 6, paddingLeft: 12, paddingRight: 12 }}>
                        <Row gutter={[12, 0]}>
                            <Col span={6}>
                                <Form.Item name="address" label="ที่อยู่" >
                                    <TextArea autoSize={{ minRows: 2, maxRows: 8 }} />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name="district" label="อำเภอ/เขต" >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name="province" label="จังหวัด" >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name="zipCode" label="รหัสไปรษณีย์" >
                                    <InputNumber style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>

                    {/* ประเภทบุคคล */}
                    <div style={{ borderLeft: "2px solid #EEEEEE", borderRight: "2px solid #EEEEEE", borderTop: "2px solid #EEEEEE", paddingTop: 6, paddingLeft: 12, paddingRight: 12 }}>
                        <Row gutter={[12, 0]}>
                            <Col span={6}>
                                <Form.Item
                                    name="personType"
                                    label="ประเภทบุคคล"
                                    rules={[{ required: true, message: 'กรุณาเลือกประเภทบุคคล' }]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        allowClear
                                        onChange={(e) => {
                                            setPersonTypeSelected(e)
                                        }}
                                    >
                                        {personType?.map((item, index) => (
                                            <Option key={index} value={item.personTypeId}>{item.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            {personTypeSelected === 1 ?
                                <Col span={3}>
                                    <Form.Item
                                        name="gender"
                                        label="เพศ"
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                            onChange={(e) => {
                                                setPersonTypeSelected(e)
                                            }}
                                        >
                                            {gender?.map((item, index) => (
                                                <Option key={index} value={item.genderId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                : []
                            }

                            {personTypeSelected === 2 ?
                                <>
                                    <Col span={6}>
                                        <Form.Item name="companyName" label="ชื่อบริษัท" >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item name="isHeadOffice" label="สำนักงานใหญ่" >
                                            <Select
                                                showSearch
                                                style={{ width: '100%' }}
                                                optionFilterProp="children"
                                                allowClear
                                            >
                                                <Option key={0} value={true}>เป็นสำนักงานใหญ่</Option>
                                                <Option key={1} value={false}>ไม่ใช่สำนักงานใหญ่</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    {form.getFieldValue().isHeadOffice ?
                                        <Col span={6}>
                                            <Form.Item name="branch" label="สาขา" >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        : []
                                    }
                                </>
                                : []
                            }
                        </Row>
                    </div>

                    {/* การใช้งาน */}
                    <div style={{ border: "2px solid #EEEEEE", paddingTop: 6, paddingLeft: 12, paddingRight: 12 }}>
                        <Row gutter={[12, 0]}>
                            <Col span={6}>
                                <Form.Item
                                    name="isActive"
                                    label="สถานะการใช้งาน"
                                    rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        allowClear
                                    >
                                        <Option key={0} value={true}>เปิดการใช้งาน</Option>
                                        <Option key={1} value={false}>ปิดการใช้งาน</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Modal>
        </Row>
    )
}