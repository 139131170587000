/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Select,
    InputNumber,
    Upload,
    theme,
    Steps,
    Spin,
    Tooltip,
    Popover,
    Image
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import {
    getEmployeeAllFetch,
    getPriorityAllFetch,
    insertTransactionPaidEmployeeFetch
} from '../task/API'
import { getProjectAllFetch } from '../employeeCost/API'
import {
    deleteCaseByIdFetch,
    deleteCaseFileByIdFetch,
    deleteCaseImageByIdFetch,
    deleteCaseVideoByIdFetch,
    deleteReasonsInspectCaseFailureByIdFetch,
    deleteReasonsInspectCaseFailureFileByIdFetch,
    deleteReasonsInspectCaseFailureImageByIdFetch,
    deleteReasonsInspectCaseFailureVideoByIdFetch,
    getCaseProcessAllFetch,
    getCaseSearchFetch,
    // getCaseSubtypeAllFetch,
    getCaseTypeAllFetch,
    getReasonsInspectCaseFailureByIdFetch,
    insertCaseFetch,
    insertCaseFileFetch,
    insertCaseImageFetch,
    insertCaseVideoFetch,
    updateCaseByIdFetch,
    updateCaseProcessByIdFetch,
    updateReasonsInspectCaseFailureByIdFetch,
    updateReasonsInspectCaseFailureFileByIdFetch,
    updateReasonsInspectCaseFailureImageByIdFetch,
    updateReasonsInspectCaseFailureVideoByIdFetch,
    updateCaseOpenPaidEmployeeByIdFetch
} from './API'
import { permission } from '../../../../common/mamager/PermissionManager'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
    LoadingOutlined,
    UploadOutlined
} from '@ant-design/icons'
import { apiServerUrl } from '../../../../constants'
import Video from '../../../../common/components/video'
import { detectOpenCase } from './managers/detectOpenCase'
import parse from 'html-react-parser'
import JSZip from 'jszip';
import { saveAs } from 'file-saver'; // For saving the ZIP file
import { detactItemCreateLatest } from './managers/detactItemCreateLatest'
import "./css/index.css"
import { downloadFile } from '../../managers/downloadMamager'
import dayjs from 'dayjs'
import { getCustomerAllFetch, getProjectPhaseByIdFetch } from '../project/API'

const { Option } = Select
const { RangePicker } = DatePicker
const { Column } = Table
const formatDate = "DD/MM/YYYY"

export default function ManageCase() {
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()
    const [formReasonsInspectCaseFailure] = Form.useForm()

    const [employee, setEmployee] = useState(null)
    const [priority, setPriority] = useState(null)
    const [caseProcess, setCaseProcess] = useState(null)
    const [project, setProject] = useState(null)
    const [customer, setCustomer] = useState(null)
    const [caseType, setCaseType] = useState(null)
    const [caseSubtype, setCaseSubtype] = useState(null)
    const [projectPhase, setProjectPhase] = useState(null)

    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const [modalCaseInfo, setModalCaseInfo] = useState({
        isShow: false,
        title: null,
        data: null
    })

    const [modalReasonsInspectCaseFailure, setModalReasonsInspectCaseFailure] = useState({
        isShow: false,
        title: null,
        dataUpdateCaseProcess: null,
        caseId: null
    })

    const callbackUpdateCaseRef = useRef(false)

    const [caseSelected, setCaseSelected] = useState({
        employeeCostTotal: null,
        // estimateTimeTotal: null,
        // progressPercentageTotal: null,
        caseList: null
    })

    const auth = JSON.parse(sessionStorage.getItem('auth'))
    // console.log("auth : ", auth)

    const getCaseBySearch = async (search) => {
        setLoading(true)

        const result = await getCaseSearchFetch(search)
        // console.log("getCaseSearchFetch : ", result)

        let tempList = [];
        let sumEmployeeCost = 0
        // let sumEstimateTime = 0
        // let sumProgressPercentage = 0
        result?.map((val, index) => {
            sumEmployeeCost += val.employeeCost
            // sumEstimateTime += val.estimateTime
            // sumProgressPercentage += val.progressPercentage
            tempList.push({
                index: (index + 1),
                caseId: val.caseId,
                caseCode: val.caseCode,
                key: val.caseId,
                name: val?.name ?? "-",
                project: val?.project?.name ?? "-",
                owner: val?.employee?.nickname ?? "-",
                ownerId: val?.ownerId,
                customer: val?.customer?.uid ?? "-",
                caseProcess: val?.case_process?.name ?? "-",
                caseProcessId: val?.caseProcessId,
                priority: val?.priority?.name ?? "-",
                isOpenPaidEmployee: val.isOpenPaidEmployee,
                completionDate: val.completionDate ? val.completionDate : undefined,
                isActive: val?.isActive,
                createdAt: val?.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                createdAtOriginal: val?.createdAt ?? null,
                updatedAt: val?.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        {permission("case", "MCS-0001") ? // [MCS-0001] Edit cases
                            <>
                                <Button
                                    style={{
                                        width: 28,
                                        padding: 0,
                                        backgroundColor: "orange",
                                        border: "1px solid orange",
                                        color: 'white',
                                        // borderRadius: 50
                                    }}
                                    onClick={async () => {

                                        await getProjectPhaseById(val.projectId)

                                        form.setFieldsValue({
                                            caseId: val.caseId,
                                            customer: val.customerId,
                                            project: val.projectId,
                                            caseProcess: val.caseProcessId,
                                            owner: val.ownerId,
                                            priority: val.priorityId,
                                            caseType: val.caseTypeId,
                                            caseSubtype: val.caseSubtypeId,
                                            name: val.name,
                                            description: val.description,
                                            employeeCost: val.employeeCost,
                                            operationCost: val.operationCost,
                                            operationDiscount: val.operationDiscount,
                                            completionDate: val?.completionDate ? dayjs(val.completionDate) : undefined,
                                            isOpenPaidEmployee: val.isOpenPaidEmployee,
                                            projectPhase: val.projectPhaseId,
                                            isActive: val.isActive,
                                        })

                                        isHandleInsertRef.current = false
                                        callbackUpdateCaseRef.current = false
                                        caseIdRef.current = val.caseId
                                        setCurrent(0)
                                        setCasesImage(val.case_images)
                                        setCasesVideo(val.case_videos)
                                        setCasesFile(val.case_files)

                                        setModal({ isShow: true, title: "edit" })
                                    }}
                                    size="small"
                                >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Icon icon="typcn:edit" style={{ color: "white", width: 15, height: 15 }} />
                                    </div>
                                </Button>{"  "}
                            </>
                            : []
                        }

                        {permission("case", "MCS-0002") ? // [MCS-0002] Delete case
                            <Popconfirm
                                title="คุณยืนยันลบหรือไม่ ?"
                                okText={<span style={{ width: 50 }}>ใช่</span>}
                                onConfirm={async () => {
                                    await handleCaseDelete(val.caseId)

                                    // reload
                                    await reloadCaseBySearch()
                                }}
                                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                            >
                                <Button
                                    danger
                                    type="primary"
                                    style={{
                                        width: 28,
                                        padding: 0
                                    }}
                                    size="small"
                                >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 15, height: 15 }} />
                                    </div>
                                </Button>{"  "}
                            </Popconfirm>
                            : []
                        }

                        <Button
                            style={{
                                width: 28,
                                padding: 0,
                                backgroundColor: "#0066ff",
                                border: "1px solid #0066ff",
                                color: 'white',
                                // borderRadius: 50
                            }}
                            onClick={async () => {
                                setModalCaseInfo({
                                    isShow: true,
                                    title: "รายละเอียด Case " + val.name,
                                    data: val
                                })
                            }}
                            size="small"
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Icon icon="fluent:document-data-20-regular" style={{ color: "white", width: 15, height: 15 }} />
                            </div>
                        </Button>
                    </>
            });
        });
        setList(tempList)

        setCaseSelected({
            employeeCostTotal: sumEmployeeCost,
            // estimateTimeTotal: sumEstimateTime,
            // progressPercentageTotal: sumProgressPercentage / result?.length,
            caseList: result
        })

        setLoading(false)
    }

    const objFromRef = useRef({
        caseId: null,
        customer: null,
        project: null,
        caseProcess: null,
        owner: null,
        priority: null,
        caseType: null,
        caseSubtype: null,
        name: null,
        description: null,
        employeeCost: null,
        operationCost: null,
        operationDiscount: null,
        completionDate: null,
        projectPhaseId: null,
        isActive: null
    })

    const genBodyApi = (data, caseId) => {
        let obj = {
            caseId: caseId ?? null,
            customerId: data.customer ?? null,
            projectId: data.project ?? null,
            caseProcessId: data.caseProcess ?? null,
            ownerId: data.owner ?? null,
            priorityId: data.priority ?? null,
            caseTypeId: data.caseType ?? null,
            caseSubtypeId: data.caseSubtype ?? null,
            name: data.name ?? null,
            description: data.description ?? null,
            employeeCost: data.employeeCost ?? null,
            operationCost: data.operationCost ?? null,
            operationDiscount: data.operationDiscount ?? null,
            completionDate: data.completionDate ?? null,
            projectPhaseId: data.projectPhase ?? null,
            isActive: data.isActive ?? null,
        }
        return obj
    }

    const onFinish = async (values) => {
        if (modal.title === "add" && !callbackUpdateCaseRef.current) { // Step 1 [Add]
            let obj = genBodyApi(values, values.caseId)
            const result = await insertCaseFetch(obj)
            if (result?.isSuccess) {
                caseIdRef.current = result?.formData?.caseId
                isHandleInsertRef.current = true
            } else {
                Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modal.title === "edit" || callbackUpdateCaseRef.current) {
            let obj = genBodyApi(objFromRef.current, caseIdRef.current)
            if (modal.title === "add" && callbackUpdateCaseRef.current) { // Step 2 [Add] : Edit form add by previous
                const result = await updateCaseByIdFetch(obj)
                if (result.isSuccess) {
                    Notification('success', 'สร้างสำเร็จ')
                } else {
                    Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
                }

                // set default
                setFormDefault()
            } else { // edit original
                const result = await updateCaseByIdFetch(obj)
                if (result.isSuccess) {
                    Notification('success', 'สร้างสำเร็จ')
                } else {
                    Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
                }

                // set default
                setFormDefault()
            }
        }

        // reload
        await reloadCaseBySearch()
    }

    const onSearchFinish = async (values) => {
        let name = values?.name ?? null
        let projectId = values?.project ?? null
        let caseProcessId = values?.caseProcess ?? null
        let ownerId = values?.owner ?? null
        let projectPhaseId = values?.projectPhase ?? null
        let startedDate = null // values?.dateRange ? values.dateRange[0] : null
        let endDate = null // values?.dateRange ? values.dateRange[1] : null

        let objSearch = {
            name,
            projectId,
            caseProcessId,
            ownerId,
            priorityId: null,
            projectPhaseId,
            startedDate,
            endDate
        }
        // console.log("onSearchFinish : ", objSearch)

        caseSearchRef.current = {
            name,
            ownerId,
            projectId,
            caseProcessId,
            projectPhaseId
        }

        await getCaseBySearch(objSearch)
    }

    const onReasonsInspectCaseFailureFinish = async (values) => {
        const obj = {
            reasonsInspectCaseFailure: values?.reason ?? null,
            amountCaseFailure: values?.amountFailure ?? 0,
            caseId: modalReasonsInspectCaseFailure?.caseId ?? null
        }
        // console.log("obj --- : ", obj)

        const result = await updateReasonsInspectCaseFailureByIdFetch(obj)
        if (result.isSuccess) {
            Notification('success', 'บันทึกเหตุผลที่ไม่ผ่านสำเร็จ')
            await handleUpdateCaseProcessById(modalReasonsInspectCaseFailure.dataUpdateCaseProcess)

            setModalReasonsInspectCaseFailure({
                isShow: false,
                title: null,
                dataUpdateCaseProcess: null,
                caseId: null
            })
        } else {
            Notification('error', 'ไม่สามารถบันทึกเหตุผลที่ไม่ผ่านได้ กรุณาลองใหม่อีกครั้ง')
        }

    }

    const handleReasonsInspectCaseFailure = async (e, record) => {
        formReasonsInspectCaseFailure.resetFields()
        const obj = [
            {
                caseProcessId: e,
                caseId: record.caseId
            }
        ]

        const resultRICF = await getReasonsInspectCaseFailureByIdFetch({ caseId: record.caseId })
        // console.log("resultRICF : ", resultRICF)

        if (e === 7) {
            setReasonsInspectCaseFailureImageURL({
                loading: false,
                imageUrl: resultRICF.reasonsInspectCaseFailureImageUrl,
                caseId: record.caseId
            })

            setReasonsInspectCaseFailureVideoURL({
                loading: false,
                videoUrl: resultRICF.reasonsInspectCaseFailureVideoUrl,
                caseId: record.caseId
            })

            setReasonsInspectCaseFailureFileURL({
                loading: false,
                fileUrl: resultRICF.reasonsInspectCaseFailureFileUrl,
                caseId: record.caseId
            })

            formReasonsInspectCaseFailure.setFieldsValue({
                reason: resultRICF.reasonsInspectCaseFailure,
                amountFailure: resultRICF.amountCaseFailure ?? 0
            })

            setModalReasonsInspectCaseFailure({
                isShow: true,
                title: "เหตุผลที่ " + record.name + " ไม่ผ่าน",
                dataUpdateCaseProcess: obj,
                caseId: record.caseId
            })
        } else {
            await handleUpdateCaseProcessById(obj)

            if (permission("case", "MCS-0003")) { // [MCS-0003] Clear reasons infomation inspect case failure
                const resultText = await deleteReasonsInspectCaseFailureByIdFetch(record?.caseId ?? null)
                const resultImage = await deleteReasonsInspectCaseFailureImageByIdFetch(record?.caseId ?? null)
                const resultVideo = await deleteReasonsInspectCaseFailureVideoByIdFetch(record?.caseId ?? null)
                const resultFile = await deleteReasonsInspectCaseFailureFileByIdFetch(record?.caseId ?? null)

                if (
                    resultText.isSuccess &&
                    resultImage.isSuccess &&
                    resultVideo.isSuccess &&
                    resultFile.isSuccess
                ) {
                    Notification('success', 'เคียร์เหตุผลที่ไม่ผ่านสำเร็จ')
                } else {
                    Notification('error', 'ไม่สามารถเคียร์เหตุผลที่ไม่ผ่านได้ กรุณาลองใหม่อีกครั้ง')
                }
            }
        }
    }

    const handleCaseDelete = async (id) => {
        const result = await deleteCaseByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleCaseImageDelete = async (id) => {
        const result = await deleteCaseImageByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบรูปภาพสำเร็จ')

            let tmpCasesImage = casesImage.filter(fill => fill.caseImageId !== id)
            setCasesImage(tmpCasesImage)
        } else {
            Notification('error', 'ไม่สามารถลบรูปภาพได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleReasonsInspectCaseFailureImageDelete = async (id) => {
        const result = await deleteReasonsInspectCaseFailureImageByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบรูปภาพสำเร็จ')

            setReasonsInspectCaseFailureImageURL(prev => ({
                ...prev,
                loading: false,
                imageUrl: null
            }))
        } else {
            Notification('error', 'ไม่สามารถลบรูปภาพได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleCaseVideoDelete = async (id) => {
        const result = await deleteCaseVideoByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบวิดีโอสำเร็จ')

            let tmpCasesVideo = casesVideo.filter(fill => fill.caseVideoId !== id)
            setCasesVideo(tmpCasesVideo)
        } else {
            Notification('error', 'ไม่สามารถลบวิดีโอได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleReasonsInspectCaseFailureVideoDelete = async (id) => {
        const result = await deleteReasonsInspectCaseFailureVideoByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบวิดีโอสำเร็จ')

            setReasonsInspectCaseFailureVideoURL(prev => ({
                ...prev,
                loading: false,
                videoUrl: null
            }))
        } else {
            Notification('error', 'ไม่สามารถลบวิดีโอได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleCaseFileDelete = async (id) => {
        const result = await deleteCaseFileByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบวิดีโอสำเร็จ')

            let tmpCasesFile = casesFile.filter(fill => fill.caseFileId !== id)
            setCasesFile(tmpCasesFile)
        } else {
            Notification('error', 'ไม่สามารถลบวิดีโอได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleReasonsInspectCaseFailureFileDelete = async (id) => {
        const result = await deleteReasonsInspectCaseFailureFileByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบวิดีโอสำเร็จ')

            setReasonsInspectCaseFailureFileURL(prev => ({
                ...prev,
                loading: false,
                fileUrl: null,
                id: reasonsInspectCaseFailureImageURL.caseId
            }))
        } else {
            Notification('error', 'ไม่สามารถลบวิดีโอได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleUpdateCaseProcessById = async (obj) => {
        const result = await updateCaseProcessByIdFetch(obj)
        if (result?.isSuccess) {
            Notification('success', 'เปลี่ยนสถานะสำเร็จ!')

            // reload
            await reloadCaseBySearch()
        } else {
            Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setModal({
            isShow: false,
            title: null,
        })
    }

    const getCaseProcessAll = async () => {
        let obj = {
            isPermitAll: permission("case", "MCS-0004") ? true : false // [MCS-0004] Show all case process list
        }
        const result = await getCaseProcessAllFetch(obj)
        // console.log("getCaseProcessAllFetch : ", result)
        setCaseProcess(result)
    }

    const getCustomerAll = async () => {
        const result = await getCustomerAllFetch()
        // console.log("getCustomerAllFetch : ", result)
        setCustomer(result)
    }

    const getProjectAll = async () => {
        let isShowAll = true
        const result = await getProjectAllFetch(null, isShowAll)
        // console.log("getProjectAllFetch : ", result)
        setProject(result)
    }

    const getEmployeeAll = async () => {
        const result = await getEmployeeAllFetch()
        // console.log("getEmployeeAllFetch : ", result)
        setEmployee(result)
    }

    const getPriorityAll = async () => {
        const result = await getPriorityAllFetch()
        // console.log("getPriorityAllFetch : ", result)
        setPriority(result)
    }

    const getCaseTypeAll = async () => {
        const result = await getCaseTypeAllFetch()
        // console.log("getCaseTypeAllFetch : ", result)
        setCaseType(result)
    }

    // const getCaseSubtypeAll = async () => {
    //     const result = await getCaseSubtypeAllFetch()
    //     // console.log("getCaseSubtypeAllFetch : ", result)
    //     setCaseSubtype(result)
    // }

    const getProjectPhaseById = async (projectId) => {
        if (projectId) {
            const result = await getProjectPhaseByIdFetch({ projectId })
            // console.log("getProjectPhaseByIdFetch : ", result)
            setProjectPhase(result)
        }
    }

    const getBaseApi = async () => {
        await getCaseProcessAll()
        await getCustomerAll()
        await getProjectAll()
        await getEmployeeAll()
        await getPriorityAll()
        await getCaseTypeAll()
        // await getCaseSubtypeAll()
    }

    const reloadCaseBySearch = async () => {
        getCaseBySearch({
            name: caseSearchRef.current.name,
            projectId: caseSearchRef.current.projectId,
            caseProcessId: caseSearchRef.current.caseProcessId,
            ownerId: (permission("case", "MCS-0005") ? caseSearchRef.current.ownerId : auth.employeeId), // [MCS-0005] Assign cases to any employees
            projectPhaseId: caseSearchRef.current.projectPhaseId,
            priorityId: null,
            startedDate: null,
            endDate: null
        })
    }

    useEffect(() => {
        // reload
        reloadCaseBySearch()

        getBaseApi()
    }, [])

    const isHandleInsertRef = useRef(false)
    const caseIdRef = useRef(null)

    const [casesImage, setCasesImage] = useState([])
    const [casesVideo, setCasesVideo] = useState([])
    const [casesFile, setCasesFile] = useState([])

    const [isOpenEmployeeCostTotal, setIsOpenEmployeeCostTotal] = useState(false)

    const [caseImageURL, setCaseImageURL] = useState({
        loading: false,
        imageUrl: null
    })
    const [reasonsInspectCaseFailureImageURL, setReasonsInspectCaseFailureImageURL] = useState({
        loading: false,
        imageUrl: null,
        caseId: null
    })
    const [caseVideoURL, setCaseVideoURL] = useState({
        loading: false,
        imageUrl: null
    })
    const [reasonsInspectCaseFailureVideoURL, setReasonsInspectCaseFailureVideoURL] = useState({
        loading: false,
        videoUrl: null,
        caseId: null
    })
    const [caseFileURL, setCaseFileURL] = useState({
        loading: false,
        fileUrl: null
    })
    const [reasonsInspectCaseFailureFileURL, setReasonsInspectCaseFailureFileURL] = useState({
        loading: false,
        fileUrl: null,
        caseId: null
    })

    const [caseImageLoading, setCaseImageLoading] = useState(false)
    const [caseVideoLoading, setCaseVideoLoading] = useState(false)
    const [caseFileLoading, setCaseFileLoading] = useState(false)

    const optionCaseImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "spm-erp",
            folder: "case/image"
        },
        headers: {
            authorization: 'authorization-text',
        },
        async onChange(info) {

            let result = info.file.response
            if (info.file.status !== 'uploading') {
                if (result?.isSuccess) {
                    setCaseImageURL({
                        imageUrl: result.url,
                        loading: false
                    })
                }
            } else {
                setCaseImageURL({
                    imageUrl: caseImageURL.imageUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                setCaseImageLoading(true)

                let objServer = {
                    caseId: caseIdRef.current,
                    url: result.url,
                    name: null,
                    description: null,
                }
                const resultInsertCaseImage = await insertCaseImageFetch(objServer)
                if (resultInsertCaseImage?.isSuccess) {
                    Notification('success', 'Upload สำเร็จ!')
                    let objClient = {
                        caseId: caseIdRef.current,
                        caseImageId: resultInsertCaseImage.formData.caseImageId,
                        url: result.url,
                        name: null,
                        description: null,
                    }
                    let tmpCasesImage = casesImage
                    tmpCasesImage?.push(objClient)
                    setCasesImage(tmpCasesImage)
                } else {
                    Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
                }

                setCaseImageLoading(false)
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionReasonsInspectCaseFailureImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "spm-erp",
            folder: "reasons-inspect-case-failure/image"
        },
        headers: {
            authorization: 'authorization-text',
        },
        async onChange(info) {

            let result = info.file.response
            if (info.file.status !== 'uploading') {
                if (result?.isSuccess) {
                    const resultReasonsInspectCaseFailureImage = await updateReasonsInspectCaseFailureImageByIdFetch({
                        reasonsInspectCaseFailureImageUrl: result.url,
                        caseId: reasonsInspectCaseFailureImageURL.caseId,
                    })
                    if (resultReasonsInspectCaseFailureImage.isSuccess) {
                        setReasonsInspectCaseFailureImageURL(prev => ({
                            ...prev,
                            imageUrl: result.url,
                            loading: false
                        }))
                    }
                }
            } else {
                setReasonsInspectCaseFailureImageURL(prev => ({
                    ...prev,
                    loading: true
                }))
            }
        }
    }

    const optionCaseVideo = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "spm-erp",
            folder: "case/video"
        },
        headers: {
            authorization: 'authorization-text',
        },
        async onChange(info) {

            let result = info.file.response
            if (info.file.status !== 'uploading') {
                if (result?.isSuccess) {
                    setCaseVideoURL({
                        videoUrl: result.url,
                        loading: false
                    })
                }
            } else {
                setCaseVideoURL({
                    videoUrl: caseVideoURL.videoUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                setCaseVideoLoading(true)

                Notification("success", "เเจ้งเตือน!", "อัพโหลดวิดีโอสำเร็จ")
                let objServer = {
                    caseId: caseIdRef.current,
                    url: result.url,
                    name: null,
                    description: null,
                }
                const resultInsertCaseVideo = await insertCaseVideoFetch(objServer)
                if (resultInsertCaseVideo?.isSuccess) {
                    Notification('success', 'Upload สำเร็จ!')
                    let objClient = {
                        caseId: caseIdRef.current,
                        caseVideoId: resultInsertCaseVideo.formData.caseVideoId,
                        url: result.url,
                        name: null,
                        description: null,
                    }
                    let tmpCasesVideo = casesVideo
                    tmpCasesVideo?.push(objClient)
                    setCasesVideo(tmpCasesVideo)
                } else {
                    Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
                }

                setCaseVideoLoading(false)
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        }
    }

    const optionReasonsInspectCaseFailureVideo = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "spm-erp",
            folder: "reasons-inspect-case-failure/video"
        },
        headers: {
            authorization: 'authorization-text',
        },
        async onChange(info) {

            let result = info.file.response
            if (info.file.status !== 'uploading') {
                if (result?.isSuccess) {
                    const resultReasonsInspectCaseFailureVideo = await updateReasonsInspectCaseFailureVideoByIdFetch({
                        reasonsInspectCaseFailureVideoUrl: result.url,
                        caseId: reasonsInspectCaseFailureVideoURL.caseId,
                    })
                    if (resultReasonsInspectCaseFailureVideo.isSuccess) {
                        setReasonsInspectCaseFailureVideoURL(prev => ({
                            ...prev,
                            videoUrl: result.url,
                            loading: false
                        }))
                    }
                }
            } else {
                setReasonsInspectCaseFailureVideoURL(prev => ({
                    ...prev,
                    loading: true
                }))
            }
        }
    }

    const optionCaseFile = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "spm-erp",
            folder: "case/file"
        },
        headers: {
            authorization: 'authorization-text',
        },
        async onChange(info) {

            let result = info.file.response
            if (info.file.status !== 'uploading') {
                if (result?.isSuccess) {
                    setCaseFileURL({
                        fileUrl: result.url,
                        loading: false
                    })
                }
            } else {
                setCaseFileURL({
                    fileUrl: caseFileURL.fileUrl,
                    loading: true
                })
            }

            if (info.file.status === 'done') {
                setCaseFileLoading(true)

                Notification("success", "เเจ้งเตือน!", "อัพโหลดวิดีโอสำเร็จ")
                let objServer = {
                    caseId: caseIdRef.current,
                    url: result.url,
                    name: result.name,
                    description: null
                }
                const resultInsertCaseFile = await insertCaseFileFetch(objServer)
                if (resultInsertCaseFile?.isSuccess) {
                    Notification('success', 'Upload สำเร็จ!')
                    let objClient = {
                        caseId: caseIdRef.current,
                        caseFileId: resultInsertCaseFile.formData.caseFileId,
                        url: result.url,
                        name: result.name,
                        description: null
                    }
                    let tmpCasesFile = casesFile
                    tmpCasesFile?.push(objClient)
                    setCasesFile(tmpCasesFile)
                } else {
                    Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
                }

                setCaseFileLoading(false)
            } else if (info.file.status === 'error') {
                Notification("error", "เเจ้งเตือน!", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#FF7F00',
                '100%': '#FF7F00'
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`
        }
    }

    const optionReasonsInspectCaseFailureFile = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "spm-erp",
            folder: "reasons-inspect-case-failure/file"
        },
        headers: {
            authorization: 'authorization-text',
        },
        async onChange(info) {

            let result = info.file.response
            if (info.file.status !== 'uploading') {
                if (result?.isSuccess) {
                    const resultReasonsInspectCaseFailureFile = await updateReasonsInspectCaseFailureFileByIdFetch({
                        reasonsInspectCaseFailureFileUrl: result.url,
                        caseId: reasonsInspectCaseFailureFileURL.caseId,
                    })
                    if (resultReasonsInspectCaseFailureFile.isSuccess) {
                        setReasonsInspectCaseFailureFileURL(prev => ({
                            ...prev,
                            fileUrl: result.url,
                            loading: false
                        }))
                    }
                }
            } else {
                setReasonsInspectCaseFailureFileURL(prev => ({
                    ...prev,
                    loading: true
                }))
            }
        }
    }

    // -------------------------------------------

    const steps = [
        {
            title: 'รายละเอียด Case',
            content: 'step-case-info',
        },
        {
            title: 'อัพโหลดไฟล์ (รูปภาพ, วิดีโอ)',
            content: 'step-case-file',
        }
    ]

    const { token } = theme.useToken()
    const [current, setCurrent] = useState(0)
    const next = async () => {
        if (modal.title === "add" && isHandleInsertRef.current === false) {
            form.submit()
        } else if (modal.title === "edit") {
            objFromRef.current = form.getFieldsValue()
        }
        setCurrent(current + 1)
    }
    const prev = () => {
        setCurrent(current - 1)
    }
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title
    }))
    const contentStyle = {
        // color: token.colorTextTertiary,
        // backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        marginTop: 16,
        padding: 16
    }

    // -------------------------------------------

    const [selectedRowKeysTask, setSelectedRowKeysTask] = useState([])
    const [resetTableCase, setResetTableCase] = useState(false)
    const caseSearchRef = useRef({
        name: null,
        ownerId: null,
        projectId: null,
        caseProcessId: null,
        projectPhaseId: null
    })

    const onSelectChange = (newSelectedRowKeys) => {
        // console.log('selectedRowKeys changed: ', newSelectedRowKeys)
        setSelectedRowKeysTask(newSelectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeysTask,
        getCheckboxProps: (record) => ({
            disabled: record.isOpenPaidEmployee === true || record.caseProcessId !== 5,
            name: record.name,
        }),
        onChange: onSelectChange
    }

    const handlePaymentSchedule = async () => {
        if (caseSearchRef.current.ownerId && caseSearchRef.current.projectId && caseSearchRef.current.projectPhaseId) {
            let employeeCostSum = 0
            let caseList = []
            caseSelected.caseList.map((item) => {
                if (selectedRowKeysTask.includes(item.caseId)) {
                    employeeCostSum += item.employeeCost
                    caseList.push({
                        // for insert-transaction-paid-employee
                        caseId: item.caseId,
                        taskId: null,
                        ownerCreateId: auth.employeeId,
                        employeeId: caseSearchRef.current.ownerId,
                        employeeCost: item.employeeCost,
                        projectPhaseId: item.projectPhaseId,

                        // for update-case-open-paid-employee-by-id
                        isOpenPaidEmployee: true
                    })
                }
            })

            const master = {
                projectId: caseSearchRef.current.projectId,
                ownerCreateId: auth.employeeId,
                transactionPaidEmployeeTypeId: 2,
                employeeId: caseSearchRef.current.ownerId,
                projectPhaseId: caseSearchRef.current.projectPhaseId,
                employeeCostSum
            }

            const resultInsertTransactionPaidEmployee = await insertTransactionPaidEmployeeFetch(master, caseList)
            const resultUpdateCaseOpenPaidEmployee = await updateCaseOpenPaidEmployeeByIdFetch(caseList)

            if (resultInsertTransactionPaidEmployee?.isSuccess && resultUpdateCaseOpenPaidEmployee?.isSuccess) {
                Notification('success', 'สร้าง PaymentSchedule สำเร็จ')

                setResetTableCase(true);

                setTimeout(async () => {
                    setSelectedRowKeysTask([])
                    setResetTableCase(false)

                    // reload
                    await reloadCaseBySearch()
                }, 1000)
            } else {
                Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
            }
        } else {
            Notification('error', 'กรุณาเลือกผู้รับมอบหมาย, โปรเจกต์, เเละเฟส ที่ช่องค้นหา/เลือก เเล้วกดปุ่มค้นหา/เลือก เพื่อใช้ระบุเจ้าของ Payment Schedule ที่จะสร้าง')
        }
    }

    // -------------------------------------------

    const downloadAllImages = async (images) => {
        const zip = new JSZip()

        // Fetch each image and add it to the ZIP file
        const imagePromises = images.map(async (url, index) => {
            const response = await fetch(url)
            const blob = await response.blob()
            zip.file(`image${index + 1}.jpg`, blob)
        })

        // Wait for all the images to be added to the ZIP
        await Promise.all(imagePromises)

        // Generate the ZIP and trigger the download
        const zipBlob = await zip.generateAsync({ type: 'blob' })
        saveAs(zipBlob, 'images-case.zip')
    }

    const caseProcessColor = (id) => {
        switch (id) {
            case 1:
                return "#ed9600"
            case 2:
                return "#00aaff"
            case 3:
                return "#ed9600"
            case 4:
                return "#a100e8"
            case 5:
                return "#429321"
            case 6:
                return "#c0c0c0"
            case 7:
                return "#ff0000"
            default:
                break
        }
    }

    const [heightScreen, setHeightScreen] = useState(window.innerHeight * 0.45)

    useEffect(() => {
        // ฟังก์ชันเพื่ออัปเดตความสูงเมื่อมีการเปลี่ยนแปลงขนาดหน้าจอ
        const handleResize = () => {
            setHeightScreen(window.innerHeight * 0.45)
        }

        // เพิ่ม event listener เมื่อหน้าจอถูก resize
        window.addEventListener('resize', handleResize)

        // ลบ event listener เมื่อคอมโพเนนต์นี้ถูกทำลาย
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <Row>
            <Col span={2} style={{ borderBottom: "1px solid black" }}>
                <label>จัดการ Case</label>
            </Col>

            <Col span={22} style={{ borderBottom: "1px solid black" }}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                name="name"
                                style={{ width: 150 }}
                            >
                                <Input placeholder="ชื่อหัวข้อ Case" />
                            </Form.Item>
                        </div>

                        {permission("case", "MCS-0006") ? // [MCS-0006] Show dropdown to select case owner
                            <div style={{ paddingLeft: 10 }}>
                                <Form.Item name="owner">
                                    <Select
                                        showSearch
                                        style={{ width: 180, border: "2px solid #429321", borderRadius: 8 }}
                                        optionFilterProp="children"
                                        allowClear
                                        placeholder="มอบหมาย"
                                    >
                                        {employee?.map((item, index) => (
                                            <Option key={index} value={item.employeeId}>{item.firstname} ({item.nickname})</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            : []
                        }

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item name="project">
                                <Select
                                    showSearch
                                    style={{
                                        width: 180,
                                        border: permission("case", "MCS-0007") ? "2px solid #429321" : "", // [MCS-0007] Hilight search project
                                        borderRadius: permission("case", "MCS-0007") ? 8 : 0 // [MCS-0007] Hilight search project
                                    }}
                                    optionFilterProp="children"
                                    allowClear
                                    placeholder="โปรเจกต์"
                                    onChange={async (e) => {
                                        caseSearchRef.current.projectId = e
                                        caseSearchRef.current.projectPhaseId = undefined
                                        await getProjectPhaseById(e)
                                    }}
                                >
                                    {project?.map((item, index) => (
                                        <Option key={index} value={item.projectId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item name="projectPhase">
                                <Select
                                    showSearch
                                    style={{
                                        width: 180,
                                        border: permission("case", "MCS-0007") ? "2px solid #429321" : "", // [MCS-0007] Hilight search project
                                        borderRadius: permission("case", "MCS-0007") ? 8 : 0 // [MCS-0007] Hilight search project
                                    }}
                                    optionFilterProp="children"
                                    placeholder="Project phase/round"
                                    allowClear
                                    disabled={!caseSearchRef.current.projectId}
                                >
                                    {projectPhase?.map((item, index) => (
                                        <Option key={index} value={item.projectPhaseId}>{item.name} (Phase/Round {item.phase})</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item name="caseProcess">
                                <Select
                                    showSearch
                                    style={{ width: 180 }}
                                    optionFilterProp="children"
                                    allowClear
                                    placeholder="การดำเนิน"
                                >
                                    {caseProcess?.map((item, index) => (
                                        <Option key={index} value={item.caseProcessId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Button
                                style={{ float: 'right' }}
                                type="primary"
                                onClick={() => formSearch.submit()}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Icon icon="tdesign:search" />
                                    <div style={{ paddingLeft: 4, paddingRight: 4 }}>/</div>
                                    <Icon icon="grommet-icons:select" />
                                </div>
                            </Button>
                        </div>

                        {permission("case", "MCS-0008") ? // [MCS-0008] Add case
                            <div style={{ paddingLeft: 10 }}>
                                <Button
                                    type="primary"
                                    style={{ float: 'right' }}
                                    onClick={() => {
                                        form.resetFields()
                                        isHandleInsertRef.current = false
                                        callbackUpdateCaseRef.current = false
                                        setCurrent(0)
                                        setCasesImage([])
                                        setCasesVideo([])
                                        setCasesFile([])

                                        setModal({ isShow: true, title: "add" })
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Icon icon="mingcute:add-fill" />
                                    </div>
                                </Button>
                            </div>
                            : []
                        }
                    </div>
                </Form>
            </Col>

            <Col span={24}>
                {!resetTableCase ?
                    <Table
                        loading={loading}
                        dataSource={list}
                        pagination={{
                            current: pageCurrent,
                            pageSize: 9,
                            total: list.length
                        }}
                        scroll={{ y: heightScreen }}
                        onChange={(n) => onPagine(n)}
                        rowSelection={permission("case", "MCS-0009") ? rowSelection : null} // [MCS-0009] Dispay row case selection
                        rowClassName={(record, index) => {
                            return record.ownerId ? '' : 'highlight-row-customer-open-case-non-owner';
                        }}
                    >
                        <Column title="Code" dataIndex="caseCode" key="caseCode" width={"7%"} align={'center'} />
                        <Column
                            title="หัวข้อ Case"
                            dataIndex="name"
                            key="name"
                            width={"35%"}
                            render={(tags, record) => (
                                <div style={{ display: "flex" }}>
                                    <>
                                        <span>{detactItemCreateLatest(record.createdAtOriginal, 1)}</span>
                                    </>

                                    {record.isOpenPaidEmployee ?
                                        <Tooltip placement="right" title={"ถูกใส่ใน Payment Schedule เเล้ว!"}>
                                            <div style={{ marginTop: 3, paddingRight: 6 }}>
                                                <Icon icon="material-symbols:paid" style={{ color: "#ff9900" }} />
                                            </div>
                                        </Tooltip>
                                        : []
                                    }

                                    <span>{tags}</span>
                                </div>
                            )}
                        />
                        <Column title="โปรเจกต์" dataIndex="project" key="project" width={"13%"} />
                        <Column title="มอบหมาย" dataIndex="owner" key="owner" width={"7%"} />
                        <Column
                            title="ดำเนินการ"
                            dataIndex="caseProcess"
                            key="caseProcess"
                            width={"14%"}
                            render={(tags, record) => (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {record.caseProcessId === 7 ?
                                        <div style={{ paddingRight: 6, display: "flex", alignItems: "center" }}>
                                            <Icon
                                                icon="fa:search"
                                                style={{ width: 18, height: 18, color: "red", cursor: "pointer" }}
                                                onClick={async () => {
                                                    await handleReasonsInspectCaseFailure(record.caseProcessId, record)
                                                }}
                                            />
                                        </div>
                                        : []
                                    }

                                    {(permission("case", "MCS-0004") ? true : ([2, 3, 4].includes(record.caseProcessId))) ? // (รับเรื่องเเล้ว, กำลังดำเนินการ, ดำเนินการเเล้ว รอทดสอบ) - // [MCS-0004] Show all case process list
                                        <Popover
                                            placement="bottom"
                                            title="ดำเนินการ"
                                            content={
                                                <Select
                                                    showSearch
                                                    defaultValue={record.caseProcessId}
                                                    style={{ width: 250 }}
                                                    optionFilterProp="children"
                                                    allowClear
                                                    onChange={async (e) => {
                                                        await handleReasonsInspectCaseFailure(e, record)
                                                    }}
                                                    disabled={record.isOpenPaidEmployee}
                                                >
                                                    {caseProcess?.map((item, index) => (
                                                        <Option key={index} value={item.caseProcessId}>{item.name}</Option>
                                                    ))}
                                                </Select>
                                            }
                                        >
                                            <span style={{ color: caseProcessColor(record.caseProcessId) }}>{tags}</span>
                                        </Popover>
                                        :
                                        <div style={{ display: "flex" }}>
                                            <span style={{ color: caseProcessColor(record.caseProcessId) }}>{tags}</span>
                                        </div>
                                    }
                                </div>
                            )}
                        />
                        <Column title="ความสำคัญ" dataIndex="priority" key="priority" width={"7%"} />
                        {/* <Column title="วันที่สร้าง" dataIndex="createdAt" key="createdAt" width={"8%"} align={'center'} /> */}
                        <Column
                            title="วันที่เเสร็จ"
                            dataIndex="completionDate"
                            key="completionDate"
                            width={"7%"}
                            align={'center'}
                            render={(tags, record) => (
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    {tags ?
                                        <>
                                            {record.caseProcessId !== 5 && (tags ? new Date(`${tags.split('/')[2]}-${tags.split('/')[1]}-${tags.split('/')[0]}`).toLocaleString("en-US", { timeZone: "Asia/Bangkok" }) < new Date().toLocaleString("en-US", { timeZone: "Asia/Bangkok" }) : true) ?
                                                <Tooltip placement="right" title={"เกินกำหนดเเล้ว!"}>
                                                    <span style={{ color: "red" }}>{moment(tags).format("DD MMM")}</span>
                                                </Tooltip>
                                                :
                                                <span style={{}}>{moment(tags).format("DD MMM")}</span>
                                            }
                                        </>
                                        :
                                        "ไม่ได้กำหนด"
                                    }
                                </div>
                            )}
                        />
                        <Column title={<FaCog />} dataIndex="operator" key="operator" width={"10%"} align={'center'} />
                    </Table>
                    :
                    <div style={{ height: 250, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Spin size="large" />
                    </div>
                }
            </Col>

            <Col span={24}>
                <Row gutter={[6, 6]} style={{ paddingTop: 12 }}>
                    <Col span={12}>
                        <div style={{ display: "grid" }}>
                            <div style={{ display: "flex", paddingBottom: 6 }}>
                                <div style={{ width: 350 }}>
                                    <label>{permission("case", "MCS-0010") ? "ค่าใช้จ่ายพนักงานรวม (ตามข้อมูลที่เเสดงในตาราง)" : ("รายได้รวมของคุณ (ตามข้อมูลที่เเสดงในตาราง)" + auth.firstname)}</label> {/* // [MCS-0010] Show sum employee expenses mode manage accounts */}
                                </div>

                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {isOpenEmployeeCostTotal ?
                                        <label style={{ paddingRight: 8, fontWeight: 600 }}>
                                            {Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(caseSelected.employeeCostTotal))} บาท
                                        </label>
                                        : []
                                    }
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setIsOpenEmployeeCostTotal(!isOpenEmployeeCostTotal)
                                        }}
                                        size="small"
                                        style={{ display: "flex", alignItems: "center" }}
                                    >
                                        {isOpenEmployeeCostTotal ?
                                            <Icon icon="mdi:hide" style={{ color: "white", width: 18, height: 18 }} />
                                            :
                                            <Icon icon="akar-icons:eye-open" style={{ color: "white", width: 18, height: 18 }} />
                                        }
                                    </Button>
                                </div>
                            </div>

                            {/* <div style={{ display: "flex" }}>
                                        <div style={{ width: 350 }}>
                                            <label>ประมาณการเวลารวม (ตามข้อมูลที่เเสดงในตาราง)</label>
                                        </div>
                                        <div style={{ width: 100 }}>
                                            <label>{taskSelected.estimateTimeTotal} hr</label>
                                        </div>
                                    </div> */}

                            {/* <div style={{ display: "flex" }}>
                                        <div style={{ width: 350 }}>
                                            <label>ความคืบหน้ารวม (ตามข้อมูลที่เเสดงในตาราง)</label>
                                        </div>
                                        <div style={{ width: 100 }}>
                                            {taskSelected.progressPercentageTotal ?
                                                <label>{Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(taskSelected.progressPercentageTotal))} %</label>
                                                : "-"
                                            }
                                        </div>
                                    </div> */}
                        </div>
                    </Col>

                    <Col span={12}>
                        {permission("case", "MCS-0011") ? // [MCS-0011] Create payment schedule
                            <div style={{ display: "flex", backgroundColor: "#EEEEEE", height: "100%", borderRadius: 6 }}>
                                <div style={{ paddingTop: 12, paddingBottom: 12, paddingLeft: 12 }}>
                                    <Button
                                        type="primary"
                                        disabled={selectedRowKeysTask.length === 0}
                                        onClick={handlePaymentSchedule}
                                    >
                                        สร้าง Payment Schedule
                                    </Button>
                                </div>
                            </div>
                            : []
                        }
                    </Col>
                </Row>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่ม Case" : "เเก้ไข Case"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault()
                }}
                style={{ top: 8 }}
                width={"100%"}
                footer={[
                    current > 0 && (
                        <Button
                            style={{
                                margin: '0 8px',
                            }}
                            onClick={() => prev()}
                        >
                            ย้อนกลับ
                        </Button>
                    ),
                    current < steps.length - 1 && (
                        <Button type="primary" onClick={() => next()}>
                            ถัดไป
                        </Button>
                    ),
                    current === steps.length - 1 && (
                        <Button
                            type="primary"
                            onClick={() => {
                                if (caseIdRef.current) {
                                    callbackUpdateCaseRef.current = true
                                    form.submit()
                                }
                            }}
                        >
                            ยืนยัน
                        </Button>
                    )
                ]}
            >
                <Steps current={current} items={items} />
                <div style={contentStyle}>
                    {steps[current].content === "step-case-info" ?
                        <Form
                            layout="vertical"
                            form={form}
                            onFinish={onFinish}
                        >
                            <Row gutter={[24, 0]}>
                                <Col span={24}>
                                    <Form.Item name="caseId" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="name"
                                        label="หัวข้อ Case"
                                        rules={[{ required: true, message: 'กรุณากรอกหัวข้อ Case' }]}
                                    >
                                        <Input onChange={(e) => objFromRef.current.name = e.target.value} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="description"
                                        label="คำอธิบาย อย่างละเอียด"
                                        rules={[{ required: true, message: 'กรุณากรอกคำอธิบาย อย่างละเอียด' }]}
                                    >
                                        <Input style={{ display: "none" }} />
                                        <CKEditor
                                            editor={ClassicEditor}
                                            config={{
                                                placeholder: 'พิมพ์คำอธิบายที่นี่...',
                                                toolbar: {
                                                    items: [
                                                        'heading', '|',
                                                        'fontfamily', 'fontsize', '|',
                                                        'bold', 'italic', 'underline', '|',
                                                        'alignment', '|',
                                                        'fontColor', 'fontBackgroundColor', '|',
                                                        'bulletedList', 'numberedList', 'todoList', '|',
                                                        'code', 'codeBlock', '|',
                                                        'undo', 'redo'
                                                    ],
                                                    removeButtons: 'Subscript,Superscript'
                                                }
                                            }}
                                            data={form.getFieldValue().description ?? ""}
                                            onBlur={(event, editor) => {
                                                const data = editor.getData()
                                                form.setFieldValue('description', data)
                                                objFromRef.current.description = data
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="owner"
                                        label="ผู้รับมอบหมาย"
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                            onChange={(e) => objFromRef.current.owner = e}
                                            disabled={form.getFieldValue()?.isOpenPaidEmployee}
                                        >
                                            {employee?.map((item, index) => (
                                                <Option key={index} value={item.employeeId}>{item.firstname} ({item.nickname})</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="priority"
                                        label="ความสำคัญ"
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                            onChange={(e) => objFromRef.current.priority = e}
                                            disabled={form.getFieldValue()?.isOpenPaidEmployee}
                                        >
                                            {priority?.map((item, index) => (
                                                <Option key={index} value={item.priorityId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="caseProcess"
                                        label="การดำเนิน Case"
                                        rules={[{ required: true, message: 'กรุณาเลือกการดำเนิน Case' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%', }}
                                            optionFilterProp="children"
                                            allowClear
                                            onChange={(e) => {
                                                objFromRef.current.caseProcess = e
                                            }}
                                            disabled={form.getFieldValue()?.isOpenPaidEmployee}
                                        >
                                            {caseProcess?.map((item, index) => (
                                                <Option key={index} value={item.caseProcessId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="caseType"
                                        label="ประเภท Case"
                                        rules={[{ required: true, message: 'กรุณาเลือก ประเภท Case' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%', }}
                                            optionFilterProp="children"
                                            allowClear
                                            onChange={(e) => objFromRef.current.caseType = e}
                                            disabled={form.getFieldValue()?.isOpenPaidEmployee}
                                        >
                                            {caseType?.map((item, index) => (
                                                <Option key={index} value={item.caseTypeId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {/* <Col span={6}>
                                    <Form.Item
                                        name="caseSubtype"
                                        label="ประเภท Case ย่อย"
                                        rules={[{ required: true, message: 'กรุณาเลือก ประเภท Case ย่อย' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%', }}
                                            optionFilterProp="children"
                                            allowClear
                                            onChange={(e) => objFromRef.current.caseProcess = e}
                                            disabled={form.getFieldValue()?.isOpenPaidEmployee}
                                        >
                                            {caseSubtype?.map((item, index) => (
                                                <Option key={index} value={item.caseSubtypeId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col> */}

                                <Col span={6}>
                                    <Form.Item
                                        name="project"
                                        label="โปรเจกต์"
                                        rules={[{ required: true, message: 'กรุณาเลือก ' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                            disabled={form.getFieldValue()?.isOpenPaidEmployee}
                                            onChange={async (e) => {
                                                objFromRef.current.project = e

                                                await getProjectPhaseById(e)
                                                form.setFieldValue("projectPhase", undefined, true)

                                                if (modal.title === "add") {
                                                    let result = await detectOpenCase(
                                                        form.getFieldsValue()?.customer,
                                                        e
                                                    )
                                                    // console.log("project - result : ", result)
                                                }
                                            }}
                                        >
                                            {project?.map((item, index) => (
                                                <Option key={index} value={item.projectId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="projectPhase"
                                        label="Project phase/round"
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            placeholder=""
                                            allowClear
                                            disabled={form.getFieldValue()?.isOpenPaidEmployee}
                                            onChange={async (e) => {
                                                objFromRef.current.projectPhase = e
                                            }}
                                        >
                                            {projectPhase?.map((item, index) => (
                                                <Option key={index} value={item.projectPhaseId}>{item.name} (Phase {item.phase})</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="customer"
                                        label="ลูกค้า"
                                        rules={[{ required: true, message: 'กรุณาเลือกลูกค้า' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                            disabled={form.getFieldValue()?.isOpenPaidEmployee}
                                            onChange={async (e) => {
                                                objFromRef.current.customer = e

                                                if (modal.title === "add") {
                                                    let result = await detectOpenCase(
                                                        e,
                                                        form.getFieldsValue()?.project
                                                    )
                                                    // console.log("customer - result : ", result)
                                                }
                                            }}
                                        >
                                            {customer?.map((item, index) => (
                                                <Option key={index} value={item.customerId}>{item.nickname}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="employeeCost"
                                        label="ค่าใช้จ่ายพนักงาน"
                                    >
                                        <InputNumber
                                            decimalSeparator={"."}
                                            step={"0.01"}
                                            style={{ width: "100%" }}
                                            onChange={(e) => objFromRef.current.employeeCost = e}
                                            disabled={form.getFieldValue()?.isOpenPaidEmployee}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="operationCost"
                                        label="ค่าใช้จ่ายดำเนินงาน"
                                        onBlur={(e) => {
                                            // console.log("e --- : ", e.target.value)
                                            form.setFieldValue("employeeCost", (e.target.value ? parseFloat(e.target.value) * 0.3 : 0), true)
                                        }}
                                    >
                                        <InputNumber
                                            decimalSeparator={"."}
                                            step={"0.01"}
                                            style={{ width: "100%" }}
                                            onChange={(e) => objFromRef.current.operationCost = e}
                                            disabled={form.getFieldValue()?.isOpenPaidEmployee}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="operationDiscount"
                                        label="ส่วนลดดำเนินงาน"
                                    >
                                        <InputNumber
                                            decimalSeparator={"."}
                                            step={"0.01"}
                                            style={{ width: "100%" }}
                                            onChange={(e) => objFromRef.current.operationDiscount = e}
                                            disabled={form.getFieldValue()?.isOpenPaidEmployee}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="completionDate"
                                        label="วันที่เเล้วเสร็จ"
                                    >
                                        <DatePicker
                                            // showTime
                                            style={{ width: "100%" }}
                                            onChange={(e) => objFromRef.current.completionDate = e}
                                            disabled={form.getFieldValue()?.isOpenPaidEmployee}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="isActive"
                                        label="สถานะการใช้งาน"
                                        rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                            onChange={(e) => objFromRef.current.isActive = e}
                                        >
                                            <Option key={0} value={true}>เปิดการใช้งาน</Option>
                                            <Option key={1} value={false}>ปิดการใช้งาน</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        : []
                    }

                    {steps[current].content === "step-case-file" ?
                        <Row gutter={[24, 0]}>
                            <Col span={24}>
                                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                        <label style={{ paddingLeft: 8 }}>รูปภาพเเนบ (.jpg, .jpeg, .png, .jfif, .webp) ใช้ประกอบการอธิบาย Case</label>
                                    </div>

                                    <Upload
                                        {...optionCaseImage}
                                        accept='image/jpeg, image/png, image/jfif, image/webp'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={false}
                                    >
                                        <Button
                                            type="primary"
                                            style={{ width: "100%" }}
                                            icon={caseImageURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                        >อัพโหลดรูปภาพ</Button>
                                    </Upload>
                                </div>
                                <div style={{ paddingTop: 12, paddingBottom: 24 }}>
                                    <div style={{ padding: 12, border: "2px solid #EEEEEE", borderRadius: 6 }}>
                                        <Row gutter={[12, 24]}>
                                            {!caseImageLoading ?
                                                casesImage?.map((val, index) => {
                                                    return (
                                                        <Col key={index} xs={24} md={12} xl={6}>
                                                            <div style={{ display: "flex", border: "2px solid #898989" }}>
                                                                <Image
                                                                    src={`${val.url}`}
                                                                    style={{
                                                                        objectFit: "cover",
                                                                        width: "100%",
                                                                        height: 200
                                                                    }}
                                                                />
                                                                <div
                                                                    style={{
                                                                        zIndex: 2,
                                                                        position: "absolute",
                                                                        left: "85.5%",
                                                                        top: "3%",
                                                                        cursor: "pointer",
                                                                        backgroundColor: "white",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        padding: 6,
                                                                        borderRadius: 20,
                                                                        boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)"
                                                                    }}
                                                                    onClick={async () => {
                                                                        await handleCaseImageDelete(val.caseImageId)
                                                                    }}
                                                                >
                                                                    <Icon icon="fluent:delete-24-regular" style={{ color: "red", width: 20, height: 20 }} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                                : []
                                            }

                                            {casesImage?.length === 0 ?
                                                <Col span={24}>
                                                    <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                                        <label>ไม่มีรูปภาพ</label>
                                                    </div>
                                                </Col>
                                                : []
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </Col>

                            <Col span={24}>
                                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                        <label style={{ paddingLeft: 8 }}>วิดีโอเเนบ (.mp4) ใช้ประกอบการอธิบาย Case</label>
                                    </div>

                                    <Upload
                                        {...optionCaseVideo}
                                        accept='.mp4'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={false}
                                    >
                                        <Button
                                            type="primary"
                                            style={{ width: "100%" }}
                                            icon={caseVideoURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                        >อัพโหลดวิดีโอ</Button>
                                    </Upload>
                                </div>
                                <div style={{ paddingTop: 12, paddingBottom: 24 }}>
                                    <div style={{ padding: 12, border: "2px solid #EEEEEE", borderRadius: 6 }}>
                                        <Row gutter={[12, 24]}>
                                            {!caseVideoLoading ?
                                                casesVideo?.map((val) => {
                                                    return (
                                                        <Col xs={24} md={12} xl={12}>
                                                            <div style={{ display: "flex" }}>
                                                                <Video url={val.url} title={""} height={""} />
                                                                <div
                                                                    style={{
                                                                        zIndex: 2,
                                                                        position: "absolute",
                                                                        left: "93%",
                                                                        top: "2%",
                                                                        cursor: "pointer",
                                                                        backgroundColor: "white",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        padding: 6,
                                                                        borderRadius: 20,
                                                                        boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)"
                                                                    }}
                                                                    onClick={async () => {
                                                                        await handleCaseVideoDelete(val.caseVideoId)
                                                                    }}
                                                                >
                                                                    <Icon icon="fluent:delete-24-regular" style={{ color: "red", width: 20, height: 20 }} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                                : []
                                            }

                                            {casesVideo?.length === 0 ?
                                                <Col span={24}>
                                                    <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                                        <label>ไม่มีวิดีโอ</label>
                                                    </div>
                                                </Col>
                                                : []
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </Col>

                            <Col span={24}>
                                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                        <label style={{ paddingLeft: 8 }}>ไฟล์เเนบ (.pdf, .docx, .xlsx, .csv) ใช้ประกอบการอธิบาย Case</label>
                                    </div>

                                    <Upload
                                        {...optionCaseFile}
                                        accept='.pdf, .docx, .xlsx, .csv'
                                        style={{ width: "100%" }}
                                        maxCount={1}
                                        showUploadList={false}
                                    >
                                        <Button
                                            type="primary"
                                            style={{ width: "100%" }}
                                            icon={caseFileURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                        >อัพโหลดไฟล์</Button>
                                    </Upload>
                                </div>
                                <div style={{ paddingTop: 12, paddingBottom: 24 }}>
                                    <div style={{ padding: 12, border: "2px solid #EEEEEE", borderRadius: 6 }}>
                                        <Row gutter={[12, 24]}>
                                            {!caseFileLoading ?
                                                casesFile?.map((val) => {
                                                    return (
                                                        <Col xs={24} md={12} xl={8}>
                                                            <div style={{ display: "flex", border: "2px solid #EEEEEE", borderRadius: 6, padding: 12 }}>
                                                                <div
                                                                    style={{ display: "flex", cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        downloadFile(val.url, val.name)
                                                                    }}
                                                                >
                                                                    <Icon icon="fa:file" style={{ width: 70, height: 70, color: "#429321" }} />
                                                                    <label style={{ paddingLeft: 6, paddingRight: 30, cursor: "pointer" }}>
                                                                        {val.name}
                                                                    </label>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        zIndex: 2,
                                                                        position: "absolute",
                                                                        left: "89%",
                                                                        top: "10%",
                                                                        cursor: "pointer",
                                                                        backgroundColor: "white",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        padding: 6,
                                                                        borderRadius: 20,
                                                                        boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)"
                                                                    }}
                                                                    onClick={async () => {
                                                                        await handleCaseFileDelete(val.caseFileId)
                                                                    }}
                                                                >
                                                                    <Icon icon="fluent:delete-24-regular" style={{ color: "red", width: 20, height: 20 }} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                                : []
                                            }

                                            {casesFile?.length === 0 ?
                                                <Col span={24}>
                                                    <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                                        <label>ไม่มีไฟล์</label>
                                                    </div>
                                                </Col>
                                                : []
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        : []
                    }
                </div>
            </Modal>

            <Modal
                title={<strong><label className="topic-color-bold">{modalCaseInfo.title}</label></strong>}
                open={modalCaseInfo.isShow}
                style={{ top: 8 }}
                onCancel={() => {
                    setModalCaseInfo({
                        isShow: false,
                        title: null,
                        data: null
                    })
                }}
                width={"100%"}
                footer={null}
            >
                <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                        <Row gutter={[6, 6]}>
                            <Col span={6}>
                                <label>หัวข้อ Case</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.name ?? "-"}</label>
                            </Col>

                            <Col span={6}>
                                <label>Code</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.caseCode ?? "-"}</label>
                            </Col>

                            <Col span={6}>
                                <label>คำอธิบาย อย่างละเอียด</label>
                            </Col>
                            <Col span={18} style={{ paddingRight: 24 }}>
                                <label className='case-description'>{modalCaseInfo?.data?.description ? parse(modalCaseInfo.data.description) : "-"}</label>
                            </Col>

                            <Col span={6}>
                                <label>ผู้รับมอบหมาย</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.employee?.firstname} ({modalCaseInfo?.data?.employee?.nickname})</label>
                            </Col>

                            <Col span={6}>
                                <label>ความสำคัญ</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.priority?.name ?? "-"}</label>
                            </Col>

                            <Col span={6}>
                                <label>การดำเนิน Case</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.case_process?.name ?? "-"}</label>
                            </Col>

                            <Col span={6}>
                                <label>ประเภท Case</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.case_type?.name ?? "-"}</label>
                            </Col>

                            <Col span={6}>
                                <label>ประเภท Case ย่อย</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.case_subtype?.name ?? "-"}</label>
                            </Col>

                            <Col span={6}>
                                <label>วันที่จะเเก้ไขเสร็จ</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.completionDate ? moment(modalCaseInfo?.data?.completionDate).format("DD/MM/YYYY") : "-"}</label>
                            </Col>

                            <Col span={6}>
                                <label>สถานะการใช้งาน</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.isActive ? "เปิดใช้งาน" : "ปิดใช้งาน"}</label>
                            </Col>

                            <Col span={6}>
                                <label>โปรเจกต์</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.project?.name ?? "-"}</label>
                            </Col>

                            <Col span={6}>
                                <label>Website (URL)</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.project?.urlWebsite ?? "-"}</label>
                            </Col>

                            <Col span={6}>
                                <label>Website Backoffice (URL)</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.project?.urlWebsiteBackoffice ?? "-"}</label>
                            </Col>

                            <Col span={6}>
                                <label>API Development (URL)</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.project?.apiDevelopment ?? "-"}</label>
                            </Col>

                            <Col span={6}>
                                <label>API Production (URL)</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.project?.apiProduction ?? "-"}</label>
                            </Col>

                            <Col span={6}>
                                <label>Domain name</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <a target={"_blank"} href={modalCaseInfo?.data?.project?.domain}>{modalCaseInfo?.data?.project?.domain ?? "-"}</a>
                            </Col>

                            <Col span={6}>
                                <label>Figma (URL)</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <a href={modalCaseInfo?.data?.project?.figmaUrl}>{modalCaseInfo?.data?.project?.figmaUrl ?? "-"}</a>
                            </Col>

                            <Col span={6}>
                                <label>Git-Client (URL)</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <a target={"_blank"} href={modalCaseInfo?.data?.project?.gitClientUrl}>{modalCaseInfo?.data?.project?.gitClientUrl ?? "-"}</a>
                            </Col>

                            <Col span={6}>
                                <label>Git-Backoffice (URL)</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <a target={"_blank"} href={modalCaseInfo?.data?.project?.gitBackofficeUrl}>{modalCaseInfo?.data?.project?.gitBackofficeUrl ?? "-"}</a>
                            </Col>

                            <Col span={6}>
                                <label>Git-Server (URL)</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <a target={"_blank"} href={modalCaseInfo?.data?.project?.gitServerUrl}>{modalCaseInfo?.data?.project?.gitServerUrl ?? "-"}</a>
                            </Col>

                            <Col span={6}>
                                <label>Go-live Date</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.project?.goLiveDate ? moment(modalCaseInfo?.data?.project?.goLiveDate).format("DD/MM/YYYY") : "-"}</label>
                            </Col>

                            <Col span={6}>
                                <label>Phase</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.project_phase?.phase ?? "-"}</label>
                            </Col>

                            <Col span={6}>
                                <label>ชื่อผู้ใช้ Backoffice</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.project?.usernameAdmin ?? "-"}</label>
                            </Col>

                            <Col span={6}>
                                <label>รหัสผ่าน Backoffice</label>
                            </Col>
                            <Col span={18} style={{ borderBottom: "1px solid #EEEEEE" }}>
                                <label>{modalCaseInfo?.data?.project?.passwordAdmin ?? "-"}</label>
                            </Col>
                        </Row>
                    </div>

                    <div style={{ width: "50%", }}>
                        <Row gutter={[6, 6]}>
                            <Col span={24}>
                                <div style={{ padding: 12, backgroundColor: "#EEEEEE", borderRadius: 6 }}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingBottom: 6 }}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                            <label style={{ paddingLeft: 8 }}>รูปภาพเเนบ (.jpg, .jpeg, .png, .jfif, .webp) ใช้ประกอบการอธิบาย Case</label>
                                        </div>

                                        <Button
                                            type="primary"
                                            size="small"
                                            style={{ float: "right" }}
                                            onClick={async () => {
                                                let urls = modalCaseInfo?.data?.case_images.map(item => item.url)
                                                // console.log("urls : ", urls)

                                                await downloadAllImages(urls)
                                            }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Icon icon="entypo:download" />
                                            </div>
                                        </Button>
                                    </div>
                                    <Row gutter={[6, 6]}>
                                        {modalCaseInfo?.data?.case_images?.map((val, index) => {
                                            return (
                                                <Col key={index} xs={24} md={12} xl={6}>
                                                    <div style={{ display: "flex", justifyContent: "center", backgroundColor: "#000000" }}>
                                                        <Image
                                                            preview
                                                            src={`${val.url}`}
                                                            style={{ objectFit: "cover", width: "100%", height: 100 }}
                                                        />
                                                    </div>
                                                </Col>
                                            )
                                        })}

                                        {modalCaseInfo?.data?.case_images?.length === 0 ?
                                            <Col span={24}>
                                                <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                                    <label>ไม่มีรูปภาพ</label>
                                                </div>
                                            </Col>
                                            : []
                                        }
                                    </Row>
                                </div>
                            </Col>

                            <Col span={24}>
                                <div style={{ padding: 12, backgroundColor: "#EEEEEE", borderRadius: 6 }}>
                                    <div style={{ display: "flex", alignItems: "center", paddingBottom: 6 }}>
                                        <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                        <label style={{ paddingLeft: 8 }}>วิดีโอเเนบ (.mp4) ใช้ประกอบการอธิบาย Case</label>
                                    </div>
                                    <Row gutter={[12, 24]}>
                                        {modalCaseInfo?.data?.case_videos?.map((val) => {
                                            return (
                                                <Col xs={24} md={24} xl={24}>
                                                    <div style={{ display: "flex" }}>
                                                        <Video url={val.url} title={""} height={""} />
                                                    </div>
                                                </Col>
                                            )
                                        })}

                                        {modalCaseInfo?.data?.case_videos?.length === 0 ?
                                            <Col span={24}>
                                                <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                                    <label>ไม่มีวิดีโอ</label>
                                                </div>
                                            </Col>
                                            : []
                                        }
                                    </Row>
                                </div>
                            </Col>

                            <Col span={24}>
                                <div style={{ padding: 12, backgroundColor: "#EEEEEE", borderRadius: 6 }}>
                                    <div style={{ display: "flex", alignItems: "center", paddingBottom: 6 }}>
                                        <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                        <label style={{ paddingLeft: 8 }}>ไฟล์เเนบ (.pdf, .docx, .xlsx, .csv) ใช้ประกอบการอธิบาย Case</label>
                                    </div>
                                    <Row gutter={[12, 24]}>
                                        {modalCaseInfo?.data?.case_files?.map((val) => {
                                            return (
                                                <Col span={24}>
                                                    <div style={{ display: "flex", border: "2px solid #a8a8a8", borderRadius: 6, padding: 12 }}>
                                                        <div
                                                            style={{ display: "flex", cursor: "pointer" }}
                                                            onClick={() => {
                                                                downloadFile(val.url, val.name)
                                                            }}
                                                        >
                                                            <Icon icon="fa:file" style={{ width: 70, height: 70, color: "#429321" }} />
                                                            <label style={{ paddingLeft: 6, cursor: "pointer" }}>
                                                                {val.name}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })}

                                        {modalCaseInfo?.data?.case_files?.length === 0 ?
                                            <Col span={24}>
                                                <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                                    <label>ไม่มีวิดีโอ</label>
                                                </div>
                                            </Col>
                                            : []
                                        }
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Modal>

            <Modal
                title={<strong><label className="topic-color-bold">{modalReasonsInspectCaseFailure.title}</label></strong>}
                open={modalReasonsInspectCaseFailure.isShow}
                style={{ top: 8 }}
                onCancel={() => {
                    setModalReasonsInspectCaseFailure({
                        isShow: false,
                        title: null,
                        dataUpdateCaseProcess: null,
                        caseId: null
                    })
                }}
                width={700}
                footer={permission("case", "MCS-0012") ? [ // [MCS-0012] Show confirm click form submit for update reasons inspect case failure
                    <Button
                        type="primary"
                        onClick={() => {
                            formReasonsInspectCaseFailure.submit()
                        }}
                    >
                        ยืนยัน
                    </Button>
                ] : false}
            >
                <Form
                    layout="vertical"
                    form={formReasonsInspectCaseFailure}
                    onFinish={onReasonsInspectCaseFailureFinish}
                >
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            {permission("case", "MCS-0013") ? // [MCS-0013] Write reasons inspect case failure
                                <Form.Item
                                    name="reason"
                                    label="อธิบายเหตุผลที่ไม่ผ่าน"
                                    rules={[{ required: true, message: 'กรุณากรอกคำอธิบายเหตุผลที่ไม่ผ่าน' }]}
                                >
                                    <Input style={{ display: "none" }} />
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            placeholder: 'พิมพ์คำอธิบายที่นี่...',
                                            toolbar: {
                                                items: [
                                                    'heading', '|',
                                                    'fontfamily', 'fontsize', '|',
                                                    'bold', 'italic', 'underline', '|',
                                                    'alignment', '|',
                                                    'fontColor', 'fontBackgroundColor', '|',
                                                    'bulletedList', 'numberedList', 'todoList', '|',
                                                    'code', 'codeBlock', '|',
                                                    'undo', 'redo'
                                                ],
                                                removeButtons: 'Subscript,Superscript'
                                            }
                                        }}
                                        data={formReasonsInspectCaseFailure.getFieldValue().reason ?? ""}
                                        onBlur={(event, editor) => {
                                            const data = editor.getData()
                                            formReasonsInspectCaseFailure.setFieldValue('reason', data)
                                        }}
                                    />
                                </Form.Item>
                                :
                                <div>
                                    <label>คำอธิบายเหตุผลที่ไม่ผ่าน</label>
                                    <div style={{ backgroundColor: "#EEEEEE", paddingLeft: 6, paddingRight: 6, marginTop: -14 }}>
                                        <label>{parse(formReasonsInspectCaseFailure.getFieldValue().reason ?? "-")}</label>
                                    </div>
                                </div>
                            }
                        </Col>

                        <Col span={24}>
                            {permission("case", "MCS-0013") ? // [MCS-0013] Write reasons inspect case failure
                                <Form.Item
                                    name="amountFailure"
                                    label="จำนวนครั้งที่ไม่ผ่านตรวจสอบ"
                                >
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        disabled={true}
                                    />
                                </Form.Item>
                                :
                                <div style={{ marginTop: -14 }}>
                                    <label>จำนวนครั้งที่ไม่ผ่านตรวจสอบ</label>
                                    <div style={{ backgroundColor: "#EEEEEE", padding: 6 }}>
                                        <label>{formReasonsInspectCaseFailure.getFieldValue().amountFailure ?? "-"} ครั้ง</label>
                                    </div>
                                </div>
                            }
                        </Col>

                        <Col span={12}>
                            <div style={{ paddingBottom: 10 }}>
                                <div style={{ display: "flex", alignItems: "center", paddingBottom: 5 }}>
                                    <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                    <label style={{ paddingLeft: 8 }}>รูปภาพเเนบ (.jpg, .jpeg, .png, .jfif, .webp)</label>
                                </div>
                                {!reasonsInspectCaseFailureImageURL?.imageUrl ?
                                    <Col span={24}>
                                        <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                            <label>ไม่มีรูปภาพ</label>
                                        </div>
                                    </Col>
                                    :
                                    <>
                                        <Image
                                            fallback={`${reasonsInspectCaseFailureImageURL.imageUrl}`}
                                            src={"error"}
                                            style={{ objectFit: "cover", width: "100%" }}
                                        />
                                        {permission("case", "MCS-0014") ? // [MCS-0014] Delete attach image reasons inspect case failure
                                            <div
                                                style={{
                                                    zIndex: 2,
                                                    position: "absolute",
                                                    left: "6%",
                                                    top: "12%",
                                                    cursor: "pointer",
                                                    backgroundColor: "white",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: 6,
                                                    borderRadius: 20,
                                                    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)"
                                                }}
                                                onClick={async () => {
                                                    await handleReasonsInspectCaseFailureImageDelete(reasonsInspectCaseFailureImageURL.caseId)
                                                }}
                                            >
                                                <Icon icon="fluent:delete-24-regular" style={{ color: "red", width: 20, height: 20 }} />
                                            </div>
                                            : []
                                        }
                                    </>
                                }
                            </div>

                            {permission("case", "MCS-0017") ? // [MCS-0017] Upload attach image reasons inspect case failure
                                <Upload
                                    {...optionReasonsInspectCaseFailureImage}
                                    accept='image/jpeg, image/png, image/jfif, image/webp'
                                    style={{ width: "100%" }}
                                    maxCount={1}
                                    showUploadList={false}
                                >
                                    <Button
                                        type="primary"
                                        style={{ width: "100%" }}
                                        icon={reasonsInspectCaseFailureImageURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                    >อัพโหลดรูปภาพ</Button>
                                </Upload>
                                : []
                            }
                        </Col>

                        <Col span={12}>
                            <div style={{ paddingBottom: 10 }}>
                                <div style={{ display: "flex", alignItems: "center", paddingBottom: 5 }}>
                                    <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                    <label style={{ paddingLeft: 8 }}>วิดีโอเเนบ (.mp4)</label>
                                </div>
                                {!reasonsInspectCaseFailureVideoURL?.videoUrl ?
                                    <Col span={24}>
                                        <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                            <label>ไม่มีวิดีโอ</label>
                                        </div>
                                    </Col>
                                    :
                                    <>
                                        <div style={{ display: "flex" }}>
                                            <Video url={reasonsInspectCaseFailureVideoURL.videoUrl} title={""} height={""} />
                                            {permission("case", "MCS-0015") ? // [MCS-0015] Delete attach video reasons inspect case failure
                                                <div
                                                    style={{
                                                        zIndex: 2,
                                                        position: "absolute",
                                                        left: "7%",
                                                        top: "12%",
                                                        cursor: "pointer",
                                                        backgroundColor: "white",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        padding: 6,
                                                        borderRadius: 20,
                                                        boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)"
                                                    }}
                                                    onClick={async () => {
                                                        await handleReasonsInspectCaseFailureVideoDelete(reasonsInspectCaseFailureVideoURL.caseId)
                                                    }}
                                                >
                                                    <Icon icon="fluent:delete-24-regular" style={{ color: "red", width: 20, height: 20 }} />
                                                </div>
                                                : []
                                            }
                                        </div>
                                    </>
                                }
                            </div>

                            {permission("case", "MCS-0018") ? // [MCS-0018] Upload attach video reasons inspect case failure
                                <Upload
                                    {...optionReasonsInspectCaseFailureVideo}
                                    accept='.mp4'
                                    style={{ width: "100%" }}
                                    maxCount={1}
                                    showUploadList={false}
                                >
                                    <Button
                                        type="primary"
                                        style={{ width: "100%" }}
                                        icon={reasonsInspectCaseFailureVideoURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                    >อัพโหลดวิดีโอ</Button>
                                </Upload>
                                : []
                            }
                        </Col>

                        <Col span={12}>
                            <div style={{ paddingBottom: 10 }}>
                                <div style={{ display: "flex", alignItems: "center", paddingBottom: 5 }}>
                                    <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                    <label style={{ paddingLeft: 8 }}>ไฟล์เเนบ (.pdf, .docx, .xlsx, .csv)</label>
                                </div>
                                {!reasonsInspectCaseFailureFileURL?.fileUrl ?
                                    <Col span={24}>
                                        <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                            <label>ไม่มีไฟล์</label>
                                        </div>
                                    </Col>
                                    :
                                    <div style={{ display: "flex", border: "2px solid #EEEEEE", borderRadius: 6, padding: 12 }}>
                                        <div
                                            style={{ display: "flex", cursor: "pointer" }}
                                            onClick={() => {
                                                downloadFile(reasonsInspectCaseFailureFileURL.fileUrl, "File reasons failure")
                                            }}
                                        >
                                            <Icon icon="fa:file" style={{ width: 70, height: 70, color: "#429321" }} />
                                            <label style={{ paddingLeft: 6, paddingRight: 30, cursor: "pointer" }}>
                                                File reasons failure
                                            </label>
                                        </div>
                                        {permission("case", "MCS-0016") ? // [MCS-0016] Delete attach file reasons inspect case failure
                                            <div
                                                style={{
                                                    zIndex: 2,
                                                    position: "absolute",
                                                    left: "84%",
                                                    top: "22%",
                                                    cursor: "pointer",
                                                    backgroundColor: "white",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: 6,
                                                    borderRadius: 20,
                                                    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)"
                                                }}
                                                onClick={async () => {
                                                    await handleReasonsInspectCaseFailureFileDelete(reasonsInspectCaseFailureFileURL.caseId)
                                                }}
                                            >
                                                <Icon icon="fluent:delete-24-regular" style={{ color: "red", width: 20, height: 20 }} />
                                            </div>
                                            : []
                                        }
                                    </div>
                                }
                            </div>

                            {permission("case", "MCS-0019") ? // [MCS-0019] Upload attach file reasons inspect case failure
                                <Upload
                                    {...optionReasonsInspectCaseFailureFile}
                                    accept='.pdf, .docx, .xlsx, .csv'
                                    style={{ width: "100%" }}
                                    maxCount={1}
                                    showUploadList={false}
                                >
                                    <Button
                                        type="primary"
                                        style={{ width: "100%" }}
                                        icon={reasonsInspectCaseFailureFileURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                    >อัพโหลดไฟล์</Button>
                                </Upload>
                                : []
                            }
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Row>
    )
}
