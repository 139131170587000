/* eslint-disable no-useless-concat */
import { httpClient } from "../../../../../utils/HttpClient"

// GET -------------------------------------------------------------
const getCaseSearchFetch = async (value) => {
    try {
        const result = await httpClient.post("case/get-case-search", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCaseByOwnerFetch = async (value) => {
    try {
        const result = await httpClient.post("case/get-case-by-owner", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getDetectOpenCaseFetch = async (value) => {
    try {
        const result = await httpClient.post("case/get-detect-open-case", value)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCaseProcessAllFetch = async (value) => {
    try {
        const result = await httpClient.post("category/get-case-process-all", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCaseTypeAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-case-type-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCaseSubtypeAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-case-subtype-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getReasonsInspectCaseFailureByIdFetch = async (value) => {
    try {
        const result = await httpClient.post("case/get-reasons-inspect-case-failure-by-id", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

// INSERT ----------------------------------------------------------
const insertCaseFetch = async (value) => {
    try {
        const result = await httpClient.post("case/insert-case", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertCaseImageFetch = async (value) => {
    try {
        const result = await httpClient.post("case/insert-case-image", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertCaseVideoFetch = async (value) => {
    try {
        const result = await httpClient.post("case/insert-case-video", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertCaseFileFetch = async (value) => {
    try {
        const result = await httpClient.post("case/insert-case-file", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

// UPDATE ----------------------------------------------------------
const updateCaseByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("case/update-case-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateCaseOpenPaidEmployeeByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("case/update-case-open-paid-employee-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateCaseProcessByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("case/update-case-process-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateReasonsInspectCaseFailureByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("case/update-reasons-inspect-case-failure-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateReasonsInspectCaseFailureImageByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("case/update-reasons-inspect-case-failure-image-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateReasonsInspectCaseFailureVideoByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("case/update-reasons-inspect-case-failure-video-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateReasonsInspectCaseFailureFileByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("case/update-reasons-inspect-case-failure-file-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateAmountCaseFailureByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("case/update-amount-case-failure-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

// DELETE ----------------------------------------------------------
const deleteCaseByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("case/delete-case-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteCaseImageByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("case/delete-case-image-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteCaseVideoByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("case/delete-case-video-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteCaseFileByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("case/delete-case-file-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteReasonsInspectCaseFailureByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("case/delete-reasons-inspect-case-failure-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteReasonsInspectCaseFailureImageByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("case/delete-reasons-inspect-case-failure-image-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteReasonsInspectCaseFailureVideoByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("case/delete-reasons-inspect-case-failure-video-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteReasonsInspectCaseFailureFileByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("case/delete-reasons-inspect-case-failure-file-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getCaseSearchFetch,
    getCaseByOwnerFetch,
    getCaseProcessAllFetch,
    getDetectOpenCaseFetch,
    getCaseTypeAllFetch,
    getCaseSubtypeAllFetch,
    getReasonsInspectCaseFailureByIdFetch,

    // insert
    insertCaseFetch,
    insertCaseImageFetch,
    insertCaseVideoFetch,
    insertCaseFileFetch,

    // update
    updateCaseByIdFetch,
    updateCaseOpenPaidEmployeeByIdFetch,
    updateCaseProcessByIdFetch,
    updateReasonsInspectCaseFailureByIdFetch,
    updateReasonsInspectCaseFailureImageByIdFetch,
    updateReasonsInspectCaseFailureVideoByIdFetch,
    updateReasonsInspectCaseFailureFileByIdFetch,
    updateAmountCaseFailureByIdFetch,

    // delete
    deleteCaseByIdFetch,
    deleteCaseImageByIdFetch,
    deleteCaseVideoByIdFetch,
    deleteCaseFileByIdFetch,
    deleteReasonsInspectCaseFailureByIdFetch,
    deleteReasonsInspectCaseFailureImageByIdFetch,
    deleteReasonsInspectCaseFailureVideoByIdFetch,
    deleteReasonsInspectCaseFailureFileByIdFetch,
}
