/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Select,
    Tooltip,
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import {
    deleteTransactionPaidEmployeeByIdFetch,
    getPaidEmployeeStatusAllFetch,
    getTransactionPaidEmployeeTypeAllFetch,
    getProjectAllFetch,
    getTransactionPaidEmployeeMasterSearchFetch,
    updatePaidEmployeeStatusByIdFetch
} from './API'
import { permission } from '../../../../common/mamager/PermissionManager'
import { jsPDF } from "jspdf";
import '../../../../fonts/TH Sarabun PSK/THSarabun-normal';
import dayjs from 'dayjs'

const { Column } = Table

const { Option } = Select
const { RangePicker } = DatePicker

const formatDate = "DD/MM/YYYY"
const formatDateTime = "YYYY-MM-DD HH:mm:ss"

export default function ManageEmployeeCost() {

    const [list, setList] = useState([])
    const [transactionPaidEmployee, setTransactionPaidEmployee] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingTransactionPaidEmployee, setLoadingTransactionPaidEmployee] = useState(false)

    const [form] = Form.useForm()
    const [formSearch] = Form.useForm()

    const [pageCurrent, setPageCurrent] = useState(1)
    const [pageCurrentTransactionPaidEmployee, setPageCurrentTransactionPaidEmployee] = useState(1)

    const paymentScheduleSelected = useRef({
        projectId: null,
        referenceId: null,
        name: null,
        employeeName: null,
        employeeCostTotal: null,
        estimateTimeTotal: null,
        progressPercentageTotal: null
    })

    const transactionPaidEmployeeSearchRef = useRef({
        referenceId: null,
        projectId: null,
        paidEmployeeStatusId: 2,
        transactionPaidEmployeeTypeId: null,
        startedDate: null,
        endDate: null
    })

    const [paidEmployeeStatus, setPaidEmployeeStatus] = useState(null)
    const [transactionPaidEmployeeType, setTransactionPaidEmployeeType] = useState(null)
    const [project, setProject] = useState(null)

    const [modal, setModal] = useState({
        isShow: false,
        title: null,
        data: null
    })

    const auth = JSON.parse(sessionStorage.getItem('auth'))
    // console.log("auth : ", auth)

    const [isOpenEmployeeCostTotal, setIsOpenEmployeeCostTotal] = useState(false)

    const [summary, setSummary] = useState(null)

    const detectPaidEmployeeInfo = (id, taskInfo, caseInfo) => {
        if (id === 1) {
            return taskInfo
        } else if (id === 2) {
            return caseInfo
        }
    }

    const sizeColProjectName = () => {
        let size = 12

        if (!isOpenEmployeeCostTotal) {
            size += 12
        }

        return String(size) + "%"
    }

    const getTransactionPaidEmployeeBySearch = async (search) => {
        setLoading(true)

        const isPermitAll = (permission("employee-cost", "MEE-0001") ? true : false) // [MEE-0001] Show all employee expenses details

        const result = await getTransactionPaidEmployeeMasterSearchFetch(search, isPermitAll)
        // console.log("getTransactionPaidEmployeeMasterSearchFetch : ", result)

        let tempList = [];
        let employeeCostTotal = 0
        result?.map((val, index) => {
            employeeCostTotal += Number(val?.employeeCostSum)
            tempList.push({
                index: (index + 1),
                project: val?.project?.name,
                projectPhase: val?.project_phase ? `${val?.project_phase?.name} (Phase ${val?.project_phase?.phase})` : "-",
                referenceId: val?.referenceId,
                employee: val.employee.firstname,
                paidEmployeeStatus: val?.paid_employee_status?.name,
                transactionPaidEmployeeTypeAbbr: val?.transaction_paid_employee_type?.abbreviation,
                transactionPaidEmployeeType: val?.transaction_paid_employee_type?.name,
                employeeCostSum: Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(val?.employeeCostSum)) + " บาท",
                isActive: val?.isActive,
                paidDate: val?.paidDate ? moment(val.paidDate).format(formatDate) : "-",
                createdAt: val?.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            style={{
                                width: 28,
                                padding: 0,
                                backgroundColor: permission("employee-cost", "MEE-0002") ? "orange" : "#0066ff", // [MEE-0002] Edit employee expenses
                                border: permission("employee-cost", "MEE-0002") ? "1px solid orange" : "1px solid #0066ff", // [MEE-0002] Edit employee expenses
                                color: 'white',
                                // borderRadius: 50
                            }}
                            onClick={() => {
                                form.setFieldsValue({
                                    transactionPaidEmployeeMasterId: val.transactionPaidEmployeeMasterId,
                                    paidEmployeeStatus: val.paidEmployeeStatusId,
                                    isActive: val?.isActive
                                })

                                setLoadingTransactionPaidEmployee(true)
                                let tmpTransactionPaidEmployee = []
                                let sumEstimateTime = 0
                                let sumProgressPercentage = 0
                                val.transaction_paid_employees.map((item, index) => {
                                    tmpTransactionPaidEmployee.push({
                                        index: (index + 1),
                                        name: detectPaidEmployeeInfo(val.transactionPaidEmployeeTypeId, item?.task?.name, item?.case?.name),
                                        projectPhase: item?.project_phase ? `${item?.project_phase?.name} (Phase ${item?.project_phase?.phase})` : "-",
                                        employeeCost: Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(item?.employeeCost)) + " บาท",
                                    })

                                    sumEstimateTime += detectPaidEmployeeInfo(val.transactionPaidEmployeeTypeId, item?.task?.estimateTime, 0) // item?.case?.estimateTime

                                    sumProgressPercentage += detectPaidEmployeeInfo(val.transactionPaidEmployeeTypeId, item?.task?.progressPercentage, 0) // item?.case?.progressPercentage
                                })
                                setTransactionPaidEmployee(tmpTransactionPaidEmployee)
                                paymentScheduleSelected.current = {
                                    projectId: val?.projectId,
                                    referenceId: val?.referenceId,
                                    name: val?.project?.name,
                                    employeeName: val?.employee?.firstname + " (" + val?.employee?.nickname + ")",
                                    employeeCostTotal: val?.employeeCostSum,
                                    estimateTimeTotal: sumEstimateTime,
                                    progressPercentageTotal: sumProgressPercentage / result?.length,
                                }

                                setModal({ isShow: true, title: "edit", data: val })
                                setLoadingTransactionPaidEmployee(false)
                            }}
                            size="small"
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Icon icon={permission("employee-cost", "MEE-0002") ? "typcn:edit" : "fluent:document-data-20-regular"} style={{ color: "white", width: 15, height: 15 }} /> {/* // [MEE-0002] Edit expenses details */}
                            </div>
                        </Button>{"  "}

                        {permission("employee-cost", "MEE-0003") ? // [MEE-0003] Delete employee expenses
                            <Popconfirm
                                title="คุณยืนยันลบหรือไม่ ?"
                                okText={<span style={{ width: 50 }}>ใช่</span>}
                                onConfirm={async () => {
                                    await handleTransactionPaidEmployeeDelete(
                                        val.transactionPaidEmployeeMasterId,
                                        val.employeeId,
                                        val.projectId,
                                        val.transactionPaidEmployeeTypeId
                                    )

                                    // reload
                                    await reloadTransactionPaidEmployee()
                                }}
                                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                            >
                                <Button
                                    danger
                                    type="primary"
                                    style={{
                                        width: 28,
                                        padding: 0,
                                        // borderRadius: 50
                                    }}
                                    size="small"
                                >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 15, height: 15 }} />
                                    </div>
                                </Button>
                            </Popconfirm>
                            : []
                        }
                    </>
            })
        })

        setSummary({
            employeeCostTotal: Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(employeeCostTotal)) + " บาท"
        })

        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let obj = {
            transactionPaidEmployeeMasterId: values.transactionPaidEmployeeMasterId ?? null,
            paidEmployeeStatusId: values.paidEmployeeStatus ?? null,
            paidDate: values.paidEmployeeStatus === 3 ? moment() : null,
            isActive: values.isActive ?? null,
        }
        // console.log("obj ---- : ", obj)

        const result = await updatePaidEmployeeStatusByIdFetch(obj)
        if (result?.isSuccess) {
            Notification('success', 'เเก้ไขข้อมูลสำเร็จ')
            // set default
            setFormDefault()
        } else {
            Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
        }

        // reload
        await reloadTransactionPaidEmployee()
    }

    const onSearchFinish = async (values) => {
        let referenceId = values?.reference ?? null
        let paidEmployeeStatusId = values?.paidEmployeeStatus ?? null
        let transactionPaidEmployeeTypeId = values?.transactionPaidEmployeeType ?? null
        let projectId = values?.project ?? null
        let startedDate = values.dateRange ? values?.dateRange[0] : null
        let endDate = values.dateRange ? values?.dateRange[1] : null

        transactionPaidEmployeeSearchRef.current = {
            referenceId,
            projectId,
            paidEmployeeStatusId,
            transactionPaidEmployeeTypeId,
            startedDate,
            endDate,
        }

        let objSearch = {
            employeeId: permission("employee-cost", "MEE-0004") ? null : auth.employeeId, // [MEE-0004] Search for a list of employee cost for any employee
            referenceId,
            paidEmployeeStatusId,
            transactionPaidEmployeeTypeId,
            projectId,
            startedDate,
            endDate
        }

        // console.log("onSearchFinish : ", objSearch)
        await getTransactionPaidEmployeeBySearch(objSearch)
    }

    const handleTransactionPaidEmployeeDelete = async (id, employeeId, projectId, transactionPaidEmployeeTypeId) => {
        const result = await deleteTransactionPaidEmployeeByIdFetch(id, employeeId, projectId, transactionPaidEmployeeTypeId)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const onPagineTransactionPaidEmployee = (n) => {
        setPageCurrentTransactionPaidEmployee(n.current)
    }

    const setFormDefault = () => {
        form.resetFields()

        setModal({
            isShow: false,
            title: null,
        })
    }

    const generatePaymentSchedulePDF = () => {

        const doc = new jsPDF()

        doc.setFont('THSarabun', 'normal')

        doc.text("Payment Schedule (เลขที่อ้างอิง : " + paymentScheduleSelected.current.referenceId + ")", 10, 10)

        doc.text("คุณ " + paymentScheduleSelected.current.employeeName, 10, 20)
        doc.text("ชื่อโปรเจกต์ " + paymentScheduleSelected.current.name, 10, 30)

        doc.text("มีจำนวน " + (transactionPaidEmployee?.length ?? 0) + detectPaidEmployeeInfo(modal?.data?.transactionPaidEmployeeTypeId, " Task ", " Case ") + "ที่ได้รับมอบหมาย" + paymentScheduleSelected.current.name, 10, 40)
        let countRow = 40
        transactionPaidEmployee.map((item, index) => {
            countRow += 10
            doc.text((index + 1) + ") " + item.name, 10, countRow)
        })

        countRow += 10
        doc.text("รายได้ค่าตอบเเทน " + Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(paymentScheduleSelected.current.employeeCostTotal)) + " บาท", 10, countRow)

        countRow += 10
        doc.text("หัก ณ ที่จ่าย 3% อยู่ที่ " + Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(paymentScheduleSelected.current.employeeCostTotal * 0.03)) + " บาท", 10, countRow)

        countRow += 10
        doc.text("คงเหลือ " + Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(paymentScheduleSelected.current.employeeCostTotal * 0.97)) + " บาท", 10, countRow)

        countRow += 10
        doc.text("วันที่ดาวน์โหลดเอกสาร " + moment().format("DD-MM-YYYY HH:mm:ss"), 10, countRow)

        doc.save("Payment Schedule (" + auth.username + ") " + moment().format("DD-MM-YYYY HH:mm:ss") + ".pdf")
    }

    const getPaidEmployeeStatusAll = async () => {
        const result = await getPaidEmployeeStatusAllFetch()
        // console.log("getPaidEmployeeStatusAllFetch : ", result)
        setPaidEmployeeStatus(result)
    }

    const getTransactionPaidEmployeeTypeAll = async () => {
        const result = await getTransactionPaidEmployeeTypeAllFetch()
        // console.log("getTransactionPaidEmployeeTypeAllFetch : ", result)
        setTransactionPaidEmployeeType(result)
    }

    const getProjectAll = async () => {
        let isShowAll = true
        const result = await getProjectAllFetch(null, isShowAll)
        // console.log("getProjectAllFetch : ", result)
        setProject(result)
    }

    const getBaseApi = async () => {
        await getPaidEmployeeStatusAll()
        await getTransactionPaidEmployeeTypeAll()
        await getProjectAll()
        await reloadTransactionPaidEmployee()
    }

    const reloadTransactionPaidEmployee = async () => {
        await getTransactionPaidEmployeeBySearch({
            employeeId: permission("employee-cost", "MEE-0004") ? null : auth.employeeId, // [MEE-0004] Search for a list of employee cost for any employee
            referenceId: transactionPaidEmployeeSearchRef.current.referenceId,
            paidEmployeeStatusId: transactionPaidEmployeeSearchRef.current.paidEmployeeStatusId,
            transactionPaidEmployeeTypeId: transactionPaidEmployeeSearchRef.current.transactionPaidEmployeeTypeId,
            projectId: transactionPaidEmployeeSearchRef.current.projectId,
            startedDate: transactionPaidEmployeeSearchRef.current.startedDate,
            endDate: transactionPaidEmployeeSearchRef.current.endDate
        })
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <Row>
            <Col span={3}>
                <label>รายได้ค่าตอบเเทน</label>
            </Col>

            <Col span={21}>
                <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="เลขที่อ้างอิง"
                                name="reference"
                            >
                                <Input
                                    defaultValue={transactionPaidEmployeeSearchRef.current.referenceId}
                                    style={{ width: 90 }}
                                    onChange={(e) => {
                                        // console.log("reference --- : ", e.target.value)
                                        transactionPaidEmployeeSearchRef.current.referenceId = e.target.value
                                    }}
                                />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="โปรเจกต์"
                                name="project"
                            >
                                <Select
                                    defaultValue={transactionPaidEmployeeSearchRef.current.projectId}
                                    showSearch
                                    style={{ width: 200 }}
                                    optionFilterProp="children"
                                    allowClear
                                    onChange={(e) => {
                                        // console.log("project --- : ", e)
                                        transactionPaidEmployeeSearchRef.current.projectId = e
                                    }}
                                >
                                    {project?.map((item, index) => (
                                        <Option key={index} value={item.projectId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                name="paidEmployeeStatus"
                                label="สถานะจ่ายเงิน"
                            >
                                <Select
                                    defaultValue={transactionPaidEmployeeSearchRef.current.paidEmployeeStatusId}
                                    showSearch
                                    style={{ width: 150 }}
                                    optionFilterProp="children"
                                    allowClear
                                    onChange={(e) => {
                                        // console.log("paidEmployeeStatus --- : ", e)
                                        transactionPaidEmployeeSearchRef.current.paidEmployeeStatusId = e
                                    }}
                                >
                                    {paidEmployeeStatus?.map((item, index) => (
                                        <Option key={index} value={item.paidEmployeeStatusId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                name="transactionPaidEmployeeType"
                                label="ประเภท PS"
                            >
                                <Select
                                    defaultValue={transactionPaidEmployeeSearchRef.current.transactionPaidEmployeeTypeId}
                                    showSearch
                                    style={{ width: 150 }}
                                    optionFilterProp="children"
                                    allowClear
                                    onChange={(e) => {
                                        // console.log("transactionPaidEmployeeType --- : ", e)
                                        transactionPaidEmployeeSearchRef.current.transactionPaidEmployeeTypeId = e
                                    }}
                                >
                                    {transactionPaidEmployeeType?.map((item, index) => (
                                        <Option key={index} value={item.transactionPaidEmployeeTypeId}>{item.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ช่วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker
                                    defaultValue={
                                        (transactionPaidEmployeeSearchRef.current.startedDate && transactionPaidEmployeeSearchRef.current.endDate) ?
                                            [
                                                dayjs(transactionPaidEmployeeSearchRef.current.startedDate, formatDateTime),
                                                dayjs(transactionPaidEmployeeSearchRef.current.endDate, formatDateTime)
                                            ]
                                            :
                                            undefined
                                    }
                                    onChange={(e) => {
                                        // console.log("dateRange --- : ", e)
                                        transactionPaidEmployeeSearchRef.current.startedDate = e[0]
                                        transactionPaidEmployeeSearchRef.current.endDate = e[1]
                                    }}
                                />
                            </Form.Item>
                        </div>

                        <div style={{ paddingLeft: 10, marginTop: 3 }}>
                            <Button
                                style={{ float: 'right', width: 70 }}
                                type="primary"
                                onClick={() => formSearch.submit()}
                            >
                                ค้นหา
                            </Button>
                        </div>
                    </div>
                </Form>
            </Col>

            <Col span={24}>
                <Table
                    loading={loading}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 9,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                    <Column title="No." dataIndex="index" key="index" width={"3%"} align={'center'} />
                    <Column title="เลขที่อ้างอิง" dataIndex="referenceId" key="referenceId" width={"8%"} align={'center'} />
                    <Column
                        title="ชื่อโปรเจกต์"
                        dataIndex="project"
                        key="project"
                        width={sizeColProjectName()}
                        render={(tags, record) => (
                            <div style={{ display: "flex" }}>
                                <Tooltip placement="right" title={"ประเภท " + record.transactionPaidEmployeeType}>
                                    <label
                                        style={{
                                            paddingRight: 6,
                                            fontWeight: 600,
                                            color: "#429321"
                                        }}
                                    >
                                        {record.transactionPaidEmployeeTypeAbbr}
                                    </label>
                                </Tooltip>
                                <label>{tags}</label>
                            </div>
                        )}
                    />
                    <Column title="Project phase/round" dataIndex="projectPhase" key="projectPhase" width={"16%"} />
                    <Column title="ผู้รับเงิน" dataIndex="employee" key="employee" width={"8%"} />
                    <Column title="สถานะจ่ายเงิน" dataIndex="paidEmployeeStatus" key="paidEmployeeStatus" width={"8%"} />
                    {isOpenEmployeeCostTotal ? <Column title="รายได้ค่าตอบเเทนรวม" dataIndex="employeeCostSum" key="employeeCostSum" width={"12%"} /> : []}
                    <Column title="วันที่จ่าย" dataIndex="paidDate" key="paidDate" width={"7%"} align={'center'} />
                    <Column title="วันที่สร้าง" dataIndex="createdAt" key="createdAt" width={"7%"} align={'center'} />
                    <Column title={<FaCog />} dataIndex="operator" key="operator" width={"7%"} align={'center'} />
                </Table>
            </Col>

            <Col span={24}>

                <Row gutter={[6, 6]} style={{ paddingTop: 12 }}>
                    <Col span={12}>
                        <div style={{ display: "grid" }}>
                            <div style={{ display: "flex", alignItems: "center", paddingBottom: 4 }}>
                                <div style={{ width: 350 }}>
                                    <label>{permission("employee-cost", "MEE-0005") ? "ค่าใช้จ่ายพนักงานรวม (ตามข้อมูลที่เเสดงในตาราง)" : ("รายได้รวมของคุณ " + auth.firstname + " (ตามข้อมูลที่เเสดงในตาราง)")}</label> {/* // [MEE-0005] Show sum employee expenses mode manage accounts */}
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/* {console.log("summary --- : ", summary)} */}
                                    {isOpenEmployeeCostTotal ?
                                        <label style={{ paddingRight: 8, fontWeight: 600 }}>{summary?.employeeCostTotal}</label>
                                        // 
                                        : []
                                    }

                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setIsOpenEmployeeCostTotal(!isOpenEmployeeCostTotal)
                                        }}
                                        size="small"
                                        style={{ display: "flex", alignItems: "center" }}
                                    >

                                        {isOpenEmployeeCostTotal ?
                                            <Icon icon="mdi:hide" style={{ color: "white", width: 18, height: 18 }} />
                                            :
                                            <Icon icon="akar-icons:eye-open" style={{ color: "white", width: 18, height: 18 }} />
                                        }

                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>

            <Modal
                title={
                    <strong>
                        <label className="topic-color-bold">{permission("employee-cost", "MEE-0006") ? // [MEE-0006] Show employee expenses detail mode manage accounts
                            detectPaidEmployeeInfo(modal?.data?.transactionPaidEmployeeTypeId, "รายละเอียดค่าใช้จ่ายตาม Task", "รายละเอียดค่าใช้จ่ายตาม Case")
                            :
                            detectPaidEmployeeInfo(modal?.data?.transactionPaidEmployeeTypeId, "รายละเอียดรายได้ตาม Task", "รายละเอียดรายได้ตาม Case")}
                        </label>
                    </strong>
                }
                visible={modal.isShow}
                onCancel={() => {
                    // default
                    setFormDefault()
                }}
                width={"100%"}
                style={{ top: 8 }}
                footer={[
                    <Button
                        type="primary"
                        onClick={() => {
                            generatePaymentSchedulePDF()
                        }}
                    >ดาวน์โหลด Payment Schedule (PDF)</Button>,
                    <Button
                        type="default"
                        onClick={() => {
                            // default
                            setFormDefault()
                        }}
                    >ยกเลิก</Button>,
                    <Button
                        type="primary"
                        onClick={() => {
                            form.submit()
                        }}
                    >บันทึก</Button>
                ]}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[0, 0]}>
                        <Col span={24}>
                            <Table
                                loading={loadingTransactionPaidEmployee}
                                dataSource={transactionPaidEmployee}
                                pagination={{
                                    current: pageCurrentTransactionPaidEmployee,
                                    pageSize: 10,
                                    total: transactionPaidEmployee.length
                                }}
                                onChange={(n) => onPagineTransactionPaidEmployee(n)}
                            >
                                <Column title="No." dataIndex="index" key="index" width={"3%"} />
                                <Column title={detectPaidEmployeeInfo(modal?.data?.transactionPaidEmployeeTypeId, "ชื่อ Task", "ชื่อ Case")} dataIndex="name" key="name" width={"35%"} />
                                <Column title={"Project phase/round"} dataIndex="projectPhase" key="projectPhase" width={"15%"} />
                                {permission("employee-cost", "MEE-0007") ? <Column title="รายได้ค่าตอบเเทนย่อย" dataIndex="employeeCost" key="employeeCost" width={"12%"} /> : []} {/* // [MEE-0007] Show column employee expenses list */}
                            </Table>
                        </Col>

                        {permission("employee-cost", "MEE-0008") ? // [MEE-0008] Pay employees and change the status of the operation
                            <>
                                <Col span={24}>
                                    <Form.Item name="transactionPaidEmployeeMasterId" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={4} style={{ paddingRight: 12 }}>
                                    <Form.Item
                                        name="paidEmployeeStatus"
                                        label="สถานะการจ่ายเงินพนักงาน"
                                        rules={[{ required: true, message: 'กรุณาเลือกสถานะการจ่ายเงินพนักงาน' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            {paidEmployeeStatus?.map((item, index) => (
                                                <Option key={index} value={item.paidEmployeeStatusId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={4} style={{ paddingRight: 12 }}>
                                    <Form.Item
                                        name="isActive"
                                        label="สถานะการใช้งาน"
                                        rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            <Option key={0} value={true}>เปิดการใช้งาน</Option>
                                            <Option key={1} value={false}>ปิดการใช้งาน</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={16} style={{ backgroundColor: "#EEEEEE", borderRadius: 6 }}>
                                    <Row gutter={[6, 6]} style={{ padding: 12 }}>
                                        <Col span={6}>
                                            <label>ชื่อบัญชีธนาคาร</label>
                                        </Col>
                                        <Col span={18}>
                                            <label>{modal?.data?.employee.bankAccount ?? "-"}</label>
                                        </Col>

                                        <Col span={6}>
                                            <label>เลขบัญชีธนาคาร</label>
                                        </Col>
                                        <Col span={18}>
                                            <label>{modal?.data?.employee.bankNumber ?? "-"}</label>
                                        </Col>

                                        <Col span={6}>
                                            <label>เลขบัญชีธนาคาร</label>
                                        </Col>
                                        <Col span={18}>
                                            <label>{modal?.data?.employee.bank.name ?? "-"}</label>
                                        </Col>

                                        <Col span={6}>
                                            <label>เงินที่ต้องจ่ายให้พนักงาน</label>
                                        </Col>
                                        <Col span={18}>
                                            <label>{Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(modal?.data?.employeeCostSum)) + " บาท" ?? "-"}</label>
                                        </Col>

                                        <Col span={6}>
                                            <label>หัก ณ ที่จ่าย 3% อยู่ที่</label>
                                        </Col>
                                        <Col span={18}>
                                            <label>{Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(modal?.data?.employeeCostSum * 0.03)) + " บาท" ?? "-"}</label>
                                        </Col>

                                        <Col span={6}>
                                            <label style={{ color: "red" }}>คงเหลือที่ต้องจ่าย</label>
                                        </Col>
                                        <Col span={18}>
                                            <label style={{ color: "red" }}>{Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(modal?.data?.employeeCostSum * 0.97)) + " บาท" ?? "-"}</label>
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                            : []
                        }
                    </Row>
                </Form>
            </Modal>
        </Row>
    )
}
