import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Card,
    Row,
    Col,
    Form,
    Input,
    Button
} from 'antd'
import {
    getEmployeeAuthSignInFetch,
} from '../API'

export default function LoginERP() {
    const [form] = Form.useForm()

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [loginCorrect, setIsLoginCorrect] = useState(null)

    const onFinish = async (values) => {
        setLoading(true)

        let username = values.username ? values.username : null
        let password = values.password

        const result = await getEmployeeAuthSignInFetch(username, password)
        // console.log("getEmployeeAuthSignInFetch : ", result)

        if (result?.isCorrect) {
            let roles = []
            if (result.formData.employeeId === 7) { // เป็นคุณเอก
                roles = [
                    'MPJ-0001', 'MPJ-0002', 'MPJ-0003', 'MPJ-0004', 'MPJ-0005',
                    'MPJ-0006', 'MPJ-0007', 'MPJ-0008',

                    'MTK-0001', 'MTK-0002', 'MTK-0003', 'MTK-0004', 'MTK-0005',
                    'MTK-0006', 'MTK-0007', 'MTK-0008', 'MTK-0009', 'MTK-0010',
                    'MTK-0011', 'MTK-0012', 'MTK-0013', 'MTK-0014', 'MTK-0015',
                    'MTK-0016', 'MTK-0017', 'MTK-0018', "MTK-0019", "MTK-0020",
                    'MTK-0021', 'MTK-0022', 'MTK-0023', "MTK-0024", "MTK-0025",
                    "MTK-0026", 'MTK-0027', 'MTK-0028', "MTK-0029", "MTK-0030",

                    'MCS-0001', 'MCS-0002', 'MCS-0003', 'MCS-0004', 'MCS-0005',
                    'MCS-0006', 'MCS-0007', 'MCS-0008', 'MCS-0009', 'MCS-0010',
                    'MCS-0011', 'MCS-0012', 'MCS-0013', 'MCS-0014', 'MCS-0015',
                    'MCS-0016', 'MCS-0017', 'MCS-0018', 'MCS-0019',

                    'MEE-0001', 'MEE-0002', 'MEE-0003', 'MEE-0004', 'MEE-0005',
                    'MEE-0006', 'MEE-0007', 'MEE-0008',

                    'ERP-0001', 'ERP-0002', 'ERP-0003', 'ERP-0004', 'ERP-0005',
                    'ERP-0006'
                ]
            }

            let auth = {
                employeeId: result.formData.employeeId,
                username: result.formData.username,
                firstname: result.formData.employee.firstname,
                lastname: result.formData.employee.lastname,
                nickname: result.formData.employee.nickname,

                employeeLevelId: result.formData.employee.employee_level.employeeLevelId,
                employeeLeveName: result.formData.employee.employee_level.name,

                employeePositionId: result.formData.employee.employee_position.employeePositionId,
                employeePositionName: result?.formData?.employee?.employee_position?.name,

                roles
            }

            sessionStorage.setItem('auth', JSON.stringify(auth))

            setIsLoginCorrect(true)
            navigate("/erp")
        } else {
            setIsLoginCorrect(false)
        }

        setLoading(false)
    }

    return (
        <div style={{ backgroundColor: '#222222' }}>
            <article>
                <Row
                    style={{
                        width: 500,
                        top: "50%",
                        left: "50%",
                        position: "absolute",
                        msTransform: "translate(-50%, -50%)",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <Col span={24}>
                        <Card>
                            <Row
                                gutter={[12, 0]}
                                style={{
                                    paddingTop: '5%',
                                    paddingBottom: '10%',
                                    paddingLeft: '10%',
                                    paddingRight: '10%'
                                }}
                            >
                                <Col span={24} style={{ paddingBottom: 15 }}><h1 style={{ float: "left" }}>Soft Po Market - ERP</h1></Col>

                                <Col span={24} style={{}}>
                                    <Form layout="vertical" form={form} onFinish={onFinish}>
                                        <Form.Item
                                            label="ชื่อผู้ใช้"
                                            name="username"
                                            rules={[{ required: true, message: <label style={{ float: "left" }}>กรุณากรอก username!</label> }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Form>
                                </Col>

                                <Col span={24} style={{}}>
                                    <Form layout="vertical" form={form} onFinish={onFinish}>
                                        <Form.Item
                                            label="รหัสผ่าน"
                                            name="password"
                                            rules={[{ required: true, message: <label style={{ float: "left" }}>กรุณากรอก password!</label> }]}
                                        >
                                            <Input.Password />
                                        </Form.Item>
                                    </Form>
                                </Col>

                                <Col span={24} style={{ paddingBottom: 12 }}>
                                    {loginCorrect !== null ? (loginCorrect ? [] : <label style={{ color: "red", paddingBottom: 12 }}>กรอกรหัสผ่านไม่ถูกต้อง!</label>) : []}
                                </Col>

                                <Col span={24}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Button
                                            type="primary"
                                            size="large"
                                            style={{ width: "100%" }}
                                            onClick={() => form.submit()}
                                            loading={loading}
                                        >
                                            {!loading ? "เข้าสู่ระบบ" : []}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </article>
        </div>
    )
}
