/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-const-assign */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useContext, useEffect, useRef, useMemo, useCallback } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    DatePicker,
    Popconfirm,
    Select,
    InputNumber,
    Tooltip,
    Spin,
    Image,
    Upload,
    Switch,
} from 'antd'
import { FaCog } from "react-icons/fa"
import moment from 'moment'
import 'moment/locale/th'
import { Icon } from '@iconify/react'
import { Notification } from '../../../../common/components/notification'
import {
    deleteProjectByIdFetch,
    deleteProjectPhaseByIdFetch,
    getBusinessAllFetch,
    getCustomerAllFetch,
    getPackageByIdFetch,
    getPaymentInstallmentAllFetch,
    getPaymentProcessByIdFetch,
    getProjectPhaseByIdFetch,
    getProjectPhaseSearch,
    getProjectSearchFetch,
    getProjectTypeByIdFetch,
    getServiceAllFetch,
    getWorkProcessByIdFetch,
    insertProjectFetch,
    insertProjectPhaseFetch,
    updateProjectByIdFetch,
    updateProjectPhaseByIdFetch,
} from './API'
import dayjs from 'dayjs'
import {
    deleteTaskByIdFetch,
    getEmployeeAllFetch,
    getPriorityAllFetch,
    getTaskOpenStatusAllFetch,
    getTaskProcessAllFetch,
    getTaskSearchFetch,
    insertTaskFetch,
    updateTaskByIdFetch,
    updateTaskOpenPaidEmployeeByIdFetch,
    insertTransactionPaidEmployeeFetch,
    updateTaskOpenStatusByIdFetch,
    updateTaskEployeeCostByIdFetch,
    updateTaskDragDropByIdFetch,
    getTaskAllFetch,
    updateTaskProgressPercentageByIdFetch,
    updateTaskProcessByIdFetch,
    updateReasonsInspectTaskFailureImageByIdFetch,
    updateReasonsInspectTaskFailureVideoByIdFetch,
    updateReasonsInspectTaskFailureFileByIdFetch,
    updateReasonsInspectTaskFailureByIdFetch,
    getReasonsInspectTaskFailureByIdFetch,
    deleteReasonsInspectTaskFailureByIdFetch,
    deleteReasonsInspectTaskFailureImageByIdFetch,
    deleteReasonsInspectTaskFailureVideoByIdFetch,
    deleteReasonsInspectTaskFailureFileByIdFetch,
    updateTaskEstimateTimeByIdFetch,
    updateTaskOperationCostByIdFetch,
    updateTaskOperationDiscountByIdFetch,
    updateTaskNameByIdFetch,
    updateTaskEmployeePositionByIdFetch,
    updateTaskOwnerByIdFetch
} from '../task/API'
import { permission } from '../../../../common/mamager/PermissionManager'
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { HolderOutlined } from '@ant-design/icons'
import { detactItemCreateLatest } from '../case/managers/detactItemCreateLatest'
import { apiServerUrl } from '../../../../constants'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
    LoadingOutlined,
    UploadOutlined
} from '@ant-design/icons'
import parse from 'html-react-parser'
import Video from '../../../../common/components/video'
import { downloadFile } from '../../managers/downloadMamager'
import { getEmployeePositionAllFetch } from '../employee/API'

import "./css/index.css"
import { calculateDaysDifference } from '../../../../common/mamager/DateMamager'
import { title } from 'process'
import debounce from "lodash.debounce";

const { Column } = Table

const { TextArea } = Input;
const { Option } = Select
const { RangePicker } = DatePicker

moment.locale('th')
const formatDate = "YYYY-MM-DD"
const formatDateTime = "YYYY-MM-DD HH:mm:ss"

export default function ManageProject() {
    const [project, setProject] = useState([])
    const [projectPhase, setProjectPhase] = useState([])

    const [loadingProject, setLoadingProject] = useState(false)
    const [loadingProjectPhase, setLoadingProjectPhase] = useState(false)
    const [loadingTask, setLoadingTask] = useState(false)

    const [formProject] = Form.useForm()
    const [formProjectPhase] = Form.useForm()
    const [formTask] = Form.useForm()

    const [formSearchProject] = Form.useForm()
    const [formSearchTask] = Form.useForm()

    const [formReasonsInspectTaskFailure] = Form.useForm()

    const [employeeCostRatio, setEmployeeCostRatio] = useState(0.3)
    const [isOpenEmployeeCostRatio, setIsOpenEmployeeCostRatio] = useState(true)

    const taskSearchRef = useRef({
        ownerId: null,
        taskName: null,
        taskOpenStatusId: null,
        employeePositionId: null,
        projectPhaseId: null,
        startedDate: null,
        endDate: null
    })

    const projectSelected = useRef({
        projectId: null,
        name: null,
    })

    const [taskSelected, setTaskSelected] = useState({
        employeeCostTotal: null,
        operationCostTotal: null,
        operationDiscountTotal: null,
        estimateTimeTotal: null,
        progressPercentageTotal: null,
        taskListTable: [],
        taskListOriginal: []
    })

    const [pageCurrentProject, setPageCurrentProject] = useState(1)
    const [pageCurrentProjectPhase, setPageCurrentProjectPhase] = useState(1)

    const [displayProject, setDisplayProject] = useState({
        isOpen: false,
        type: null
    })

    const [displayTaskOperation, setDisplayTaskOperation] = useState({
        isOpen: false,
        type: null,
        data: null
    })

    const [paymentProcess, setPaymentProcess] = useState(null)
    const [business, setBusiness] = useState(null)
    const [projectType, setProjectType] = useState(null)
    const [workProcess, setWorkProcess] = useState(null)
    const [customer, setCustomer] = useState(null)
    const [packages, setPackages] = useState(null)
    const [paymentInstallment, setPaymentInstallment] = useState(null)
    const [service, setService] = useState(null)

    const [employee, setEmployee] = useState(null)
    const [employeePosition, setEmployeePosition] = useState(null)
    const [taskProcess, setTaskProcess] = useState(null)
    const [priority, setPriority] = useState(null)
    const [taskOpenStatus, setTaskOpenStatus] = useState(null)
    const [taskOption, setTaskOption] = useState([])

    const [processingDays, setProcessingDays] = useState(null)

    const auth = JSON.parse(sessionStorage.getItem('auth'))

    const [modalTask, setModalTask] = useState({
        isShow: false,
        title: null
    })

    const [modalProjectPhase, setModalProjectPhase] = useState({
        isShow: false,
        type: null
    })

    const [modalProjectInfo, setModalProjectInfo] = useState({
        isShow: false,
        title: null,
        data: null
    })

    const [modalTaskInfo, setModalTaskInfo] = useState({
        isShow: false,
        title: null,
        data: null
    })

    const [modalReasonsInspectTaskFailure, setModalReasonsInspectTaskFailure] = useState({
        isShow: false,
        title: null,
        dataUpdateTaskProcess: null,
        taskId: null,
        record: null,
        taskProcessId: null
    })

    const [selectedRowKeysTask, setSelectedRowKeysTask] = useState([])
    const [resetTableTask, setResetTableTask] = useState(false)

    const RowContext = React.createContext({})

    const [reasonsInspectTaskFailureImageURL, setReasonsInspectTaskFailureImageURL] = useState({
        loading: false,
        imageUrl: null,
        taskId: null
    })

    const [reasonsInspectTaskFailureVideoURL, setReasonsInspectTaskFailureVideoURL] = useState({
        loading: false,
        videoUrl: null,
        taskId: null
    })

    const [reasonsInspectTaskFailureFileURL, setReasonsInspectTaskFailureFileURL] = useState({
        loading: false,
        fileUrl: null,
        taskId: null
    })

    const optionReasonsInspectTaskFailureImage = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "spm-erp",
            folder: "reasons-inspect-task-failure/image"
        },
        headers: {
            authorization: 'authorization-text',
        },
        async onChange(info) {

            let result = info.file.response
            if (info.file.status !== 'uploading') {
                if (result?.isSuccess) {
                    const resultReasonsInspectTaskFailureImage = await updateReasonsInspectTaskFailureImageByIdFetch({
                        reasonsInspectTaskFailureImageUrl: result.url,
                        taskId: reasonsInspectTaskFailureImageURL.taskId,
                    })
                    if (resultReasonsInspectTaskFailureImage.isSuccess) {
                        setReasonsInspectTaskFailureImageURL(prev => ({
                            ...prev,
                            imageUrl: result.url,
                            loading: false
                        }))
                    }
                }
            } else {
                setReasonsInspectTaskFailureImageURL(prev => ({
                    ...prev,
                    loading: true
                }))
            }
        }
    }

    const optionReasonsInspectTaskFailureVideo = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "spm-erp",
            folder: "reasons-inspect-task-failure/video"
        },
        headers: {
            authorization: 'authorization-text',
        },
        async onChange(info) {

            let result = info.file.response
            if (info.file.status !== 'uploading') {
                if (result?.isSuccess) {
                    const resultReasonsInspectTaskFailureVideo = await updateReasonsInspectTaskFailureVideoByIdFetch({
                        reasonsInspectTaskFailureVideoUrl: result.url,
                        taskId: reasonsInspectTaskFailureVideoURL.taskId,
                    })
                    if (resultReasonsInspectTaskFailureVideo.isSuccess) {
                        setReasonsInspectTaskFailureVideoURL(prev => ({
                            ...prev,
                            videoUrl: result.url,
                            loading: false
                        }))
                    }
                }
            } else {
                setReasonsInspectTaskFailureVideoURL(prev => ({
                    ...prev,
                    loading: true
                }))
            }
        }
    }

    const optionReasonsInspectTaskFailureFile = {
        name: 'file',
        action: `${apiServerUrl}/upload/file`,
        data: {
            bucket: "spm-erp",
            folder: "reasons-inspect-task-failure/file"
        },
        headers: {
            authorization: 'authorization-text',
        },
        async onChange(info) {

            let result = info.file.response
            if (info.file.status !== 'uploading') {
                if (result?.isSuccess) {
                    const resultReasonsInspectTaskFailureFile = await updateReasonsInspectTaskFailureFileByIdFetch({
                        reasonsInspectTaskFailureFileUrl: result.url,
                        taskId: reasonsInspectTaskFailureFileURL.taskId,
                    })
                    if (resultReasonsInspectTaskFailureFile.isSuccess) {
                        setReasonsInspectTaskFailureFileURL(prev => ({
                            ...prev,
                            fileUrl: result.url,
                            loading: false
                        }))
                    }
                }
            } else {
                setReasonsInspectTaskFailureFileURL(prev => ({
                    ...prev,
                    loading: true
                }))
            }
        }
    }

    const Rows = (props) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            setActivatorNodeRef,
            transform,
            transition,
            isDragging,
        } = useSortable({
            id: props['data-row-key'],
        });

        // กำหนด style สำหรับ Row
        const style = useMemo(() => ({
            ...props.style,
            transform: transform ? CSS.Translate.toString(transform) : undefined,
            transition,
            ...(isDragging
                ? {
                    position: 'relative',
                    zIndex: 9999,
                }
                : {}),
        }), [props.style, transform, transition, isDragging]);

        // สร้าง context สำหรับ Row
        const contextValue = useMemo(() => ({
            setActivatorNodeRef,
            listeners,
        }), [setActivatorNodeRef, listeners]);

        return (
            <RowContext.Provider value={contextValue}>
                <tr {...props} ref={setNodeRef} style={style} {...attributes} />
            </RowContext.Provider>
        );
    };

    const onDragEnd = async ({ active, over }) => {
        if (!permission("project", "MPJ-0001")) {
            Notification('warning', 'ไม่มีสิทธิเคลื่อนย้าย Task โปรดติดต่อผู้ดูเเล Project นี้!');
            return;
        }

        if (active.id === over?.id) return;

        console.log("X2 *****************************************");

        // Use memoization to reduce unnecessary re-renders
        const taskIds = taskSelected.taskListTable.reduce((acc, _, idx, arr) => {
            if (arr[idx].key === active?.id) {
                const activeIndex = idx;
                const overIndex = arr.findIndex((record) => record.key === over?.id);
                const sortedData = arrayMove(arr, activeIndex, overIndex);
                setTaskSelected({
                    ...taskSelected,
                    taskListTable: sortedData,
                });
                return sortedData.map(({ taskId }) => ({ taskId }));
            }
            return acc;
        }, []);

        const obj = {
            taskIds,
            projectId: projectSelected.current.projectId,
            ownerId: permission("task", "MTK-0001")
                ? taskSearchRef.current.ownerId
                : auth.employeeId,
        };

        try {
            // Wrap the async functions to ensure they run only once
            await updateTaskDragDropByIdFetch(obj);
            await reloadTaskSearch();
        } catch (error) {
            console.error('Error updating task order:', error);
            Notification('error', 'เกิดข้อผิดพลาดในการอัปเดตข้อมูล');
        }
    };

    const [pageSize, setPageSize] = useState(10); // ตั้งค่าขนาดหน้าเริ่มต้น
    const [currentPage, setCurrentPage] = useState(1); // ตั้งค่าหน้าปัจจุบัน

    const DragHandle = () => {
        const { setActivatorNodeRef, listeners } = useContext(RowContext);
        return (
            <Button
                type="text"
                size="small"
                icon={<HolderOutlined />}
                style={{
                    cursor: 'move',
                }}
                ref={setActivatorNodeRef}
                {...listeners}
            />
        )
    }

    const handlePaymentSchedule = async () => {
        if (taskSearchRef.current.ownerId && taskSearchRef.current.projectPhaseId) {
            let employeeCostSum = 0
            let operationCostSum = 0
            let operationDiscountSum = 0
            let taskList = []
            taskSelected.taskListOriginal.map((item) => {
                if (selectedRowKeysTask.includes(item.taskId)) {
                    employeeCostSum += item.employeeCost
                    operationCostSum += item.operationCost
                    operationDiscountSum += item.operationDiscountSum
                    taskList.push({
                        // for insert-transaction-paid-employee
                        taskId: item.taskId,
                        caseId: null,
                        ownerCreateId: auth.employeeId,
                        employeeId: taskSearchRef.current.ownerId,
                        employeeCost: item.employeeCost,
                        operationCost: item.operationCost,
                        operationDiscount: item.operationDiscount,
                        projectPhaseId: item.projectPhaseId,

                        // for update-task-open-paid-employee-by-id
                        isOpenPaidEmployee: true
                    })
                }
            })

            const master = {
                projectId: projectSelected.current.projectId,
                ownerCreateId: auth.employeeId,
                transactionPaidEmployeeTypeId: 1,
                employeeId: taskSearchRef.current.ownerId,
                projectPhaseId: taskSearchRef.current.projectPhaseId,
                employeeCostSum,
                operationCostSum,
                operationDiscountSum
            }

            const resultInsertTransactionPaidEmployee = await insertTransactionPaidEmployeeFetch(master, taskList)
            const resultUpdateTaskOpenPaidEmployee = await updateTaskOpenPaidEmployeeByIdFetch(taskList)

            if (resultInsertTransactionPaidEmployee?.isSuccess && resultUpdateTaskOpenPaidEmployee?.isSuccess) {
                Notification('success', 'สร้าง PaymentSchedule สำเร็จ')
                setResetTableTask(true)

                setTimeout(async () => {
                    setSelectedRowKeysTask([])
                    setResetTableTask(false)

                    // reload
                    await reloadTaskSearch()
                }, 1000)

            } else {
                Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
            }
        } else {
            Notification('error', 'กรุณาเลือกผู้รับผิดชอบ เเละ Project phase/round ที่ช่องค้นหา/เลือก เเล้วกดปุ่มค้นหา/เลือก เพื่อใช้ระบุเจ้าของ Payment Schedule ที่จะสร้าง')
        }
    }
    
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            console.log("X --- : ", selectedRowKeys)

            setSelectedRowKeysTask(selectedRowKeys)
        },
        getCheckboxProps: (record) => ({
            disabled: record.isOpenPaidEmployee === true || record.taskProcessId !== 4 || record.taskOpenStatusId !== 2,
            // Column configuration not to be checked
            // name: record.name,
        }),
    };

    const getProjectBySearch = async (search) => {
        setLoadingProject(true)

        const isPermitAll = (permission("project", "MPJ-0002") ? true : false) // [MPJ-0002] Show all project details

        const result = await getProjectSearchFetch(search, isPermitAll)
        // console.log("getProjectSearchFetch : ", result)

        let tempProject = [];
        result?.map((val, index) => {
            let workProcess = null // [1 : Recive requirement], [2: Design], [3: Coding], [4: Test], [5: Done]
            let countComplete = 0
            if (val.project_phases?.length > 0) {
                val.project_phases.map(pp => {
                    if (pp?.work_process?.workProcessId === 5) {
                        countComplete++
                    }
                })

                if (val.project_phases?.length === countComplete) {
                    workProcess = 5
                } else {
                    let notCompleteList = val.project_phases.filter(fill => fill?.work_process?.workProcessId !== 5)
                    workProcess = notCompleteList[notCompleteList?.length - 1]?.work_process?.workProcessId
                }
            }

            // project name
            let projectName = ""
            if (val?.serviceId === 1) { // Software Development
                projectName = "SD -> " + val?.name
            } else if (val?.serviceId === 2) { // Marketing
                projectName = "MK -> " + val?.name
            } else {
                projectName = val?.name
            }

            tempProject.push({
                index: (index + 1),
                name: projectName,
                business: val?.business?.name ?? "-",
                workProcess,
                paymentProcess: val?.payment_process?.name ?? "-",
                projectCode: val?.projectCode,
                service: val?.service?.name ?? "-",
                isActive: val?.isActive ?? null,
                operator:
                    <>
                        {permission("project", "MPJ-0003") ? // [MPJ-0003] Edit project
                            <>
                                <Button
                                    style={{
                                        width: 28,
                                        padding: 0,
                                        backgroundColor: "orange",
                                        border: "1px solid orange",
                                        color: 'white'
                                    }}
                                    onClick={async () => {
                                        projectSelected.current = {
                                            projectId: val.projectId,
                                            service: val.serviceId,
                                            name: val.name
                                        }

                                        formProject.setFieldsValue({
                                            projectId: val.projectId,
                                            projectType: val.projectTypeId,
                                            customer: val.customerId,
                                            business: val.businessId,
                                            package: val.packageId,
                                            service: val.serviceId,
                                            urlWebsite: val.urlWebsite,
                                            urlWebsiteBackoffice: val.urlWebsiteBackoffice,
                                            apiDevelopment: val.apiDevelopment,
                                            apiProduction: val.apiProduction,
                                            domain: val.domain,
                                            name: val.name,
                                            usernameAdmin: val.usernameAdmin,
                                            passwordAdmin: val.passwordAdmin,
                                            figmaUrl: val.figmaUrl,
                                            gitClientUrl: val.gitClientUrl,
                                            gitBackofficeUrl: val.gitBackofficeUrl,
                                            gitServerUrl: val.gitServerUrl,
                                            projectValue: val.projectValue,
                                            isActive: val.isActive
                                        })

                                        await getProjectTypeById(val.serviceId)
                                        await getPackageById(val.serviceId)
                                        await getWorkProcessById(val.serviceId)
                                        await getPaymentProcessById(val.paymentInstallmentId)
                                        await getTaskAll()

                                        window.scroll(0, 0)
                                        setDisplayProject({
                                            isOpen: true,
                                            type: "edit"
                                        })
                                    }}
                                    size="small"
                                >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Icon icon="typcn:edit" style={{ color: "white", width: 15, height: 15 }} />
                                    </div>
                                </Button>{"  "}
                            </>
                            : null
                        }

                        {permission("project", "MPJ-0008") ? // [MPJ-0008] Show all project phase/round
                            <>
                                <Button
                                    type="primary"
                                    size="small"
                                    style={{
                                        width: 28,
                                        padding: 0,
                                        backgroundColor: "orange",
                                        border: "1px solid orange",
                                        color: 'white'
                                    }}
                                    onClick={async () => {
                                        projectSelected.current = {
                                            projectId: val.projectId,
                                            service: val.serviceId,
                                            name: val.name
                                        }

                                        await getWorkProcessById(val.serviceId)

                                        // reload 
                                        await reloadProjectPhaseSearch()

                                        setModalProjectPhase({ isShow: true, type: "manage" })
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Icon icon="entypo:flow-branch" style={{ color: "white", width: 15, height: 15 }} />
                                    </div>
                                </Button>{"  "}
                            </>
                            : []
                        }

                        {permission("project", "MPJ-0004") ? // [MPJ-0004] Delete project
                            <>
                                <Popconfirm
                                    title="คุณยืนยันลบหรือไม่ ?"
                                    okText={<span style={{ width: 50 }}>ใช่</span>}
                                    onConfirm={async () => {
                                        await handleProjectDelete(val.projectId)

                                        // reload
                                        await reloadProjectSearch()
                                    }}
                                    cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                >
                                    <Button
                                        danger
                                        type="primary"
                                        style={{
                                            width: 28,
                                            padding: 0
                                        }}
                                        size="small"
                                    >
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 15, height: 15 }} />
                                        </div>
                                    </Button>
                                </Popconfirm>{"  "}
                            </>
                            : null
                        }

                        <Button
                            style={{
                                width: 28,
                                padding: 0,
                                backgroundColor: "#0066ff",
                                border: "1px solid #0066ff",
                                color: 'white'
                            }}
                            onClick={async () => {
                                projectSelected.current = {
                                    projectId: val.projectId,
                                    service: val.serviceId,
                                    name: val.name
                                }
                                formTask.resetFields()

                                // reload
                                await reloadTaskSearch()

                                await getTaskAll()

                                if (val?.projectId) {
                                    const resultProjectPhase = await getProjectPhaseByIdFetch({
                                        projectId: val.projectId,
                                    })
                                    setProjectPhase(resultProjectPhase)
                                    // console.log("resultProjectPhase : ", resultProjectPhase)
                                }

                                setDisplayTaskOperation({
                                    isOpen: false,
                                    type: null,
                                    data: null
                                })

                                window.scroll(0, 0)
                                setModalTask({
                                    isShow: true,
                                    title: permission("project", "MPJ-0005") ? // [MPJ-0005] Title modal manage tasks all in project
                                        ("จัดการ Task ของโปรเจกต์ " + projectSelected.current.name)
                                        :
                                        ("จัดการ Task ของคุณ " + auth.firstname + " ในโปรเจกต์ " + projectSelected.current.name)
                                })
                            }}
                            size="small"
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Icon icon="fluent:data-usage-edit-24-regular" style={{ color: "white", width: 15, height: 15 }} />
                            </div>
                        </Button>{"  "}

                        <Button
                            style={{
                                width: 28,
                                padding: 0,
                                backgroundColor: "#0066ff",
                                border: "1px solid #0066ff",
                                color: 'white'
                            }}
                            onClick={async () => {
                                setModalProjectInfo({
                                    isShow: true,
                                    title: "รายละเอียด Project " + val.name,
                                    data: val
                                })
                            }}
                            size="small"
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Icon icon="fluent:document-data-20-regular" style={{ color: "white", width: 15, height: 15 }} />
                            </div>
                        </Button>
                    </>
            })
        })

        // console.log("search : ", search)
        let fillProject = tempProject
        if (search.workProcessId) {
            fillProject = tempProject.filter(fill => fill.workProcess === search.workProcessId)
        }
        // console.log("fillProject : ", fillProject)
        setProject(fillProject)

        setLoadingProject(false)
    }

    const getProjectPhaseBySearch = async (search) => {
        setLoadingProjectPhase(true)

        const result = await getProjectPhaseSearch(search)
        // console.log("getProjectPhaseSearch : ", result)

        let tempProjectPhase = [];
        result?.map((val, index) => {
            tempProjectPhase.push({
                index: (index + 1),
                name: val?.name ?? '-',
                phase: val?.phase ?? "-",
                projectValue: val?.projectValue ?? "-",
                workProcess: val?.work_process?.name,
                paymentProcess: val?.payment_process?.name ?? "-",
                developmentStart: val?.developmentStart ?? undefined,
                developmentEnd: val?.developmentEnd ?? undefined,
                goLiveDate: val?.goLiveDate ?? undefined,
                operator:
                    <>
                        {permission("project", "MPJ-0006") ? // [MPJ-0006] Edit project type
                            <>
                                <Button
                                    style={{
                                        width: 28,
                                        padding: 0,
                                        backgroundColor: "orange",
                                        border: "1px solid orange",
                                        color: 'white'
                                    }}
                                    onClick={async () => {
                                        let processingPeriod = null
                                        if (val.developmentStart && val.developmentEnd) {
                                            let developmentStart = dayjs(val.developmentStart, formatDateTime)
                                            let developmentEnd = dayjs(val.developmentEnd, formatDateTime)
                                            processingPeriod = [developmentStart, developmentEnd]

                                            let daysDiff = calculateDaysDifference(developmentStart, developmentEnd).days
                                            setProcessingDays(daysDiff)
                                        } else {
                                            setProcessingDays(null)
                                        }

                                        formProjectPhase.setFieldsValue({
                                            projectPhaseId: val.projectPhaseId,
                                            projectId: val.projectId,
                                            workProcess: val.workProcessId,
                                            paymentInstallment: val.paymentInstallmentId,
                                            paymentProcess: val.paymentProcessId,
                                            name: val.name,
                                            phase: val.phase,
                                            projectValue: val.projectValue,
                                            goLiveDate: val.goLiveDate ? dayjs(val.goLiveDate, "YYYY-MM-DD HH:mm:ss") : null,
                                            processingPeriod,
                                            isActive: val.isActive
                                        })
                                        await getPaymentProcessById(val.paymentInstallmentId)

                                        setModalProjectPhase({ isShow: true, type: "edit" })
                                    }}
                                    size="small"
                                >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Icon icon="typcn:edit" style={{ color: "white", width: 15, height: 15 }} />
                                    </div>
                                </Button>{"  "}
                            </>
                            : null
                        }

                        {permission("project", "MPJ-0007") ? // [MPJ-0007] Delete project type
                            <>
                                <Popconfirm
                                    title="คุณยืนยันลบหรือไม่ ?"
                                    okText={<span style={{ width: 50 }}>ใช่</span>}
                                    onConfirm={async () => {
                                        await handleProjectPhaseDelete(val.projectPhaseId)

                                        // reload
                                        await reloadProjectPhaseSearch()
                                    }}
                                    cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                >
                                    <Button
                                        danger
                                        type="primary"
                                        style={{
                                            width: 28,
                                            padding: 0
                                        }}
                                        size="small"
                                    >
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 15, height: 15 }} />
                                        </div>
                                    </Button>
                                </Popconfirm>{"  "}
                            </>
                            : null
                        }
                    </>
            })
        })
        setProjectPhase(tempProjectPhase)

        setLoadingProjectPhase(false)
    }

    const getTaskBySearch = async (search) => {
        console.log("loading ---1 : ", loadingTask)
        // setLoadingTask(true)

        const result = await getTaskSearchFetch(search)
        console.log("getTaskSearchFetch : ", result)

        // -------------------------------------------------------------------------------------------------
        const tempTask = [];
        let sumEmployeeCost = 0;
        let sumOperationCost = 0;
        let sumOperationDiscount = 0;
        let sumEstimateTime = 0;
        let sumProgressPercentage = 0;

        // ใช้ forEach แทน map เพื่อลดการสร้างอาร์เรย์ใหม่
        result?.forEach((val, index) => {
            // รวมค่าต่าง ๆ ในลูปเดียว
            sumEmployeeCost += val.employeeCost || 0;
            sumOperationCost += val.operationCost || 0;
            sumOperationDiscount += val.operationDiscount || 0;
            sumEstimateTime += val.estimateTime || 0;
            sumProgressPercentage += val.progressPercentage || 0;

            // สร้างข้อมูลสำหรับ tempTask
            tempTask.push({
                index: index + 1,
                taskId: val.taskId,
                taskCode: val.taskCode ?? "-",
                key: val.taskId,
                name: val.name ?? "",
                taskProcess: val?.task_process?.name ?? "",
                employeePositionId: val.employeePositionId,
                employeePosition: val?.employee_position?.name ?? "",
                progressPercentage: val.progressPercentage || 0,
                employeeCost: val.employeeCost || 0,
                operationCost: val.operationCost || 0,
                operationDiscount: val.operationDiscount || 0,
                taskEndDate: val.taskEndDate || "",
                ownerId: val.ownerId || "",
                employee: val?.employee?.firstname ?? "-",
                taskProcessId: val.taskProcessId || "",
                taskOpenStatus: val?.task_open_status?.name ?? "",
                taskOpenStatusId: val.taskOpenStatusId || "",
                description: val.description || "",
                isOpenPaidEmployee: val.isOpenPaidEmployee || false,
                parentTaskId: val.parentTaskId || "",
                parentTask: val?.parent_task?.name ?? null,
                estimateTime: val.estimateTime || 0,
                isActive: val.isActive || false,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                createdAtOriginal: val.createdAt || null,
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator: (
                    <>
                        <Button
                            style={{
                                width: 28,
                                padding: 0,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: "white",
                            }}
                            onClick={async () => {
                                const resultById = await getTaskSearchFetch({
                                    taskId: val.taskId,
                                    projectId: val.projectId,
                                });
                                const taskById = resultById[0] || null;

                                await getTaskAll();

                                if (taskById) {
                                    formTask.setFieldsValue({
                                        taskId: taskById.taskId,
                                        projectId: taskById.projectId,
                                        taskProcess: taskById.taskProcessId,
                                        priority: taskById.priorityId,
                                        owner: taskById.ownerId,
                                        employeePosition: taskById.employeePositionId,
                                        name: taskById?.name,
                                        description: taskById.description,
                                        progressPercentage: taskById.progressPercentage,
                                        feedback: taskById.feedback,
                                        taskRange: taskById.taskStartDate && taskById.taskEndDate
                                            ? [
                                                dayjs(taskById.taskStartDate, formatDateTime),
                                                dayjs(taskById.taskEndDate, formatDateTime),
                                            ]
                                            : undefined,
                                        employeeCost: taskById.employeeCost,
                                        operationCost: taskById.operationCost,
                                        operationDiscount: taskById.operationDiscount,
                                        estimateTime: taskById.estimateTime,
                                        isOpenPaidEmployee: taskById.isOpenPaidEmployee,
                                        parentTaskId: taskById.parentTaskId,
                                        projectPhase: taskById.projectPhaseId,
                                        isActive: taskById.isActive,
                                    });

                                    window.scroll(0, 0);
                                    setDisplayTaskOperation({
                                        isOpen: true,
                                        type: "edit",
                                        data: taskById,
                                    });
                                }
                            }}
                            size="small"
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Icon icon="typcn:edit" style={{ color: "white", width: 15, height: 15 }} />
                            </div>
                        </Button>
                        {"  "}

                        {permission("task", "MTK-0002") && (
                            <Popconfirm
                                title="คุณยืนยันลบหรือไม่ ?"
                                okText={<span style={{ width: 50 }}>ใช่</span>}
                                onConfirm={async () => {
                                    await handleTaskDelete(val.taskId);
                                    await reloadTaskSearch();
                                }}
                                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                            >
                                <Button
                                    danger
                                    type="primary"
                                    style={{
                                        width: 28,
                                        padding: 0,
                                    }}
                                    size="small"
                                >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Icon icon="fluent:delete-16-regular" style={{ color: "white", width: 15, height: 15 }} />
                                    </div>
                                </Button>
                            </Popconfirm>
                        )}
                        {"  "}

                        <Button
                            style={{
                                width: 28,
                                padding: 0,
                                backgroundColor: "#0066ff",
                                border: "1px solid #0066ff",
                                color: "white",
                            }}
                            loading={loadingTask}
                            onClick={async () => {
                                const resultById = await getTaskSearchFetch({
                                    taskId: val.taskId,
                                    projectId: val.projectId,
                                });
                                const taskById = resultById[0] || null;

                                if (taskById) {
                                    setModalTaskInfo({
                                        isShow: true,
                                        title: "รายละเอียด Task " + taskById?.name,
                                        data: taskById,
                                    });
                                }
                            }}
                            size="small"
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Icon icon="fluent:document-data-20-regular" style={{ color: "white", width: 15, height: 15 }} />
                            </div>
                        </Button>
                    </>
                ),
            });
        });

        // ตั้งค่าข้อมูลสรุปทั้งหมด
        console.log("X1 *****************************************")
        setTaskSelected({
            employeeCostTotal: sumEmployeeCost,
            operationCostTotal: sumOperationCost,
            operationDiscountTotal: sumOperationDiscount,
            estimateTimeTotal: sumEstimateTime,
            progressPercentageTotal: result?.length ? sumProgressPercentage / result.length : 0,
            taskListTable: tempTask,
            taskListOriginal: result,
        })

        // -------------------------------------------------------------------------------------------------

        // setLoadingTask(false)
    }

    const onFinishProject = async (values) => {
        console.log("loading ---2 : ", loadingTask)
        setLoadingTask(true)

        let obj = {
            projectId: values.projectId ?? null,
            paymentProcessId: values.paymentProcess ?? null,
            paymentInstallmentId: values.paymentInstallment ?? null,
            businessId: values.business ?? null,
            projectTypeId: values.projectType ?? null,
            workProcessId: values.workProcess ?? null,
            serviceId: values.service ?? null,
            customerId: values.customer ?? null,
            packageId: values.package ?? null,
            urlWebsite: values.urlWebsite ?? null,
            urlWebsiteBackoffice: values.urlWebsiteBackoffice ?? null,
            apiDevelopment: values.apiDevelopment ?? null,
            apiProduction: values.apiProduction ?? null,
            domain: values.domain ?? null,
            name: values.name ?? null,
            usernameAdmin: values.usernameAdmin ?? null,
            passwordAdmin: values.passwordAdmin ?? null,
            phase: values.phase ?? null,
            developmentStart: values?.processingPeriod ? values?.processingPeriod[0] : null,
            developmentEnd: values?.processingPeriod ? values?.processingPeriod[1] : null,
            goLiveDate: values.goLiveDate ?? null,
            figmaUrl: values.figmaUrl ?? null,
            gitClientUrl: values.gitClientUrl ?? null,
            gitBackofficeUrl: values.gitBackofficeUrl ?? null,
            gitServerUrl: values.gitServerUrl ?? null,
            projectValue: values.projectValue ?? null,
        }
        // console.log("obj --- : ", obj)

        if (displayProject.type === "add") {
            const result = await insertProjectFetch(obj)
            if (result?.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
                // set default
                setFormDefaultProject()
                setLoadingTask(false)
            } else {
                Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
            }
        } else if (displayProject.type === "edit") {
            const result = await updateProjectByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
                // set default
                setFormDefaultProject()
                setLoadingTask(false)
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        await reloadProjectSearch()
    }

    const onFinishProjectPhase = async (values) => {
        setLoadingProjectPhase(true)

        const originalGoLiveDate = new Date(values.goLiveDate ?? null);
        const updatedGoLiveDate = new Date(originalGoLiveDate?.getTime() + 7 * 60 * 60 * 1000); // บวก 7 ชั่วโมง

        let obj = {
            projectPhaseId: values.projectPhaseId ?? null,
            projectId: values.projectId ?? null,
            workProcessId: values.workProcess ?? null,
            paymentInstallmentId: values.paymentInstallment ?? null,
            paymentProcessId: values.paymentProcess ?? null,
            name: values.name ?? null,
            phase: values.phase ?? null,
            projectValue: values.projectValue ?? null,
            goLiveDate: values.goLiveDate ? updatedGoLiveDate : null,
            developmentStart: values?.processingPeriod ? values?.processingPeriod[0] : null,
            developmentEnd: values?.processingPeriod ? values?.processingPeriod[1] : null
        }
        // console.log("obj --- : ", obj)

        if (modalProjectPhase.type === "add") {
            const result = await insertProjectPhaseFetch(obj)
            if (result?.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
                setLoadingProjectPhase(false)
            } else {
                Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
            }
        } else if (modalProjectPhase.type === "edit") {
            const result = await updateProjectPhaseByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
                setLoadingProjectPhase(false)
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        setModalProjectPhase({ ...modalProjectPhase, type: "manage" })
        // reload
        await reloadProjectPhaseSearch()
        await reloadProjectSearch()
    }

    const onFinishTask = async (values) => {
        let obj = {
            taskId: values.taskId ?? null,
            projectId: projectSelected.current.projectId ?? null,
            taskProcessId: values.taskProcess ?? null,
            priorityId: values.priority ?? null,
            ownerId: (permission("task", "MTK-0001") ? values.owner : auth.employeeId) ?? null, // [MTK-0001] assign tasks to any employees
            employeePositionId: (permission("task", "MTK-0001") ? values.employeePosition : auth.employeePositionId) ?? null, // [MTK-0001] assign tasks to any employees
            ownerCreateId: auth.employeeId ?? null,
            name: values.name ?? null,
            description: values.description ?? null,
            progressPercentage: values.progressPercentage ?? null,
            feedback: (permission("task", "MTK-0001") ? values.feedback : null) ?? null, // [MTK-0001] assign tasks to any employees
            taskStartDate: values.taskRange ? values.taskRange[0] : null,
            taskEndDate: values.taskRange ? values.taskRange[1] : null,
            employeeCost: (permission("task", "MTK-0001") ? values.employeeCost : 0) ?? null, // [MTK-0001] assign tasks to any employees
            operationCost: (permission("task", "MTK-0001") ? values.operationCost : 0) ?? null, // [MTK-0001] assign tasks to any employees
            operationDiscount: (permission("task", "MTK-0001") ? values.operationDiscount : 0) ?? null, // [MTK-0001] assign tasks to any employees
            projectPhaseId: values.projectPhase,
            estimateTime: values.estimateTime ?? null,
            parentTaskId: values.parentTaskId ?? null
        }

        if (displayTaskOperation.type === "add") {
            const result = await insertTaskFetch(obj)
            if (result?.isSuccess) {
                Notification('success', 'สร้างสำเร็จ')
                // set default
                setDisplayTaskOperation({
                    isOpen: false,
                    type: null,
                    data: null
                })
                formTask.resetFields()
            } else {
                Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
            }
        } else if (displayTaskOperation.type === "edit") {
            const result = await updateTaskByIdFetch(obj)
            if (result.isSuccess) {
                Notification('success', 'เเก้ไขสำเร็จ')
                // set default
                setDisplayTaskOperation({
                    isOpen: false,
                    type: null,
                    data: null
                })
                formTask.resetFields()
            } else {
                Notification('error', 'ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง')
            }
        }

        // reload
        await reloadTaskSearch()
        await getTaskAll()
    }

    const onCreateTaskFast = async (values) => {
        console.log("loading ---3 : ", loadingTask)
        setLoadingTask(true)
        let obj = {
            taskId: null,
            projectId: projectSelected.current.projectId ?? null,
            taskProcessId: 1, // Dafault pending
            priorityId: null,
            ownerId: (permission("task", "MTK-0001") ? null : auth.employeeId), // [MTK-0001] assign tasks to any employees
            employeePositionId: (permission("task", "MTK-0001") ? null : auth.employeePositionId), // [MTK-0001] assign tasks to any employees
            ownerCreateId: auth.employeeId ?? null,
            name: null,
            description: null,
            progressPercentage: 0,
            feedback: null,
            taskStartDate: null,
            taskEndDate: null,
            employeeCost: 0,
            operationCost: 0,
            operationDiscount: 0,
            estimateTime: 0,
            parentTaskId: null,
            projectPhaseId: (projectPhase?.length > 0 ? projectPhase[projectPhase?.length - 1]?.projectPhaseId : undefined)
        }

        const result = await insertTaskFetch(obj)
        if (result?.isSuccess) {
            // Notification('success', 'สร้างสำเร็จ')
            setLoadingTask(false)

            // reload
            await reloadTaskSearch()
        } else {
            Notification('error', 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
        }
    }

    const onReasonsInspectTaskFailureFinish = async (values) => {
        const obj = {
            reasonsInspectTaskFailure: values?.reason ?? null,
            amountTaskFailure: values?.amountFailure ?? 0,
            taskId: modalReasonsInspectTaskFailure?.taskId ?? null
        }
        // console.log("obj --- : ", obj)

        const result = await updateReasonsInspectTaskFailureByIdFetch(obj)
        if (result.isSuccess) {
            Notification('success', 'บันทึกเหตุผลที่ไม่ผ่านสำเร็จ')
            await handleUpdateTaskProcessById(
                modalReasonsInspectTaskFailure.taskProcessId,
                modalReasonsInspectTaskFailure.record
            )

            setModalReasonsInspectTaskFailure({
                isShow: false,
                title: null,
                dataUpdateTaskProcess: null,
                taskId: null,
                record: null,
                taskProcessId: null
            })
        } else {
            Notification('error', 'ไม่สามารถบันทึกเหตุผลที่ไม่ผ่านได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleReasonsInspectTaskFailure = async (taskProcessId, record) => {
        formReasonsInspectTaskFailure.resetFields();

        const obj = [
            {
                taskProcessId,
                taskId: record.taskId
            }
        ];

        // ใช้ Promise.all เพื่อดึงข้อมูลทั้งหมดพร้อมกัน
        const [resultRICF] = await Promise.all([
            getReasonsInspectTaskFailureByIdFetch({ taskId: record.taskId }),
            // สามารถเรียก API อื่นๆ ที่จำเป็นในฟังก์ชันนี้พร้อมกันที่นี่
        ]);

        // ถ้า taskProcessId เป็น 6 จะดำเนินการตามนี้
        if (taskProcessId === 6) {
            setReasonsInspectTaskFailureImageURL({
                loading: false,
                imageUrl: resultRICF.reasonsInspectTaskFailureImageUrl,
                taskId: record.taskId
            });

            setReasonsInspectTaskFailureVideoURL({
                loading: false,
                videoUrl: resultRICF.reasonsInspectTaskFailureVideoUrl,
                taskId: record.taskId
            });

            setReasonsInspectTaskFailureFileURL({
                loading: false,
                fileUrl: resultRICF.reasonsInspectTaskFailureFileUrl,
                taskId: record.taskId
            });

            formReasonsInspectTaskFailure.setFieldsValue({
                reason: resultRICF.reasonsInspectTaskFailure,
                amountFailure: resultRICF.amountTaskFailure ?? 0
            });

            setModalReasonsInspectTaskFailure({
                isShow: true,
                title: "เหตุผลที่ " + record.name + " ไม่ผ่าน",
                dataUpdateTaskProcess: obj,
                taskId: record.taskId,
                record,
                taskProcessId
            });
        } else {
            await handleUpdateTaskProcessById(taskProcessId, record);

            // ทำการลบข้อมูลเก่า ถ้ามี
            if (
                resultRICF.reasonsInspectTaskFailure ||
                resultRICF.reasonsInspectTaskFailureImageUrl ||
                resultRICF.reasonsInspectTaskFailureVideoUrl ||
                resultRICF.reasonsInspectTaskFailureFileUrl
            ) {
                const deletePromises = [
                    deleteReasonsInspectTaskFailureByIdFetch(record?.taskId ?? null),
                    deleteReasonsInspectTaskFailureImageByIdFetch(record?.taskId ?? null),
                    deleteReasonsInspectTaskFailureVideoByIdFetch(record?.taskId ?? null),
                    deleteReasonsInspectTaskFailureFileByIdFetch(record?.taskId ?? null)
                ];

                const [resultText, resultImage, resultVideo, resultFile] = await Promise.all(deletePromises);

                if (
                    resultText.isSuccess &&
                    resultImage.isSuccess &&
                    resultVideo.isSuccess &&
                    resultFile.isSuccess
                ) {
                    Notification('success', 'เคียร์เหตุผลที่ไม่ผ่านสำเร็จ');
                } else {
                    Notification('error', 'ไม่สามารถเคียร์เหตุผลที่ไม่ผ่านได้ กรุณาลองใหม่อีกครั้ง');
                }
            }
        }
    }

    const projectSearchRef = useRef({
        name: null,
        projectCode: null,
        workProcessId: null,
        businessId: null,
        serviceId: null,
    })

    const onSearchProjectFinish = async (values) => {
        let name = values?.name ?? null
        let projectCode = values?.projectCode ?? null
        let workProcessId = values?.workProcess ?? null
        let businessId = values?.business ?? null
        let serviceId = values?.service ?? null
        // let startedDate = values?.dateRange ? values.dateRange[0] : null
        // let endDate = values?.dateRange ? values.dateRange[1] : null        
        // let paymentProcessId = values?.paymentProcess ?? null       

        projectSearchRef.current = {
            name,
            projectCode,
            workProcessId,
            businessId,
            serviceId
        }

        let objSearch = {
            name,
            projectCode,
            workProcessId,
            businessId,
            serviceId,
            // projectTypeId: null,            
            // customerId: null,
            // paymentProcessId,            
            // startedDate,
            // endDate,
        }
        await getProjectBySearch(objSearch)
    }

    const onSearchTaskFinish = async (values) => {
        let name = values?.name ?? null
        let taskOpenStatusId = values?.taskOpenStatus ?? null
        let ownerId = values?.owner ?? null
        let employeePositionId = values?.employeePosition ?? null
        let taskProcessId = values?.taskProcess ?? null
        let projectPhaseId = values?.projectPhase ?? null
        let startedDate = values?.dateRange ? values.dateRange[0] : null
        let endDate = values?.dateRange ? values.dateRange[1] : null

        let objSearch = {
            name,
            projectId: projectSelected.current.projectId,
            taskProcessId,
            priorityId: null,
            ownerId,
            employeePositionId,
            taskOpenStatusId,
            projectPhaseId,
            startedDate,
            endDate,
        }

        taskSearchRef.current = {
            ownerId,
            taskName: name,
            taskOpenStatusId,
            employeePositionId,
            projectPhaseId,
            startedDate,
            endDate
        }

        await getTaskBySearch(objSearch)
    }

    const reloadProjectSearch = async () => {
        await getProjectBySearch({
            name: projectSearchRef.current.name,
            projectCode: projectSearchRef.current.projectCode,
            workProcessId: projectSearchRef.current.workProcessId,
            businessId: projectSearchRef.current.businessId,
            serviceId: projectSearchRef.current.serviceId,
        })
    }

    const reloadProjectPhaseSearch = async () => {
        await getProjectPhaseBySearch({
            projectId: projectSelected.current.projectId
        })
    }

    const reloadTaskSearch = async () => {
        await getTaskBySearch({
            name: taskSearchRef.current.taskName,
            projectId: projectSelected.current.projectId,
            taskProcessId: null,
            priorityId: null,
            ownerId: (permission("task", "MTK-0003") ? taskSearchRef.current.ownerId : auth.employeeId), // [MTK-0003] Search for a list of tasks for any employee
            employeePositionId: taskSearchRef.current.employeePositionId,
            taskOpenStatusId: taskSearchRef.current.taskOpenStatusId,
            projectPhaseId: taskSearchRef.current.projectPhaseId,
            startedDate: taskSearchRef.current.startedDate,
            endDate: taskSearchRef.current.endDate
        })
    }

    const handleProjectDelete = async (id) => {
        const result = await deleteProjectByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleProjectPhaseDelete = async (id) => {
        const result = await deleteProjectPhaseByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleTaskDelete = async (id) => {
        const result = await deleteTaskByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบสำเร็จ')
        } else {
            Notification('error', 'ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleReasonsInspectTaskFailureImageDelete = async (id) => {
        const result = await deleteReasonsInspectTaskFailureImageByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบรูปภาพสำเร็จ')

            setReasonsInspectTaskFailureImageURL(prev => ({
                ...prev,
                loading: false,
                imageUrl: null
            }))
        } else {
            Notification('error', 'ไม่สามารถลบรูปภาพได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleReasonsInspectTaskFailureVideoDelete = async (id) => {
        const result = await deleteReasonsInspectTaskFailureVideoByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบวิดีโอสำเร็จ')

            setReasonsInspectTaskFailureVideoURL(prev => ({
                ...prev,
                loading: false,
                videoUrl: null
            }))
        } else {
            Notification('error', 'ไม่สามารถลบวิดีโอได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleReasonsInspectTaskFailureFileDelete = async (id) => {
        const result = await deleteReasonsInspectTaskFailureFileByIdFetch(id)
        if (result.isSuccess) {
            Notification('success', 'ลบวิดีโอสำเร็จ')

            setReasonsInspectTaskFailureFileURL(prev => ({
                ...prev,
                loading: false,
                fileUrl: null,
                id: reasonsInspectTaskFailureImageURL.taskId
            }))
        } else {
            Notification('error', 'ไม่สามารถลบวิดีโอได้ กรุณาลองใหม่อีกครั้ง')
        }
    }

    const handleUpdateTaskProcessById = async (taskProcessId, record) => {
        let obj = {
            taskProcessId,
            taskId: record.taskId,
            progressPercentage: record.progressPercentage
        }

        await updateTaskProcessByIdFetch(obj)

        let taskListTable = taskSelected.taskListTable.map(item => item.taskId === record.taskId ? {
            ...item,
            taskProcessId: taskProcessId,
            progressPercentage: (taskProcessId === 4 ? 100 : record.progressPercentage)
        } : item)

        let taskListOriginal = taskSelected.taskListOriginal.map(item => item.taskId === record.taskId ? {
            ...item,
            taskProcessId: taskProcessId,
            progressPercentage: (taskProcessId === 4 ? 100 : record.progressPercentage)
        } : item)

        reloadTaskOffline(taskListTable, taskListOriginal)
    }

    const onPagineProject = (n) => {
        setPageCurrentProject(n.current)
    }

    const onPagineProjectPhase = (n) => {
        setPageCurrentProjectPhase(n.current)
    }

    const setFormDefaultProject = () => {
        formProject.resetFields()
        setDisplayProject({
            isOpen: false,
            type: null
        })
    }

    const setFormDefaultProjectPhase = () => {
        formProjectPhase.resetFields()
        setModalProjectPhase({ isShow: false, type: null })
    }

    const setFormDefaultTask = () => {
        setModalTask({
            isShow: false,
            title: null,
        })
        formTask.resetFields()
    }

    const getPaymentProcessById = async (paymentInstallmentId) => {
        const obj = {
            paymentInstallmentId
        }
        const result = await getPaymentProcessByIdFetch(obj)
        // console.log("getPaymentProcessByIdFetch : ", result)
        setPaymentProcess(result)
    }

    const getBusinessAll = async () => {
        const result = await getBusinessAllFetch()
        // console.log("getBusinessAllFetch : ", result)
        setBusiness(result)
    }

    const getCustomerAll = async () => {
        const result = await getCustomerAllFetch()
        // console.log("getCustomerAllFetch : ", result)
        setCustomer(result)
    }

    const getPaymentInstallmentAll = async () => {
        const result = await getPaymentInstallmentAllFetch()
        // console.log("getPaymentInstallmentAllFetch : ", result)
        setPaymentInstallment(result)
    }

    const getPriorityAll = async () => {
        const result = await getPriorityAllFetch()
        // console.log("getPriorityAllFetch : ", result)
        setPriority(result)
    }

    const getTaskProcessAll = async () => {
        let obj = {
            isPermitAll: permission("task", "MTK-0004") ? true : false // [MTK-0004] Show all task process list
        }
        const result = await getTaskProcessAllFetch(obj)
        // console.log("getTaskProcessAllFetch : ", result)
        setTaskProcess(result)
    }

    const getEmployeePositionAll = async () => {
        let obj = {
            employeeDepartmentId: null
        }
        const result = await getEmployeePositionAllFetch(obj)
        // console.log("getEmployeePositionAllFetch : ", result)
        setEmployeePosition(result)
    }

    const getEmployeeAll = async () => {
        const result = await getEmployeeAllFetch()
        // console.log("getEmployeeAllFetch : ", result)
        setEmployee(result)
    }

    const getTaskOpenStatusAll = async () => {
        const result = await getTaskOpenStatusAllFetch()
        // console.log("getTaskOpenStatusAllFetch : ", result)
        setTaskOpenStatus(result)
    }

    const getTaskAll = async () => {
        let obj = {
            projectId: projectSelected.current.projectId,
            ownerId: (permission("task", "MTK-0005") ? taskSearchRef.current.ownerId : auth.employeeId) // [MTK-0005] Show all task details
        }
        const result = await getTaskAllFetch(obj)
        // console.log("obj getTaskAllFetch --- : ", obj)
        // console.log("getTaskAllFetch ------- : ", result)
        // console.log("-----------------------")

        setTaskOption(result)
    }

    const getServiceAll = async () => {
        const result = await getServiceAllFetch()
        // console.log("getServiceAllFetch : ", result)
        setService(result)
    }

    const getProjectTypeById = async (serviceId) => {
        const obj = {
            serviceId
        }
        const result = await getProjectTypeByIdFetch(obj)
        // console.log("getProjectTypeByIdFetch : ", result)
        setProjectType(result)
    }

    const getPackageById = async (serviceId) => {
        const obj = {
            serviceId
        }
        const result = await getPackageByIdFetch(obj)
        // console.log("getPackageByIdFetch : ", result)
        setPackages(result)
    }

    const getWorkProcessById = async (serviceId) => {
        const obj = {
            serviceId
        }
        const result = await getWorkProcessByIdFetch(obj)
        // console.log("getWorkProcessByIdFetch : ", result)
        setWorkProcess(result)
    }

    const getBaseApi = async () => {
        // PROJECT
        await getPaymentProcessById(null)
        await getBusinessAll()
        await getCustomerAll()
        await getPaymentInstallmentAll()
        await getServiceAll()
        // await getPackageById(null)
        // await getProjectTypeById(null)

        // TASK
        await getPriorityAll()
        await getTaskProcessAll()
        await getEmployeePositionAll()
        await getEmployeeAll()
        await getTaskOpenStatusAll()
        // await getTaskAll()
    }

    useEffect(() => {
        // reload
        reloadProjectSearch()

        getBaseApi()
    }, [])

    const reloadTaskOffline = (taskListTable, taskListOriginal) => {
        const calculateTotal = (key) =>
            taskListOriginal.reduce((acc, obj) => acc + (parseFloat(obj[key]) || 0), 0);

        const employeeCostTotal = calculateTotal("employeeCost");
        const operationCostTotal = calculateTotal("operationCost");
        const operationDiscountTotal = calculateTotal("operationDiscount");
        const estimateTimeTotal = calculateTotal("estimateTime");
        const progressPercentageTotal =
            taskListOriginal.length > 0
                ? calculateTotal("progressPercentage") / taskListOriginal.length
                : 0;

        setTaskSelected({
            employeeCostTotal,
            operationCostTotal,
            operationDiscountTotal,
            estimateTimeTotal,
            progressPercentageTotal,
            taskListTable,
            taskListOriginal,
        });
    };

    const [isOpenEmployeeCostTotal, setIsOpenEmployeeCostTotal] = useState(false)
    const [isOpenOperationCostTotal, setIsOpenOperationCostTotal] = useState(false)
    const [isOpenOperationDiscountTotal, setIsOpenOperationDiscountTotal] = useState(false)
    const [isOpenEstimateTimeTotal, setIsOpenEstimateTimeTotal] = useState(false)
    const [isOpenProgressPercentageTotal, setIsOpenProgressPercentageTotal] = useState(false)

    const sizeTaskName = () => {
        let size = (permission("task", "MTK-0006") ? 9 : 19) // [MTK-0006] Shows all task columns available for display

        if (!isOpenEmployeeCostTotal && permission("task", "MTK-0006")) { // [MTK-0006] Shows all task columns available for display
            size += 6
        }

        if (!isOpenOperationCostTotal && permission("task", "MTK-0006")) { // [MTK-0006] Shows all task columns available for display
            size += 6
        }

        if (!isOpenOperationDiscountTotal && permission("task", "MTK-0006")) { // [MTK-0006] Shows all task columns available for display
            size += 6
        }

        if (!isOpenProgressPercentageTotal) {
            size += 6
        }

        if (!isOpenEstimateTimeTotal) {
            size += 9
        }



        return String(size) + "%"
    }

    // -------------------------------------------------------------------------

    const [heightScreen, setHeightScreen] = useState(window.innerHeight * 0.495)

    useEffect(() => {
        // ฟังก์ชันเพื่ออัปเดตความสูงเมื่อมีการเปลี่ยนแปลงขนาดหน้าจอ
        const handleResize = () => {
            setHeightScreen(window.innerHeight * 0.65)
        }

        // เพิ่ม event listener เมื่อหน้าจอถูก resize
        window.addEventListener('resize', handleResize)

        // ลบ event listener เมื่อคอมโพเนนต์นี้ถูกทำลาย
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    // -------------------------------------------------------------------------

    const textareaRefs = useRef({});

    const setRef = useCallback((el, taskId) => {
        if (el) {
            textareaRefs.current[taskId] = el;
            requestAnimationFrame(() => {
                el.style.height = "24px"; // ความสูงเริ่มต้นสำหรับ 1 แถว
                if (el.scrollHeight > 24) {
                    el.style.height = `${el.scrollHeight}px`; // ปรับขนาดตามเนื้อหา
                }
            });
        }
    }, []);

    useEffect(() => {
        // ล้าง refs ที่ไม่ได้ใช้งาน
        return () => {
            textareaRefs.current = {};
        };
    }, []);

    const handleBlur = async (e, record) => {
        if (record.name !== e.target.value) {
            await updateTaskNameByIdFetch({
                name: e.target.value,
                taskId: record.taskId,
            });

            const taskListTable = taskSelected.taskListTable.map(item =>
                item.taskId === record.taskId
                    ? { ...item, name: e.target.value }
                    : item
            );

            const taskListOriginal = taskSelected.taskListOriginal.map(item =>
                item.taskId === record.taskId
                    ? { ...item, name: e.target.value }
                    : item
            );

            reloadTaskOffline(taskListTable, taskListOriginal);
        }
    };

    const handleChange = debounce((e, taskId) => {
        const target = textareaRefs.current[taskId];
        if (target) {
            target.style.height = "24px"; // รีเซ็ตความสูงเริ่มต้น
            target.style.height = `${target.scrollHeight}px`; // ปรับขนาดตามเนื้อหา
        }
    }, 100);

    const genTaskNameChange = (tags, record) => (
        <textarea
            ref={el => setRef(el, record.taskId)}
            className="task-name-edit"
            defaultValue={tags}
            disabled={formTask.getFieldValue()?.isOpenPaidEmployee}
            onChange={e => handleChange(e, record.taskId)}
            onBlur={e => handleBlur(e, record)}
        />
    );

    // -------------------------------------------------------------------------

    // const [currentTime, setCurrentTime] = useState(new Date())

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         setCurrentTime(new Date())
    //     }, 1000) // Update every second

    //     return () => clearInterval(intervalId) // Cleanup the interval on component unmount
    // }, [currentTime])

    return (
        <>
            <Row gutter={[0, 0]}>
                <Col span={6}>
                    <label>จัดการโปรเจกต์</label>
                </Col>

                <Col span={18}>
                    {!displayProject.isOpen ?
                        <Form form={formSearchProject} layout="vertical" onFinish={onSearchProjectFinish}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <div style={{ paddingLeft: 10 }}>
                                    <Form.Item name="projectCode">
                                        <Input placeholder="Code" style={{ width: 90 }} />
                                    </Form.Item>
                                </div>

                                <div style={{ paddingLeft: 10 }}>
                                    <Form.Item name="name">
                                        <Input placeholder="ชื่อโปรเจกต์" style={{ width: 150 }} />
                                    </Form.Item>
                                </div>

                                <div style={{ paddingLeft: 10 }}>
                                    <Form.Item name="business">
                                        <Select
                                            showSearch
                                            style={{ width: 190 }}
                                            optionFilterProp="children"
                                            placeholder="ธุรกิจ"
                                            allowClear
                                        >
                                            {business?.map((item, index) => (
                                                <Option key={index} value={item.businessId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div style={{ paddingLeft: 10 }}>
                                    <Form.Item name="service">
                                        <Select
                                            showSearch
                                            style={{ width: 190 }}
                                            placeholder="ประเภทบริการ"
                                            optionFilterProp="children"
                                            allowClear
                                            onChange={async (e) => {
                                                await getWorkProcessById(e)
                                                projectSearchRef.current.serviceId = e
                                            }}
                                        >
                                            {service?.map((item, index) => (
                                                <Option key={index} value={item.serviceId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div style={{ paddingLeft: 10 }}>
                                    <Form.Item name="workProcess">
                                        <Select
                                            showSearch
                                            style={{ width: 190 }}
                                            placeholder="Work process"
                                            optionFilterProp="children"
                                            allowClear
                                            disabled={!projectSearchRef.current.serviceId}
                                        >
                                            {workProcess?.map((item, index) => (
                                                <Option key={index} value={item.workProcessId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div style={{ paddingLeft: 10, marginTop: -24 }}>
                                    <Button
                                        style={{ float: 'right' }}
                                        type="primary"
                                        onClick={() => formSearchProject.submit()}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Icon icon="tdesign:search" />
                                        </div>
                                    </Button>
                                </div>


                                <div style={{ paddingLeft: 10, marginTop: -24 }}>
                                    <Button
                                        type="primary"
                                        style={{ float: 'right' }}
                                        onClick={() => {
                                            formProject.resetFields()
                                            setDisplayProject({
                                                isOpen: true,
                                                type: "add"
                                            })
                                        }}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Icon icon="mingcute:add-fill" />
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </Form>
                        :
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <div style={{ paddingLeft: 10, marginTop: 3 }}>
                                <Button
                                    type="default"
                                    onClick={() => {
                                        formProject.resetFields()
                                        setDisplayProject({
                                            isOpen: false,
                                            type: null
                                        })
                                    }}
                                >ยกเลิก</Button>
                            </div>

                            <div style={{ paddingLeft: 10, marginTop: 3 }}>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        formProject.submit()
                                    }}
                                >บันทึก</Button>
                            </div>

                            {displayProject.type === "edit" ?
                                <div style={{ paddingLeft: 10, marginTop: 3 }}>
                                    <Button
                                        type="primary"
                                        style={{ float: 'right' }}
                                        onClick={async () => {
                                            formTask.resetFields()

                                            // reload
                                            await reloadTaskSearch()

                                            setModalTask({
                                                isShow: true,
                                                title: "จัดการ Task ของโปรเจกต์ " + projectSelected.current.name
                                            })
                                        }}
                                    >จัดการ Task</Button>
                                </div>
                                : []
                            }

                            {displayProject.type === "edit" ?
                                <div style={{ paddingLeft: 10, marginTop: 3 }}>
                                    <Button
                                        type="primary"
                                        style={{ float: 'right' }}
                                        onClick={async () => {
                                            // reload 
                                            await reloadProjectPhaseSearch()

                                            setModalProjectPhase({ isShow: true, type: "manage" })
                                        }}
                                    >จัดการ Phase</Button>
                                </div>
                                : []
                            }
                        </div>
                    }
                </Col>

                {!displayProject.isOpen ?
                    <Col span={24}>
                        <Table
                            loading={loadingProject}
                            dataSource={project}
                            pagination={{
                                current: pageCurrentProject,
                                pageSize: 10,
                                total: project.length
                            }}
                            onChange={(n) => onPagineProject(n)}
                            rowClassName={(record, index) => {
                                // console.log("record.workProcess : ", record.workProcess)
                                if ([1, 7].includes(record.workProcess)) { // Recive requirement, Analyze strategy
                                    return 'highlight-row-project-recive-requirement'
                                } else if (record.workProcess === 2) { // Design
                                    return 'highlight-row-project-design'
                                } else if ([3, 8].includes(record.workProcess)) { // Coding, Execute campaign
                                    return 'highlight-row-project-coding'
                                } else if (record.workProcess === 4) { // Test
                                    return 'highlight-row-project-test'
                                } else if ([5, 9].includes(record.workProcess)) { // Done, Launch ads
                                    return 'highlight-row-project-done'
                                } else {
                                    return ""
                                }
                            }}
                        >
                            <Column title="No." dataIndex="index" key="index" width={"3%"} align={'center'} />
                            <Column title="Code" dataIndex="projectCode" key="projectCode" width={"8%"} align={'center'} />
                            <Column title="ชื่อโปรเจกต์" dataIndex="name" key="name" width={"26%"} />
                            <Column title="ธุรกิจ" dataIndex="business" key="business" width={"22%"} />
                            {/* service */}
                            <Column title="บริการ" dataIndex="service" key="service" width={"13%"} />
                            <Column
                                title="สถานะใช้งาน"
                                dataIndex="isActive"
                                key="isActive"
                                width={"8%"}
                                render={(tags, record) => (
                                    <>
                                        {tags ? "เปิดใช้งาน" : "ปิดใช้งาน"}
                                    </>
                                )}
                            />
                            <Column title={<FaCog />} dataIndex="operator" key="operator" width={"14%"} align={'center'} />
                        </Table>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ display: "flex", paddingRight: 16 }}>
                                <div style={{ height: 20, width: 20, backgroundColor: "#eed2ff", border: "1px solid black" }}></div>
                                <label style={{ paddingLeft: 8 }}>Recive requirement, Analyze strategy</label>
                            </div>

                            <div style={{ display: "flex", paddingRight: 16 }}>
                                <div style={{ height: 20, width: 20, backgroundColor: "#fff9d2", border: "1px solid black" }}></div>
                                <label style={{ paddingLeft: 8 }}>Design</label>
                            </div>

                            <div style={{ display: "flex", paddingRight: 16 }}>
                                <div style={{ height: 20, width: 20, backgroundColor: "#d2f3ff", border: "1px solid black" }}></div>
                                <label style={{ paddingLeft: 8 }}>Coding, Execute campaign</label>
                            </div>

                            <div style={{ display: "flex", paddingRight: 16 }}>
                                <div style={{ height: 20, width: 20, backgroundColor: "#ffddd2", border: "1px solid black" }}></div>
                                <label style={{ paddingLeft: 8 }}>Test</label>
                            </div>

                            <div style={{ display: "flex", paddingRight: 16 }}>
                                <div style={{ height: 20, width: 20, backgroundColor: "#d4ffd2", border: "1px solid black" }}></div>
                                <label style={{ paddingLeft: 8 }}>Done, Launch ads</label>
                            </div>
                        </div>
                    </Col>
                    : []
                }

                {displayProject.isOpen ?
                    <>
                        <Col span={24} style={{ paddingTop: 12 }}>
                            <div style={{ maxHeight: 480, overflowY: 'scroll', overflowX: "hidden" }}>
                                <div style={{ backgroundColor: "#EEEEEE", padding: 12, borderRadius: 6 }}>
                                    <Form
                                        layout="vertical"
                                        form={formProject}
                                        onFinish={onFinishProject}
                                    >
                                        <Row gutter={[24, 0]}>
                                            <Col span={24}>
                                                <Form.Item name="projectId" style={{ display: "none" }}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item
                                                    name="name"
                                                    label="ชื่อโปรเจกต์"
                                                    rules={[{ required: true, message: 'กรุณากรอกชื่อโปรเจกต์' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col span={6}>
                                                <Form.Item
                                                    name="service"
                                                    label="ประเภทบริการ"
                                                    rules={[{ required: true, message: 'กรุณาเลือกประเภทบริการ' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: "100%" }}
                                                        optionFilterProp="children"
                                                        allowClear
                                                        onChange={async (e) => {
                                                            // console.log("e --- : ", e)
                                                            await getProjectTypeById(e)
                                                            await getPackageById(e)
                                                            formProject.setFieldValue("projectType", undefined, true)
                                                            formProject.setFieldValue("package", undefined, true)
                                                        }}
                                                    >
                                                        {service?.map((item, index) => (
                                                            <Option key={index} value={item.serviceId}>{item.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={6}>
                                                <Form.Item
                                                    name="projectType"
                                                    label="ประเภทโปรเจกต์"
                                                    rules={[{ required: true, message: 'กรุณาเลือกประเภทโปรเจกต์' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        optionFilterProp="children"
                                                        allowClear
                                                        disabled={!formProject.getFieldValue().service}
                                                    >
                                                        {projectType?.map((item, index) => (
                                                            <Option key={index} value={item.projectTypeId}>{item.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={6}>
                                                <Form.Item
                                                    name="package"
                                                    label="Package"
                                                    rules={[{ required: true, message: 'กรุณาเลือก Package' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        optionFilterProp="children"
                                                        allowClear
                                                        disabled={!formProject.getFieldValue().service}
                                                    >
                                                        {packages?.map((item, index) => (
                                                            <Option key={index} value={item.packageId}>{item.name} {item.package_size.name} </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={6}>
                                                <Form.Item
                                                    name="business"
                                                    label="ธุรกิจ"
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        optionFilterProp="children"
                                                        allowClear
                                                    >
                                                        {business?.map((item, index) => (
                                                            <Option key={index} value={item.businessId}>{item.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={6}>
                                                <Form.Item
                                                    name="customer"
                                                    label="ลูกค้า"
                                                    rules={[{ required: true, message: 'กรุณาเลือกลูกค้า' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        optionFilterProp="children"
                                                        allowClear
                                                    >
                                                        {customer?.map((item, index) => (
                                                            <Option key={index} value={item.customerId}>{item.nickname}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={6}>
                                                <Form.Item
                                                    name="isActive"
                                                    label="สถานะการใช้งาน"
                                                    rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        optionFilterProp="children"
                                                        allowClear
                                                    >
                                                        <Option key={0} value={true}>เปิดการใช้งาน</Option>
                                                        <Option key={1} value={false}>ปิดการใช้งาน</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            {formProject.getFieldValue().service === 1 ?
                                                <>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="urlWebsite"
                                                            label="ลิงค์เว็บไซต์"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="urlWebsiteBackoffice"
                                                            label="เว็บไซต์ Backoffice"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="apiDevelopment"
                                                            label="API Development"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="apiProduction"
                                                            label="API Production"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="domain"
                                                            label="ชื่อเว็บไซต์"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="figmaUrl"
                                                            label="ลิงค์ Figma"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="gitClientUrl"
                                                            label="Git Client (URL)"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="gitBackofficeUrl"
                                                            label="Git Backoffice (URL)"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={12}>
                                                        <Form.Item
                                                            name="gitServerUrl"
                                                            label="Git Server (URL)"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="usernameAdmin"
                                                            label="Username Backoffice"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={6}>
                                                        <Form.Item
                                                            name="passwordAdmin"
                                                            label="Password Backoffice"
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                                : []
                                            }
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </>
                    : []
                }
            </Row>

            <Modal
                title={<strong><label className="topic-color-bold">{modalTask.title}</label></strong>}
                open={modalTask.isShow}
                width={"100%"}
                style={{
                    top: 8,
                    padding: 0
                }}
                onCancel={() => {
                    // default
                    setFormDefaultTask()
                }}
                footer={null}
            >
                <Row gutter={[0, 0]}>
                    <Col span={4} style={{ borderBottom: "1px solid black" }}>
                        {displayTaskOperation.isOpen ?
                            <div style={{ display: "flex", marginTop: 32, backgroundColor: "#EEEEEE", padding: 12, borderTopLeftRadius: 6, borderTopRightRadius: 6 }}>
                                <div style={{}}>
                                    <Button
                                        type="default"
                                        onClick={() => {
                                            formTask.resetFields()
                                            setDisplayTaskOperation({
                                                isOpen: false,
                                                type: null,
                                                data: null
                                            })

                                        }}
                                    >ยกเลิก</Button>
                                </div>

                                <div style={{ paddingLeft: 10 }}>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            formTask.submit()

                                        }}
                                    >บันทึก</Button>
                                </div>
                            </div>
                            : []
                        }
                    </Col>

                    <Col span={20} style={{ borderBottom: "1px solid black" }}>
                        <Form form={formSearchTask} layout="vertical" onFinish={onSearchTaskFinish}>
                            <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-end" }}>
                                <div style={{ paddingLeft: 10 }}>
                                    <Form.Item name="name" style={{ width: 200 }}>
                                        <Input placeholder="ชื่อ Task" />
                                    </Form.Item>
                                </div>

                                {!displayTaskOperation.isOpen && permission("task", "MTK-0008") ? // [MTK-0008] Show dropdown to select task owner
                                    <div style={{ paddingLeft: 10 }}>
                                        <Form.Item name="owner">
                                            <Select
                                                showSearch
                                                style={{ width: 150, border: "2px solid #429321", borderRadius: 8 }}
                                                optionFilterProp="children"
                                                allowClear
                                                placeholder="ผู้รับผิดชอบ"
                                            >
                                                {employee?.map((item, index) => (
                                                    <Option key={index} value={item.employeeId}>{item.firstname}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    : []
                                }

                                <div style={{ paddingLeft: 10 }}>
                                    <Form.Item name="projectPhase">
                                        <Select
                                            showSearch
                                            style={{ width: 200, border: "2px solid #429321", borderRadius: 8 }}
                                            optionFilterProp="children"
                                            placeholder="Project phase/round"
                                            allowClear
                                            disabled={formTask.getFieldValue()?.isOpenPaidEmployee}
                                        >
                                            {projectPhase?.map((item, index) => (
                                                <Option key={index} value={item.projectPhaseId}>{item.name} (Phase {item.phase})</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div style={{ paddingLeft: 10 }}>
                                    <Form.Item name="taskProcess">
                                        <Select
                                            showSearch
                                            style={{ width: 150 }}
                                            optionFilterProp="children"
                                            allowClear
                                            placeholder="Task process"
                                        >
                                            {taskProcess?.map((item, index) => (
                                                <Option key={index} value={item.taskProcessId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div style={{ paddingLeft: 10 }}>
                                    <Form.Item name="taskOpenStatus">
                                        <Select
                                            showSearch
                                            style={{ width: 150 }}
                                            optionFilterProp="children"
                                            allowClear
                                            placeholder="การเปิด Task"
                                        >
                                            {taskOpenStatus?.map((item, index) => (
                                                <Option key={index} value={item.taskOpenStatusId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div style={{ paddingLeft: 10 }}>
                                    <Form.Item name="employeePosition">
                                        <Select
                                            showSearch
                                            style={{ width: 150 }}
                                            optionFilterProp="children"
                                            allowClear
                                            placeholder="Task department"
                                        >
                                            {employeePosition?.map((item, index) => (
                                                <Option key={index} value={item.employeePositionId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div style={{ paddingLeft: 10 }}>
                                    <Button
                                        style={{ float: 'right' }}
                                        type="primary"
                                        onClick={() => formSearchTask.submit()}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Icon icon="tdesign:search" />
                                            <div style={{ paddingLeft: 4, paddingRight: 4 }}>/</div>
                                            <Icon icon="grommet-icons:select" />
                                        </div>
                                    </Button>
                                </div>

                                <div style={{ paddingLeft: 10 }}>
                                    <Button
                                        type="primary"
                                        style={{ float: 'right' }}
                                        onClick={async () => {
                                            formTask.resetFields()
                                            formTask.setFieldValue("isActive", true, true)
                                            formTask.setFieldValue("progressPercentage", 0, true)
                                            formTask.setFieldValue("taskProcess", 1, true)
                                            formTask.setFieldValue("projectPhase", (projectPhase?.length > 0 ? projectPhase[projectPhase?.length - 1]?.projectPhaseId : undefined), true)

                                            await getTaskAll()

                                            setDisplayTaskOperation({
                                                isOpen: true,
                                                type: "add",
                                                data: null
                                            })
                                        }}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Icon icon="mingcute:add-fill" />
                                        </div>
                                    </Button>
                                </div>

                                <div style={{ paddingLeft: 10 }}>
                                    <Button
                                        type="primary"
                                        style={{ float: 'right' }}
                                        loading={loadingTask}
                                        onClick={async () => {
                                            await onCreateTaskFast()
                                        }}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Icon icon="mingcute:add-fill" />
                                            <div style={{ paddingLeft: 4 }}></div>
                                            <Icon icon="fa:fast-forward" />
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </Col>

                    {displayTaskOperation.isOpen ?
                        <Col span={24}>
                            <div style={{ paddingBottom: 12 }}>
                                <div style={{ backgroundColor: "#EEEEEE", padding: 12, borderTopRightRadius: 6, borderBottomLeftRadius: 6, borderBottomRightRadius: 6 }}>
                                    <Form
                                        layout="vertical"
                                        form={formTask}
                                        onFinish={onFinishTask}
                                    >
                                        <Row gutter={[24, 0]}>
                                            <Col span={24}>
                                                <Form.Item name="taskId" style={{ display: "none" }}>
                                                    <Input />
                                                </Form.Item>

                                                <Form.Item name="projectId" style={{ display: "none" }}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col span={8}>
                                                <Form.Item
                                                    name="name"
                                                    label="ชื่อ Task"
                                                    rules={[{ required: true, message: 'กรุณากรอก Task' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col span={8}>
                                                <Form.Item
                                                    name="description"
                                                    label="รายละเอียด"
                                                >
                                                    <TextArea
                                                        autoSize={{
                                                            minRows: 2,
                                                            maxRows: 8
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            {permission("task", "MTK-0009") ? // [MTK-0009] Show textArea for write feedback
                                                <>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            name="feedback"
                                                            label="Feedback"
                                                        >
                                                            <TextArea
                                                                autoSize={{
                                                                    minRows: 2,
                                                                    maxRows: 8
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                                : null
                                            }

                                            <Col span={4}>
                                                <Form.Item
                                                    name="progressPercentage"
                                                    label="ความคืบหน้า %"
                                                    rules={[{ required: true, message: 'กรุณากรอกความคืบหน้า %' }]}
                                                >
                                                    <Input disabled={formTask.getFieldValue()?.isOpenPaidEmployee} />
                                                </Form.Item>
                                            </Col>

                                            <Col span={4}>
                                                <Form.Item
                                                    name="taskProcess"
                                                    label="Task process"
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        optionFilterProp="children"
                                                        allowClear
                                                        disabled={formTask.getFieldValue()?.isOpenPaidEmployee}
                                                        onChange={(e) => {
                                                            if (e === 4) {
                                                                formTask.setFieldValue("progressPercentage", 100, true)
                                                            } else {
                                                                formTask.setFieldValue("progressPercentage", null, true)
                                                            }
                                                        }}
                                                    >
                                                        {taskProcess?.map((item, index) => (
                                                            <Option key={index} value={item.taskProcessId}>{item.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={8}>
                                                <Form.Item
                                                    name="taskRange"
                                                    label="ช่วงเวลาดำเนินการ"
                                                >
                                                    <RangePicker
                                                        style={{ width: "100%" }}
                                                        disabled={formTask.getFieldValue()?.isOpenPaidEmployee}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={4}>
                                                <Form.Item
                                                    name="priority"
                                                    label="ความสำคัญ"
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        optionFilterProp="children"
                                                        allowClear
                                                        disabled={formTask.getFieldValue()?.isOpenPaidEmployee}
                                                    >
                                                        {priority?.map((item, index) => (
                                                            <Option key={index} value={item.priorityId}>{item.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={4}>
                                                <Form.Item
                                                    name="estimateTime"
                                                    label="ประมาณการเวลา"
                                                >
                                                    <InputNumber
                                                        placeholder='1=1 hr, 0.5=30 m'
                                                        decimalSeparator={"."}
                                                        step={"0.01"}
                                                        precision={2}
                                                        style={{ width: "100%" }}
                                                        disabled={formTask.getFieldValue()?.isOpenPaidEmployee}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={8}>
                                                <Form.Item
                                                    name="parentTaskId"
                                                    label="เป็น Task ย่อยของ?"
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        optionFilterProp="children"
                                                        allowClear
                                                    >
                                                        {taskOption?.map((item, index) => (
                                                            <Option key={index} value={item.taskId}>{item.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            {permission("task", "MTK-0008") ? // [MTK-0008] Show dropdown to select task owner
                                                <Col span={4}>
                                                    <Form.Item
                                                        name="owner"
                                                        label="ผู้รับผิดชอบ"
                                                    >
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            optionFilterProp="children"
                                                            allowClear
                                                            disabled={formTask.getFieldValue()?.isOpenPaidEmployee}
                                                        >
                                                            {employee?.map((item, index) => (
                                                                <Option key={index} value={item.employeeId}>{item.firstname}</Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                : null
                                            }

                                            <Col span={4}>
                                                <Form.Item
                                                    name="employeePosition"
                                                    label="ตำเเหน่งงานใน Task"
                                                    rules={[{ required: true, message: 'กรุณาเลือกตำเเหน่งงานใน Task' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        optionFilterProp="children"
                                                        allowClear
                                                        disabled={formTask.getFieldValue()?.isOpenPaidEmployee}
                                                    >
                                                        {employeePosition?.map((item, index) => (
                                                            <Option key={index} value={item.employeePositionId}>{item.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={4}>
                                                <Form.Item
                                                    name="projectPhase"
                                                    label="Project phase/round"
                                                    rules={[{ required: true, message: 'กรุณาเลือก Project phase/round' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ width: '100%' }}
                                                        optionFilterProp="children"
                                                        allowClear
                                                        disabled={formTask.getFieldValue()?.isOpenPaidEmployee}
                                                    >
                                                        {projectPhase?.map((item, index) => (
                                                            <Option key={index} value={item.projectPhaseId}>{item.name} (Phase {item.phase})</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            {permission("task", "MTK-0010") ? // [MTK-0010] Show Input number for enter employee cost
                                                <Col span={4}>
                                                    <Form.Item
                                                        name="employeeCost"
                                                        label="ค่าใช้จ่ายพนักงาน"
                                                    >
                                                        <InputNumber
                                                            decimalSeparator={"."}
                                                            step={"0.01"}
                                                            precision={2}
                                                            style={{ width: "100%" }}
                                                            disabled={formTask.getFieldValue()?.isOpenPaidEmployee}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                : null
                                            }

                                            {permission("task", "MTK-0025") ? // [MTK-0025] Show Input number for enter operation cost
                                                <Col span={4}>
                                                    <Form.Item
                                                        name="operationCost"
                                                        label="ค่าใช้จ่ายดำเนินงาน"
                                                    >
                                                        <InputNumber
                                                            decimalSeparator={"."}
                                                            step={"0.01"}
                                                            precision={2}
                                                            style={{ width: "100%" }}
                                                            disabled={formTask.getFieldValue()?.isOpenPaidEmployee}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                : null
                                            }

                                            {permission("task", "MTK-0028") ? // [MTK-0028] Show Input number for enter operation discount
                                                <Col span={4}>
                                                    <Form.Item
                                                        name="operationDiscount"
                                                        label="ส่วนลดดำเนินงาน"
                                                    >
                                                        <InputNumber
                                                            decimalSeparator={"."}
                                                            step={"0.01"}
                                                            precision={2}
                                                            style={{ width: "100%" }}
                                                            disabled={formTask.getFieldValue()?.isOpenPaidEmployee}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                : null
                                            }

                                            {permission("task", "MTK-0011") ? // [MTK-0011] Show dropdown to select isActive of task
                                                <Col span={4}>
                                                    <Form.Item
                                                        name="isActive"
                                                        label="สถานะการใช้งาน"
                                                        rules={[{ required: true, message: 'กรุณาเลือกสถานะการเเสดง' }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            optionFilterProp="children"
                                                            allowClear
                                                        >
                                                            <Option value={true}>เปิดการใช้งาน</Option>
                                                            <Option value={false}>ปิดการใช้งาน</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                : []
                                            }
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                        : []
                    }

                    <Col span={24}>
                        {!resetTableTask ?
                            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                                <SortableContext
                                    items={taskSelected.taskListTable.map((i) => i.key)}
                                    strategy={verticalListSortingStrategy}
                                >
                                    <Table
                                        loading={loadingTask}
                                        dataSource={taskSelected.taskListTable}
                                        pagination={{
                                            current: currentPage, // ค่า page ปัจจุบัน
                                            pageSize: pageSize, // ค่า page size ที่เลือก
                                            pageSizeOptions: ['10', '20', '50', '100'],
                                            showSizeChanger: true,
                                            size: 'small',
                                            onChange: (page, size) => {
                                                setCurrentPage(page); // อัปเดต page เมื่อเปลี่ยนหน้า
                                                setPageSize(size); // อัปเดต pageSize เมื่อเปลี่ยน page size
                                            },
                                            onShowSizeChange: (current, size) => {
                                                setPageSize(size); // อัปเดต pageSize เมื่อเลือก page size ใหม่
                                            }
                                        }}
                                        rowSelection={permission("task", "MTK-0012") ? rowSelection : null}
                                        rowKey="key"
                                        scroll={{ y: heightScreen }}
                                        components={{
                                            body: { row: Rows },
                                        }}
                                    >
                                        <>
                                            <Column key="sort" align={'center'} width={"3%"} render={(tags, record) => (<DragHandle />)} />
                                            {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                                            <Column title="Code" dataIndex="taskCode" key="taskCode" width={"8%"} align={'center'} />
                                            {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                                            <Column
                                                title="ชื่อ Task"
                                                dataIndex="name"
                                                key="name"
                                                width={sizeTaskName()}
                                                render={(tags, record) => (
                                                    <div style={{ display: "flex", width: "100%" }}>

                                                        {detactItemCreateLatest(record.createdAtOriginal, 1)}

                                                        {record.isOpenPaidEmployee ?
                                                            <Tooltip placement="right" title={"ถูกใส่ใน Payment Schedule เเล้ว!"}>
                                                                <div style={{ paddingRight: 6, height: 15 }}>
                                                                    <Icon icon="material-symbols:paid" style={{ color: "#ff9900" }} />
                                                                </div>
                                                            </Tooltip>
                                                            : []
                                                        }

                                                        {record.parentTask ?
                                                            <>
                                                                <Tooltip placement="bottom" title={record.parentTask}>
                                                                    <div style={{ paddingRight: 6, height: 15 }}>
                                                                        <Icon icon="pepicons-pop:chain" style={{ color: "#429321", width: 15, height: 15 }} />
                                                                    </div>
                                                                </Tooltip>

                                                                {genTaskNameChange(tags, record, record.index)}
                                                            </>
                                                            :
                                                            genTaskNameChange(tags, record, record.index)
                                                        }
                                                    </div>
                                                )}
                                            />
                                            {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                                            {permission("task", "MTK-0013") && isOpenEmployeeCostTotal ? // [MTK-0013] Show column employee cost by task
                                                <Column
                                                    title="Cost Emp"
                                                    dataIndex="employeeCost"
                                                    key="employeeCost"
                                                    width="6%"
                                                    render={(tags, record) => {
                                                        const employeeCost = parseFloat(tags) || 0;

                                                        return (
                                                            <InputNumber
                                                                defaultValue={employeeCost}
                                                                size="small"
                                                                decimalSeparator="."
                                                                step="0.01"
                                                                precision={2}
                                                                style={{
                                                                    border: "0px",
                                                                    padding: 0,
                                                                    margin: 0,
                                                                    borderRadius: 0,
                                                                    width: "100%",
                                                                }}
                                                                disabled={formTask.getFieldValue()?.isOpenPaidEmployee}
                                                                onBlur={async (e) => {
                                                                    const newEmployeeCost = parseFloat(e.target.value) || 0;

                                                                    if (newEmployeeCost !== employeeCost) {
                                                                        updateTaskEployeeCostByIdFetch({
                                                                            employeeCost: newEmployeeCost,
                                                                            taskId: record.taskId,
                                                                        });

                                                                        if (isOpenEmployeeCostRatio) {
                                                                            const newOperationCost = newEmployeeCost / employeeCostRatio;
                                                                            updateTaskOperationCostByIdFetch({
                                                                                operationCost: newOperationCost,
                                                                                taskId: record.taskId,
                                                                            });
                                                                        }

                                                                        const updateTaskList = (list) =>
                                                                            list.map((item) =>
                                                                                item.taskId === record.taskId
                                                                                    ? {
                                                                                        ...item,
                                                                                        employeeCost: newEmployeeCost,
                                                                                        operationCost: isOpenEmployeeCostRatio
                                                                                            ? newEmployeeCost / employeeCostRatio
                                                                                            : item.operationCost,
                                                                                    }
                                                                                    : item
                                                                            );

                                                                        const taskListTable = updateTaskList(
                                                                            taskSelected.taskListTable
                                                                        );
                                                                        const taskListOriginal = updateTaskList(
                                                                            taskSelected.taskListOriginal
                                                                        );

                                                                        reloadTaskOffline(taskListTable, taskListOriginal);
                                                                    }
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                    align="center"
                                                />
                                                : null
                                            }
                                            {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                                            {permission("task", "MTK-0026") && isOpenOperationCostTotal ? // [MTK-0026] Show column operation cost by task
                                                <Column
                                                    title="Cost Opt"
                                                    dataIndex="operationCost"
                                                    key="operationCost"
                                                    width="6%"
                                                    render={(tags, record) => {
                                                        const operationCost = parseFloat(tags) || 0;

                                                        return (
                                                            <InputNumber
                                                                defaultValue={operationCost}
                                                                size="small"
                                                                decimalSeparator="."
                                                                step={0.01}
                                                                max={10000}
                                                                precision={2}
                                                                style={{
                                                                    border: "0px",
                                                                    padding: 0,
                                                                    margin: 0,
                                                                    borderRadius: 0,
                                                                    width: "100%",
                                                                }}
                                                                disabled={formTask.getFieldValue()?.isOpenPaidEmployee}
                                                                onBlur={async (e) => {
                                                                    const newOperationCost = parseFloat(e.target.value) || 0;

                                                                    if (newOperationCost !== operationCost) {
                                                                        updateTaskOperationCostByIdFetch({
                                                                            operationCost: newOperationCost,
                                                                            taskId: record.taskId,
                                                                        });

                                                                        if (isOpenEmployeeCostRatio) {
                                                                            const newEmployeeCost =
                                                                                newOperationCost * employeeCostRatio;
                                                                            updateTaskEployeeCostByIdFetch({
                                                                                employeeCost: newEmployeeCost,
                                                                                taskId: record.taskId,
                                                                            });
                                                                        }

                                                                        const updateTaskList = (list) =>
                                                                            list.map((item) =>
                                                                                item.taskId === record.taskId
                                                                                    ? {
                                                                                        ...item,
                                                                                        operationCost: newOperationCost,
                                                                                        employeeCost: isOpenEmployeeCostRatio
                                                                                            ? newOperationCost * employeeCostRatio
                                                                                            : item.employeeCost,
                                                                                    }
                                                                                    : item
                                                                            );

                                                                        const taskListTable = updateTaskList(
                                                                            taskSelected.taskListTable
                                                                        );
                                                                        const taskListOriginal = updateTaskList(
                                                                            taskSelected.taskListOriginal
                                                                        );

                                                                        reloadTaskOffline(taskListTable, taskListOriginal);
                                                                    }
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                    align="center"
                                                />
                                                : null
                                            }
                                            {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                                            {permission("task", "MTK-0029") && isOpenOperationDiscountTotal ? // [MTK-0029] Show column operation discount by task
                                                <Column
                                                    title="ส่วนลด"
                                                    dataIndex="operationDiscount"
                                                    key="operationDiscount"
                                                    width={"6%"}
                                                    render={(tags, record) => {
                                                        const operationDiscount = parseFloat(tags)
                                                        return (
                                                            <InputNumber
                                                                defaultValue={operationDiscount}
                                                                size="small"
                                                                decimalSeparator={"."}
                                                                step={"0.01"}
                                                                precision={2}
                                                                style={{ border: "0px", padding: 0, margin: 0, borderRadius: 0, width: "100%" }}
                                                                disabled={formTask.getFieldValue()?.isOpenPaidEmployee}
                                                                onBlur={async (e) => {
                                                                    const newOperationDiscount = parseFloat(e.target.value)
                                                                    if (newOperationDiscount !== operationDiscount) {
                                                                        updateTaskOperationDiscountByIdFetch({
                                                                            operationDiscount: newOperationDiscount,
                                                                            taskId: record.taskId
                                                                        })

                                                                        let taskListTable = taskSelected.taskListTable.map(item => item.taskId === record.taskId ? {
                                                                            ...item, operationDiscount: newOperationDiscount
                                                                        } : item)

                                                                        let taskListOriginal = taskSelected.taskListOriginal.map(item => item.taskId === record.taskId ? {
                                                                            ...item, operationDiscount: newOperationDiscount
                                                                        } : item)

                                                                        reloadTaskOffline(taskListTable, taskListOriginal)
                                                                    }
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                    align={'center'}
                                                />
                                                : null
                                            }
                                            {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                                            {isOpenEstimateTimeTotal ?
                                                <Column
                                                    title="ส่วนลด"
                                                    dataIndex="operationDiscount"
                                                    key="operationDiscount"
                                                    width="6%"
                                                    render={(tags, record) => {
                                                        const operationDiscount = parseFloat(tags) || 0;

                                                        return (
                                                            <InputNumber
                                                                defaultValue={operationDiscount}
                                                                size="small"
                                                                decimalSeparator="."
                                                                step={0.01}
                                                                precision={2}
                                                                style={{
                                                                    border: "0px",
                                                                    padding: 0,
                                                                    margin: 0,
                                                                    borderRadius: 0,
                                                                    width: "100%",
                                                                }}
                                                                disabled={formTask.getFieldValue()?.isOpenPaidEmployee}
                                                                onBlur={async (e) => {
                                                                    const newOperationDiscount = parseFloat(e.target.value) || 0;

                                                                    if (newOperationDiscount !== operationDiscount) {
                                                                        updateTaskOperationDiscountByIdFetch({
                                                                            operationDiscount: newOperationDiscount,
                                                                            taskId: record.taskId,
                                                                        });

                                                                        const updateTaskList = (list) =>
                                                                            list.map((item) =>
                                                                                item.taskId === record.taskId
                                                                                    ? {
                                                                                        ...item,
                                                                                        operationDiscount: newOperationDiscount,
                                                                                    }
                                                                                    : item
                                                                            );

                                                                        const taskListTable = updateTaskList(
                                                                            taskSelected.taskListTable
                                                                        );
                                                                        const taskListOriginal = updateTaskList(
                                                                            taskSelected.taskListOriginal
                                                                        );

                                                                        reloadTaskOffline(taskListTable, taskListOriginal);
                                                                    }
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                    align="center"
                                                />
                                                : []
                                            }
                                            {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                                            <Column
                                                title="Task process"
                                                dataIndex="taskProcess"
                                                key="taskProcess"
                                                width={"9%"}
                                                render={(tags, record) => {
                                                    let taskProcessId = Number(record.taskProcessId)
                                                    return (
                                                        <div style={{ backgroundColor: (record.taskProcessId === 6 ? "#ffd6d6" : "") }}>
                                                            {(permission("task", "MTK-0004") ? true : ([1, 2, 3, 5].includes(record.taskProcessId))) ? // (Pending, Working, Testing, Hold) - [MTK-0004] Show all task process list
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    {record.taskProcessId === 6 ?
                                                                        <Icon
                                                                            icon="fa:search"
                                                                            style={{ width: 18, height: 18, color: "red", cursor: "pointer" }}
                                                                            onClick={async () => {
                                                                                handleReasonsInspectTaskFailure(record.taskProcessId, record)
                                                                            }}
                                                                        />
                                                                        : []
                                                                    }
                                                                    <Select
                                                                        size="small"
                                                                        showSearch
                                                                        defaultValue={taskProcessId}
                                                                        style={{ width: "100%" }}
                                                                        optionFilterProp="children"
                                                                        variant="borderless"
                                                                        allowClear
                                                                        disabled={record?.isOpenPaidEmployee || record?.taskOpenStatusId !== 2}
                                                                        onChange={async (e) => {
                                                                            handleReasonsInspectTaskFailure(e, record)
                                                                        }}
                                                                    >
                                                                        {taskProcess?.map((item, index) => (
                                                                            <Option key={index} value={item.taskProcessId}>{item.name}</Option>
                                                                        ))}
                                                                    </Select>
                                                                </div>
                                                                :
                                                                <span>{tags}</span>
                                                            }
                                                        </div>
                                                    )
                                                }}
                                            />
                                            {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                                            <Column
                                                title="การเปิด Task"
                                                dataIndex="taskOpenStatus"
                                                key="taskOpenStatus"
                                                width={"10%"}
                                                render={(tags, record) => {
                                                    let taskOpenStatusId = Number(record.taskOpenStatusId);

                                                    // เช็ค permission ก่อนการ render
                                                    if (!permission("task", "MTK-0014")) {
                                                        return <span>{tags}</span>;
                                                    }

                                                    const handleChange = async (e) => {
                                                        const newTaskOpenStatusId = parseFloat(e);
                                                        if (newTaskOpenStatusId !== taskOpenStatusId) {
                                                            const obj = {
                                                                taskOpenStatusId: newTaskOpenStatusId,
                                                                ownerApprovalId: e === 2 ? auth.employeeId : null,
                                                                taskId: record.taskId
                                                            };

                                                            // เรียกฟังก์ชัน updateTaskOpenStatusByIdFetch
                                                            updateTaskOpenStatusByIdFetch([obj]);

                                                            // อัพเดต taskListTable และ taskListOriginal อย่างมีประสิทธิภาพ
                                                            const updateTaskList = (taskList) => taskList.map(item =>
                                                                item.taskId === record.taskId ? { ...item, taskOpenStatusId: newTaskOpenStatusId } : item
                                                            );

                                                            let taskListTable = updateTaskList(taskSelected.taskListTable);
                                                            let taskListOriginal = updateTaskList(taskSelected.taskListOriginal);

                                                            // เรียกใช้ reloadTaskOffline เพื่ออัพเดตข้อมูล
                                                            reloadTaskOffline(taskListTable, taskListOriginal);
                                                        }
                                                    };

                                                    return (
                                                        <Select
                                                            size="small"
                                                            showSearch
                                                            defaultValue={record.taskOpenStatusId}
                                                            style={{ width: "100%" }}
                                                            variant="borderless"
                                                            optionFilterProp="children"
                                                            allowClear
                                                            disabled={record?.isOpenPaidEmployee}
                                                            onChange={handleChange} // ใช้ handleChange ที่เราสร้างขึ้น
                                                        >
                                                            {taskOpenStatus?.map((item, index) => (
                                                                <Option key={index} value={item.taskOpenStatusId}>
                                                                    {item.name}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    );
                                                }}
                                                align={'center'}
                                            />

                                            {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                                            <Column
                                                title="Task department"
                                                dataIndex="employeePosition"
                                                key="employeePosition"
                                                width={"10%"}
                                                render={(tags, record) => {
                                                    let employeePositionId = record.employeePositionId
                                                    return (
                                                        <Select
                                                            showSearch
                                                            defaultValue={employeePositionId}
                                                            size="small"
                                                            style={{ width: "100%" }}
                                                            variant="borderless"
                                                            optionFilterProp="children"
                                                            allowClear
                                                            placeholder="Task department"
                                                            disabled={record?.isOpenPaidEmployee || record?.taskOpenStatusId !== 2}
                                                            onChange={async (e) => {
                                                                const newEmployeePositionId = e
                                                                if (newEmployeePositionId !== employeePositionId) {
                                                                    updateTaskEmployeePositionByIdFetch({
                                                                        employeePositionId: newEmployeePositionId,
                                                                        taskId: record.taskId
                                                                    })

                                                                    let taskListTable = taskSelected.taskListTable.map(item => item.taskId === record.taskId ? {
                                                                        ...item, employeePositionId: newEmployeePositionId
                                                                    } : item)

                                                                    let taskListOriginal = taskSelected.taskListOriginal.map(item => item.taskId === record.taskId ? {
                                                                        ...item, employeePositionId: newEmployeePositionId
                                                                    } : item)

                                                                    reloadTaskOffline(taskListTable, taskListOriginal)
                                                                }
                                                            }}
                                                        >
                                                            {employeePosition?.map((item, index) => (
                                                                <Option key={index} value={item.employeePositionId}>{item.name}</Option>
                                                            ))}
                                                        </Select>
                                                    )
                                                }}
                                            />
                                            {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                                            <Column
                                                title="ผู้รับผิดชอบ"
                                                dataIndex="employee"
                                                key="employee"
                                                width={"9%"}
                                                render={(tags, record) => {
                                                    const ownerId = record.ownerId;

                                                    // ฟังก์ชันสำหรับการอัพเดต task list
                                                    const updateTaskList = (taskList, newOwnerId) =>
                                                        taskList.map(item =>
                                                            item.taskId === record.taskId
                                                                ? { ...item, ownerId: newOwnerId }
                                                                : item
                                                        );

                                                    // ฟังก์ชันที่จัดการการเปลี่ยนแปลงของ owner
                                                    const handleOwnerChange = async (newOwnerId) => {
                                                        if (newOwnerId !== ownerId) {
                                                            // อัพเดตข้อมูลในฐานข้อมูล
                                                            updateTaskOwnerByIdFetch({
                                                                ownerId: newOwnerId,
                                                                taskId: record.taskId
                                                            });

                                                            // อัพเดตทั้ง taskListTable และ taskListOriginal
                                                            const taskListTable = updateTaskList(taskSelected.taskListTable, newOwnerId);
                                                            const taskListOriginal = updateTaskList(taskSelected.taskListOriginal, newOwnerId);

                                                            // เรียกฟังก์ชัน reloadTaskOffline
                                                            reloadTaskOffline(taskListTable, taskListOriginal);
                                                        }
                                                    };

                                                    return (
                                                        <Select
                                                            showSearch
                                                            defaultValue={ownerId}
                                                            size="small"
                                                            style={{ width: "100%" }}
                                                            variant="borderless"
                                                            optionFilterProp="children"
                                                            allowClear
                                                            placeholder="Task department"
                                                            disabled={record?.isOpenPaidEmployee || record?.taskOpenStatusId !== 2}
                                                            onChange={handleOwnerChange} // ใช้ handleOwnerChange
                                                        >
                                                            {employee?.map((item, index) => (
                                                                <Option key={index} value={item.employeeId}>
                                                                    {item.firstname}
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    );
                                                }}
                                            />

                                            {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                                            {isOpenProgressPercentageTotal ?
                                                <Column
                                                    title="คืบหน้า%"
                                                    dataIndex="progressPercentage"
                                                    key="progressPercentage"
                                                    width={"6%"}
                                                    render={(tags, record) => {
                                                        const progressPercentage = parseFloat(tags);

                                                        // ฟังก์ชันสำหรับการอัพเดต task list
                                                        const updateTaskList = (taskList, newProgressPercentage) =>
                                                            taskList.map(item =>
                                                                item.taskId === record.taskId
                                                                    ? { ...item, progressPercentage: newProgressPercentage }
                                                                    : item
                                                            );

                                                        // ฟังก์ชันที่จัดการการเปลี่ยนแปลงของ progress percentage
                                                        const handleProgressChange = async (e) => {
                                                            const newProgressPercentage = parseFloat(e.target.value);
                                                            if (newProgressPercentage !== progressPercentage) {
                                                                // อัพเดตข้อมูลในฐานข้อมูล
                                                                updateTaskProgressPercentageByIdFetch({
                                                                    progressPercentage: newProgressPercentage,
                                                                    taskId: record.taskId
                                                                });

                                                                // อัพเดตทั้ง taskListTable และ taskListOriginal
                                                                const taskListTable = updateTaskList(taskSelected.taskListTable, newProgressPercentage);
                                                                const taskListOriginal = updateTaskList(taskSelected.taskListOriginal, newProgressPercentage);

                                                                // เรียกฟังก์ชัน reloadTaskOffline
                                                                reloadTaskOffline(taskListTable, taskListOriginal);
                                                            }
                                                        };

                                                        return (
                                                            <InputNumber
                                                                defaultValue={tags}
                                                                size="small"
                                                                decimalSeparator={"."}
                                                                step={"0.01"}
                                                                precision={2}
                                                                style={{ border: "0px", padding: 0, margin: 0, borderRadius: 0, width: "100%" }}
                                                                disabled={formTask.getFieldValue()?.isOpenPaidEmployee || record?.taskOpenStatusId !== 2}
                                                                onBlur={handleProgressChange} // ใช้ handleProgressChange
                                                            />
                                                        );
                                                    }}
                                                />
                                                : []
                                            }
                                            {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                                            <Column
                                                title="วันที่เสร็จ"
                                                dataIndex="taskEndDate"
                                                key="taskEndDate"
                                                width={"7%"}
                                                render={(tags, record) => {
                                                    const formattedDate = tags ? moment(tags).format("DD MMM") : "-";
                                                    const isOverdue = tags && record.taskProcessId !== 4 && new Date(moment(tags).format(formatDate)) < new Date();

                                                    // ฟังก์ชันที่ใช้ในการแสดง Tooltip
                                                    const renderDateWithTooltip = () => (
                                                        <Tooltip placement="right" title={"เกินกำหนดเเล้ว!"}>
                                                            <span style={{ color: "red" }}>{formattedDate}</span>
                                                        </Tooltip>
                                                    );

                                                    // ฟังก์ชันที่ใช้ในการแสดงวันที่ปกติ
                                                    const renderNormalDate = () => <span>{formattedDate}</span>;

                                                    return (
                                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                                            {tags ? (
                                                                isOverdue ? renderDateWithTooltip() : renderNormalDate()
                                                            ) : (
                                                                <span>-</span>
                                                            )}
                                                        </div>
                                                    );
                                                }}
                                                align={'center'}
                                            />

                                            {/* ----------------------------------------------------------------------------------------------------------------------------------------------- */}
                                            <Column title={<FaCog />} dataIndex="operator" key="operator" width={"11%"} align={'center'} />
                                        </>
                                    </Table>
                                </SortableContext>
                            </DndContext>
                            :
                            <div style={{ height: 250, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Spin size="large" />
                            </div>
                        }
                    </Col>

                    <Col span={24}>
                        <Row gutter={[6, 6]} style={{ paddingTop: 12 }}>
                            <Col span={6}>
                                <div style={{ display: "grid" }}>
                                    <div style={{ display: "flex", paddingBottom: 4 }}>
                                        <div style={{ width: 220 }}>
                                            <label>{permission("task", "MTK-0015") ? "ค่าใช้จ่ายพนักงานรวม ตามการค้นหา" : ("รายได้รวมของคุณ " + auth.firstname + " ตามการค้นหา")}</label> {/* // [MTK-0015] Show sum employee cost mode manage accounts  */}
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {isOpenEmployeeCostTotal ?
                                                <label style={{ paddingRight: 8, fontWeight: 600 }}>
                                                    {Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(taskSelected.employeeCostTotal))} บาท
                                                </label>
                                                : []
                                            }
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    setIsOpenEmployeeCostTotal(!isOpenEmployeeCostTotal)
                                                }}
                                                size="small"
                                                style={{ display: "flex", alignItems: "center" }}
                                            >
                                                {isOpenEmployeeCostTotal ?
                                                    <Icon icon="mdi:hide" style={{ color: "white", width: 18, height: 18 }} />
                                                    :
                                                    <Icon icon="akar-icons:eye-open" style={{ color: "white", width: 18, height: 18 }} />
                                                }
                                            </Button>
                                        </div>
                                    </div>

                                    {permission("task", "MTK-0027") ? // [MTK-0027] Show sum operation cost mode manage accounts
                                        <div style={{ display: "flex", paddingBottom: 4 }}>
                                            <div style={{ width: 220 }}>
                                                <label>มูลค่าโปรเจกต์ ตามการค้นหา</label>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                {isOpenOperationCostTotal ?
                                                    <label style={{ paddingRight: 8, fontWeight: 600 }}>
                                                        {Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(taskSelected.operationCostTotal))} บาท
                                                    </label>
                                                    : []
                                                }
                                                <Button
                                                    type="primary"
                                                    onClick={() => {
                                                        setIsOpenOperationCostTotal(!isOpenOperationCostTotal)
                                                    }}
                                                    size="small"
                                                    style={{ display: "flex", alignItems: "center" }}
                                                >
                                                    {isOpenOperationCostTotal ?
                                                        <Icon icon="mdi:hide" style={{ color: "white", width: 18, height: 18 }} />
                                                        :
                                                        <Icon icon="akar-icons:eye-open" style={{ color: "white", width: 18, height: 18 }} />
                                                    }
                                                </Button>
                                            </div>
                                        </div>
                                        : []
                                    }
                                </div>
                            </Col>

                            <Col span={6}>
                                <div style={{ display: "grid" }}>
                                    <div style={{ display: "flex", paddingBottom: 4 }}>
                                        <div style={{ width: 220 }}>
                                            <label>ส่วนลดโปรเจกต์ ตามการค้นหา</label> {/* // [MTK-0030] Show sum operation discount mode manage accounts */}
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {isOpenOperationDiscountTotal ?
                                                <label style={{ paddingRight: 8, fontWeight: 600 }}>
                                                    {Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(taskSelected.operationDiscountTotal))} บาท
                                                </label>
                                                : []
                                            }
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    setIsOpenOperationDiscountTotal(!isOpenOperationDiscountTotal)
                                                }}
                                                size="small"
                                                style={{ display: "flex", alignItems: "center" }}
                                            >
                                                {isOpenOperationDiscountTotal ?
                                                    <Icon icon="mdi:hide" style={{ color: "white", width: 18, height: 18 }} />
                                                    :
                                                    <Icon icon="akar-icons:eye-open" style={{ color: "white", width: 18, height: 18 }} />
                                                }
                                            </Button>
                                        </div>
                                    </div>

                                    <div style={{ display: "flex", paddingBottom: 4 }}>
                                        <div style={{ width: 220 }}>
                                            <label>ประมาณการเวลารวม ตามการค้นหา</label>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {isOpenEstimateTimeTotal ?
                                                <label style={{ paddingRight: 8, fontWeight: 600 }}>{taskSelected.estimateTimeTotal} hr</label>
                                                : []
                                            }
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    setIsOpenEstimateTimeTotal(!isOpenEstimateTimeTotal)
                                                }}
                                                size="small"
                                                style={{ display: "flex", alignItems: "center" }}
                                            >
                                                {isOpenEstimateTimeTotal ?
                                                    <Icon icon="mdi:hide" style={{ color: "white", width: 18, height: 18 }} />
                                                    :
                                                    <Icon icon="akar-icons:eye-open" style={{ color: "white", width: 18, height: 18 }} />
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col span={6}>
                                <div style={{ display: "grid" }}>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ width: 220 }}>
                                            <label>ความคืบหน้ารวม ตามการค้นหา</label>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {isOpenProgressPercentageTotal ?
                                                <>
                                                    {(taskSelected.progressPercentageTotal) ?
                                                        <label style={{ paddingRight: 8, fontWeight: 600 }}>{Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(taskSelected.progressPercentageTotal))} %</label>
                                                        : "-"
                                                    }
                                                </>
                                                : []
                                            }
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    setIsOpenProgressPercentageTotal(!isOpenProgressPercentageTotal)
                                                }}
                                                size="small"
                                                style={{ display: "flex", alignItems: "center" }}
                                            >
                                                {isOpenProgressPercentageTotal ?
                                                    <Icon icon="mdi:hide" style={{ color: "white", width: 18, height: 18 }} />
                                                    :
                                                    <Icon icon="akar-icons:eye-open" style={{ color: "white", width: 18, height: 18 }} />
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            <Col span={6}>
                                {permission("task", "MTK-0016") ? // [MTK-0016] Create payment schedule
                                    <div style={{ display: "flex", backgroundColor: "#EEEEEE", height: "100%", borderRadius: 6 }}>
                                        <div style={{ paddingTop: 12, paddingBottom: 12, paddingLeft: 12, width: 190 }}>
                                            <span>Payment Schedule</span>
                                            <Button
                                                type="primary"
                                                style={{ width: "100%" }}
                                                disabled={selectedRowKeysTask.length === 0}
                                                onClick={handlePaymentSchedule}
                                            >
                                                คลิก! สร้าง
                                            </Button>
                                        </div>

                                        <div style={{ padding: 12, width: 190 }}>
                                            <span>emp ratio</span>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ paddingRight: 12 }}>
                                                    <InputNumber
                                                        disabled={!isOpenEmployeeCostRatio}
                                                        defaultValue={employeeCostRatio}
                                                        decimalSeparator={"."}
                                                        step={"0.01"}
                                                        precision={2}
                                                        style={{ width: "100%" }}
                                                        onBlur={async (e) => {
                                                            setEmployeeCostRatio(e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <Switch
                                                    size="default"
                                                    checked={isOpenEmployeeCostRatio}
                                                    checkedChildren="เปิด"
                                                    unCheckedChildren="ปิด"
                                                    style={{ width: 120 }}
                                                    defaultChecked
                                                    onChange={(e) => {
                                                        // console.log("e --- : ", e)
                                                        setIsOpenEmployeeCostRatio(e)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    : []
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>

            <Modal
                title={<strong><label className="topic-color-bold">จัดการ Project phase/round</label></strong>}
                open={modalProjectPhase.isShow}
                width={"100%"}
                style={{
                    top: 8,
                    padding: 0
                }}
                onCancel={() => {
                    // default
                    setFormDefaultProjectPhase()
                }}
                footer={false}
            >
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div style={{ padding: 12, backgroundColor: (["manage", null].includes(modalProjectPhase.type) ? "" : "#EEEEEE") }}>
                        {["manage", null].includes(modalProjectPhase.type) ?
                            <Button
                                type="primary"
                                onClick={async () => {
                                    const result = await getProjectPhaseSearch({
                                        projectId: projectSelected.current.projectId
                                    })

                                    formProjectPhase.setFieldsValue({
                                        projectPhaseId: undefined,
                                        projectId: projectSelected.current.projectId,
                                        workProcess: undefined,
                                        paymentInstallment: undefined,
                                        paymentProcess: undefined,
                                        name: undefined,
                                        phase: ((result?.length ?? 0) + 1),
                                        projectValue: undefined,
                                        goLiveDate: undefined,
                                        processingPeriod: undefined,
                                        isActive: undefined
                                    })

                                    setModalProjectPhase({ ...modalProjectPhase, type: "add" })
                                }}
                            >
                                เพิ่ม Phase/Round
                            </Button>
                            :
                            <div style={{ display: "flex" }}>
                                <div style={{ paddingRight: 10 }}>
                                    <Button
                                        type="default"
                                        onClick={() => {
                                            setModalProjectPhase({ ...modalProjectPhase, type: "manage" })
                                        }}
                                    >
                                        ยกเลิก
                                    </Button>
                                </div>

                                <div>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            formProjectPhase.submit()
                                        }}
                                    >
                                        ยืนยัน
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {["add", "edit"].includes(modalProjectPhase.type) ?
                    <div style={{ backgroundColor: "#EEEEEE", padding: 12 }}>
                        <Form
                            layout="vertical"
                            form={formProjectPhase}
                            onFinish={onFinishProjectPhase}
                        >
                            <Row gutter={[24, 0]}>
                                <Col span={24}>
                                    <Form.Item name="projectPhaseId" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>

                                    <Form.Item name="projectId" style={{ display: "none" }}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="name"
                                        label="ชื่อ Project phase/round"
                                        rules={[{ required: true, message: 'กรุณากรอกชื่อ Project phase/round' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="workProcess"
                                        label="Work process"
                                        rules={[{ required: true, message: 'กรุณาเลือก Work process' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            {workProcess?.map((item, index) => (
                                                <Option key={index} value={item.workProcessId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="processingPeriod"
                                        label={`ช่วงดำเนินการ ${processingDays ? ("(" + processingDays + "  วัน)") : ""}`}
                                    >
                                        <RangePicker
                                            style={{ width: "100%" }}
                                            onChange={(e) => {
                                                if (e) {
                                                    let daysDiff = calculateDaysDifference(formProjectPhase.getFieldValue().processingPeriod[0], formProjectPhase.getFieldValue().processingPeriod[1]).days
                                                    // console.log("daysDiff : ", daysDiff)
                                                    setProcessingDays(daysDiff)
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="goLiveDate"
                                        label={projectSelected.current.serviceId === 1 ? "วันส่งมอบ" : "วันสรุปผล"}
                                    >
                                        <DatePicker showTime style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>

                                <Col span={3}>
                                    <Form.Item
                                        name="projectValue"
                                        label="มูลค่าโปรเจกต์"
                                        rules={[{ required: true, message: 'กรุณากรอกมูลค่าโปรเจกต์' }]}
                                    >
                                        <InputNumber decimalSeparator={"."} step={"0.01"} style={{ width: "100%" }} precision={2} />
                                    </Form.Item>
                                </Col>

                                <Col span={3}>
                                    <Form.Item
                                        name="phase"
                                        label="Phase/Round"
                                    >
                                        {/* {modalProjectPhase.type === "add" ? */}
                                        <label>{formProjectPhase?.getFieldValue()?.phase ?? "-"}</label>
                                        {/* :
                                            <Input />
                                        } */}
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="paymentInstallment"
                                        label="การเเบ่งจ่าย"
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                            onChange={async (e) => {
                                                await getPaymentProcessById(e)
                                                formProject.setFieldsValue("paymentProcess", undefined, true)
                                            }}
                                        >
                                            {paymentInstallment?.map((item, index) => (
                                                <Option key={index} value={item.paymentInstallmentId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        name="paymentProcess"
                                        label="การชำระเงิน"
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            {paymentProcess?.map((item, index) => (
                                                <Option key={index} value={item.paymentProcessId}>{item.name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Form>
                    </div>
                    : []
                }

                <div>
                    <Table
                        loading={loadingProjectPhase}
                        dataSource={projectPhase}
                        pagination={{
                            current: pageCurrentProjectPhase,
                            pageSize: 10,
                            total: projectPhase.length
                        }}
                        onChange={(n) => onPagineProjectPhase(n)}
                    >
                        <Column title="No." dataIndex="index" key="index" width={"3%"} align={'center'} />
                        <Column title="ชื่อ Project phase/round" dataIndex="name" key="name" width={"22%"} />
                        <Column title="Phase/Round" dataIndex="phase" key="phase" width={"5%"} />
                        <Column
                            title="มูลค่า Project "
                            dataIndex="projectValue"
                            key="projectValue"
                            width={"8%"}
                            render={(tags, record) => (
                                <>
                                    <label>{Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(tags)} บาท</label>
                                </>
                            )}
                        />
                        <Column title="Work process" dataIndex="workProcess" key="workProcess" width={"10%"} />
                        {permission("task", "MTK-0007") ? <Column title="การชำระเงิน" dataIndex="paymentProcess" key="paymentProcess" width={"21%"} /> : null} {/* // [MTK-0007] Show column payment process list */}
                        <Column
                            title="วันส่งมอบ"
                            dataIndex="goLiveDate"
                            key="goLiveDate"
                            width={"20%"}
                            // align={'center'}
                            render={(tags, record) => {
                                const originalGoLiveDate = new Date(tags ?? null);
                                const updatedGoLiveDate = new Date(originalGoLiveDate?.getTime() - 7 * 60 * 60 * 1000); // ลบ 7 ชั่วโมง

                                let diffDays = null
                                let daysLeft = ""
                                if (tags) {
                                    diffDays = calculateDaysDifference(
                                        new Date(), // currentTime, 
                                        updatedGoLiveDate
                                    )

                                    if (diffDays.days > 0 || diffDays.hours > 0 || diffDays.minutes > 0 || diffDays.seconds > 0) {
                                        // daysLeft = `${moment(tags).format("DD MMM YYYY")} (เหลือ ${diffDays.days} วัน ${diffDays.hours} ชั่วโมง ${diffDays.minutes} นาที  ${diffDays.seconds} วินาที)`
                                        daysLeft = `${moment(tags).format("DD MMM YYYY")} (เหลือ ${diffDays.days} วัน ${diffDays.hours} ชั่วโมง)`
                                    } else {
                                        daysLeft = "เกินกำหนดส่งมอบเเล้ว"
                                    }
                                } else {
                                    daysLeft = "-"
                                }

                                return (
                                    <>
                                        {diffDays && tags ?
                                            <label style={{ color: (diffDays.days <= 0 && diffDays.hours <= 0 && diffDays.minutes <= 0 && diffDays.seconds <= 0 ? "red" : "") }}>{daysLeft}</label>
                                            :
                                            <label>-</label>
                                        }
                                    </>
                                )
                            }}
                        />
                        <Column title={<FaCog />} dataIndex="operator" key="operator" width={"5%"} align={'center'} />
                    </Table>
                </div>
            </Modal>

            <Modal
                title={<strong><label className="topic-color-bold">{modalProjectInfo.title}</label></strong>}
                open={modalProjectInfo.isShow}
                style={{ top: 8 }}
                onCancel={() => {
                    setModalProjectInfo({
                        isShow: false,
                        title: null,
                        data: null
                    })
                }}
                width={"100%"}
                footer={null}
            >
                <Row gutter={[6, 6]}>
                    <Col span={4}>
                        <label>Website (URL)</label>
                    </Col>
                    <Col span={20}>
                        <a target={"_blank"} href={modalProjectInfo?.data?.urlWebsite}>{modalProjectInfo?.data?.urlWebsite ?? "-"}</a>
                    </Col>

                    <Col span={4}>
                        <label>ชื่อเว็บไซต์</label>
                    </Col>
                    <Col span={20}>
                        <label>{modalProjectInfo?.data?.domain ?? "-"}</label>
                    </Col>

                    <Col span={4}>
                        <label>Package</label>
                    </Col>
                    <Col span={20}>
                        <label>{modalProjectInfo?.data?.package?.name ?? "-"} {modalProjectInfo?.data?.package?.package_size?.name ?? "-"}</label>
                    </Col>

                    <Col span={4}>
                        <label>Website Backoffice (URL)</label>
                    </Col>
                    <Col span={20}>
                        <a target={"_blank"} href={modalProjectInfo?.data?.urlWebsiteBackoffice}>{modalProjectInfo?.data?.urlWebsiteBackoffice ?? "-"}</a>
                    </Col>

                    <Col span={4}>
                        <label>API Development (URL)</label>
                    </Col>
                    <Col span={20}>
                        <a target={"_blank"} href={modalProjectInfo?.data?.apiDevelopment}>{modalProjectInfo?.data?.apiDevelopment ?? "-"}</a>
                    </Col>

                    <Col span={4}>
                        <label>API Production (URL)</label>
                    </Col>
                    <Col span={20}>
                        <a target={"_blank"} href={modalProjectInfo?.data?.apiProduction}>{modalProjectInfo?.data?.apiProduction ?? "-"}</a>
                    </Col>

                    <Col span={4}>
                        <label>Figma (URL)</label>
                    </Col>
                    <Col span={20}>
                        <a target={"_blank"} href={modalProjectInfo?.data?.figmaUrl} b>{modalProjectInfo?.data?.figmaUrl ?? "-"}</a>
                    </Col>

                    <Col span={4}>
                        <label>Git-Client (URL)</label>
                    </Col>
                    <Col span={20}>
                        <a target={"_blank"} href={modalProjectInfo?.data?.gitClientUrl}>{modalProjectInfo?.data?.gitClientUrl ?? "-"}</a>
                    </Col>

                    <Col span={4}>
                        <label>Git-Backoffice (URL)</label>
                    </Col>
                    <Col span={20}>
                        <a target={"_blank"} href={modalProjectInfo?.data?.gitBackofficeUrl}>{modalProjectInfo?.data?.gitBackofficeUrl ?? "-"}</a>
                    </Col>

                    <Col span={4}>
                        <label>Git-Server (URL)</label>
                    </Col>
                    <Col span={20}>
                        <a target={"_blank"} href={modalProjectInfo?.data?.gitServerUrl}>{modalProjectInfo?.data?.gitServerUrl ?? "-"}</a>
                    </Col>

                    <Col span={4}>
                        <label>ชื่อผู้ใช้ Backoffice</label>
                    </Col>
                    <Col span={20}>
                        <label>{modalProjectInfo?.data?.usernameAdmin ?? "-"}</label>
                    </Col>

                    <Col span={4}>
                        <label>รหัสผ่าน Backoffice</label>
                    </Col>
                    <Col span={20}>
                        <label>{modalProjectInfo?.data?.passwordAdmin ?? "-"}</label>
                    </Col>

                    {/* <Col span={4}>
                        <label>Go-live Date</label>
                    </Col>
                    <Col span={20}>
                        <label>{modalProjectInfo?.data?.goLiveDate ? moment(modalProjectInfo?.data?.goLiveDate).format("DD/MM/YYYY") : "-"}</label>
                    </Col> */}

                    {/* <Col span={4}>
                        <label>Phase</label>
                    </Col>
                    <Col span={20}>
                        <label>{modalProjectInfo?.data?.phase ?? "-"}</label>
                    </Col> */}
                </Row>
            </Modal>

            <Modal
                title={<strong><label className="topic-color-bold">{modalTaskInfo.title}</label></strong>}
                open={modalTaskInfo.isShow}
                style={{ top: 8 }}
                onCancel={() => {
                    setModalTaskInfo({
                        isShow: false,
                        title: null,
                        data: null
                    })
                }}
                width={"100%"}
                footer={null}
            >
                <Row gutter={[6, 6]}>
                    <Col span={4}>
                        <label>ชื่อ Task</label>
                    </Col>
                    <Col span={20}>
                        <label>{modalTaskInfo?.data?.name ?? "-"}</label>
                    </Col>

                    <Col span={4}>
                        <label>รายละเอียด Task</label>
                    </Col>
                    <Col span={20}>
                        <label>{modalTaskInfo?.data?.description ?? "-"}</label>
                    </Col>

                    <Col span={4}>
                        <label>Feedback</label>
                    </Col>
                    <Col span={20}>
                        <label>{modalTaskInfo?.data?.feedback ?? "-"}</label>
                    </Col>

                    <Col span={4}>
                        <label>วันที่เริ่ม</label>
                    </Col>
                    <Col span={20}>
                        <label>{modalTaskInfo?.data?.goLiveDate ? moment(modalTaskInfo?.data?.taskStartDate).format("DD/MM/YYYY") : "-"}</label>
                    </Col>

                    <Col span={4}>
                        <label>วันที่ส่งมอบ</label>
                    </Col>
                    <Col span={20}>
                        <label>{modalTaskInfo?.data?.goLiveDate ? moment(modalTaskInfo?.data?.taskEndDate).format("DD/MM/YYYY") : "-"}</label>
                    </Col>
                </Row>
            </Modal>

            <Modal
                title={<strong><label className="topic-color-bold">{modalReasonsInspectTaskFailure.title}</label></strong>}
                open={modalReasonsInspectTaskFailure.isShow}
                style={{ top: 8 }}
                onCancel={() => {
                    setModalReasonsInspectTaskFailure({
                        isShow: false,
                        title: null,
                        dataUpdateTaskProcess: null,
                        taskId: null,
                        record: null,
                        taskProcessId: null
                    })
                }}
                width={700}
                footer={permission("task", "MTK-0017") ? [ // [MTK-0017] Show confirm click form submit for update reasons inspect task failure 
                    <Button
                        type="primary"
                        onClick={() => {
                            formReasonsInspectTaskFailure.submit()
                        }}
                    >
                        ยืนยัน
                    </Button>
                ] : false}
            >
                <Form
                    layout="vertical"
                    form={formReasonsInspectTaskFailure}
                    onFinish={onReasonsInspectTaskFailureFinish}
                >
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            {permission("task", "MTK-0018") ? // [MTK-0018] Write reasons inspect task failure
                                <Form.Item
                                    name="reason"
                                    label="อธิบายเหตุผลที่ไม่ผ่าน"
                                    rules={[{ required: true, message: 'กรุณากรอกคำอธิบายเหตุผลที่ไม่ผ่าน' }]}
                                >
                                    <Input style={{ display: "none" }} />
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            placeholder: 'พิมพ์คำอธิบายที่นี่...',
                                            toolbar: {
                                                items: [
                                                    'heading', '|',
                                                    'fontfamily', 'fontsize', '|',
                                                    'bold', 'italic', 'underline', '|',
                                                    'alignment', '|',
                                                    'fontColor', 'fontBackgroundColor', '|',
                                                    'bulletedList', 'numberedList', 'todoList', '|',
                                                    'code', 'codeBlock', '|',
                                                    'undo', 'redo'
                                                ],
                                                removeButtons: 'Subscript,Superscript'
                                            }
                                        }}
                                        data={formReasonsInspectTaskFailure.getFieldValue().reason ?? ""}
                                        onBlur={(event, editor) => {
                                            const data = editor.getData()
                                            formReasonsInspectTaskFailure.setFieldValue('reason', data)
                                        }}
                                    />
                                </Form.Item>
                                :
                                <div>
                                    <label>คำอธิบายเหตุผลที่ไม่ผ่าน</label>
                                    <div style={{ backgroundColor: "#EEEEEE", paddingLeft: 6, paddingRight: 6, marginTop: -14 }}>
                                        <label>{parse(formReasonsInspectTaskFailure.getFieldValue().reason ?? "-")}</label>
                                    </div>
                                </div>
                            }
                        </Col>

                        <Col span={24}>
                            {permission("task", "MTK-0018") ? // [MTK-0018] Write reasons inspect task failure
                                <Form.Item
                                    name="amountFailure"
                                    label="จำนวนครั้งที่ไม่ผ่านตรวจสอบ"
                                >
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        disabled={true}
                                    />
                                </Form.Item>
                                :
                                <div style={{ marginTop: -14 }}>
                                    <label>จำนวนครั้งที่ไม่ผ่านตรวจสอบ</label>
                                    <div style={{ backgroundColor: "#EEEEEE", padding: 6 }}>
                                        <label>{formReasonsInspectTaskFailure.getFieldValue().amountFailure ?? "-"} ครั้ง</label>
                                    </div>
                                </div>
                            }
                        </Col>

                        <Col span={12}>
                            <div style={{ paddingBottom: 10 }}>
                                <div style={{ display: "flex", alignItems: "center", paddingBottom: 5 }}>
                                    <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                    <label style={{ paddingLeft: 8 }}>รูปภาพเเนบ (.jpg, .jpeg, .png, .jfif, .webp)</label>
                                </div>
                                {!reasonsInspectTaskFailureImageURL?.imageUrl ?
                                    <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                        <label>ไม่มีรูปภาพ</label>
                                    </div>
                                    :
                                    <>
                                        <Image
                                            fallback={`${reasonsInspectTaskFailureImageURL.imageUrl}`}
                                            src={"error"}
                                            style={{ objectFit: "cover", width: "100%" }}
                                        />
                                        {permission("task", "MTK-0019") ? // [MTK-0019] Delete attach image reasons inspect task failure
                                            <div
                                                style={{
                                                    zIndex: 2,
                                                    position: "absolute",
                                                    left: "6%",
                                                    top: "12%",
                                                    cursor: "pointer",
                                                    backgroundColor: "white",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: 6,
                                                    borderRadius: 20,
                                                    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)"
                                                }}
                                                onClick={async () => {
                                                    await handleReasonsInspectTaskFailureImageDelete(reasonsInspectTaskFailureImageURL.taskId)
                                                }}
                                            >
                                                <Icon icon="fluent:delete-24-regular" style={{ color: "red", width: 20, height: 20 }} />
                                            </div>
                                            : []
                                        }
                                    </>
                                }
                            </div>

                            {permission("task", "MTK-0022") ? // [MTK-0022] Upload attach image reasons inspect task failure
                                <Upload
                                    {...optionReasonsInspectTaskFailureImage}
                                    accept='image/jpeg, image/png, image/jfif, image/webp'
                                    style={{ width: "100%" }}
                                    maxCount={1}
                                    showUploadList={false}
                                >
                                    <Button
                                        type="primary"
                                        style={{ width: "100%" }}
                                        icon={reasonsInspectTaskFailureImageURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                    >อัพโหลดรูปภาพ</Button>
                                </Upload>
                                : []
                            }
                        </Col>

                        <Col span={12}>
                            <div style={{ paddingBottom: 10 }}>
                                <div style={{ display: "flex", alignItems: "center", paddingBottom: 5 }}>
                                    <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                    <label style={{ paddingLeft: 8 }}>วิดีโอเเนบ (.mp4)</label>
                                </div>
                                {!reasonsInspectTaskFailureVideoURL?.videoUrl ?
                                    <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                        <label>ไม่มีวิดีโอ</label>
                                    </div>
                                    :
                                    <>
                                        <div style={{ display: "flex" }}>
                                            <Video url={reasonsInspectTaskFailureVideoURL.videoUrl} title={""} height={""} />
                                            {permission("task", "MTK-0020") ? // [MTK-0020] Delete attach video reasons inspect task failure
                                                <div
                                                    style={{
                                                        zIndex: 2,
                                                        position: "absolute",
                                                        left: "7%",
                                                        top: "12%",
                                                        cursor: "pointer",
                                                        backgroundColor: "white",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        padding: 6,
                                                        borderRadius: 20,
                                                        boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)"
                                                    }}
                                                    onClick={async () => {
                                                        await handleReasonsInspectTaskFailureVideoDelete(reasonsInspectTaskFailureVideoURL.taskId)
                                                    }}
                                                >
                                                    <Icon icon="fluent:delete-24-regular" style={{ color: "red", width: 20, height: 20 }} />
                                                </div>
                                                : []
                                            }
                                        </div>
                                    </>
                                }
                            </div>

                            {permission("task", "MTK-0023") ? // [MTK-0023] Upload attach video reasons inspect task failure
                                <Upload
                                    {...optionReasonsInspectTaskFailureVideo}
                                    accept='.mp4'
                                    style={{ width: "100%" }}
                                    maxCount={1}
                                    showUploadList={false}
                                >
                                    <Button
                                        type="primary"
                                        style={{ width: "100%" }}
                                        icon={reasonsInspectTaskFailureVideoURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                    >อัพโหลดวิดีโอ</Button>
                                </Upload>
                                : []
                            }
                        </Col>

                        <Col span={12}>
                            <div style={{ paddingBottom: 10 }}>
                                <div style={{ display: "flex", alignItems: "center", paddingBottom: 5 }}>
                                    <Icon icon="tdesign:attach" style={{ color: "black" }} />
                                    <label style={{ paddingLeft: 8 }}>ไฟล์เเนบ (.pdf, .docx, .xlsx, .csv)</label>
                                </div>
                                {!reasonsInspectTaskFailureFileURL?.fileUrl ?
                                    <div style={{ padding: 24, display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#EEEEEE" }} >
                                        <label>ไม่มีไฟล์</label>
                                    </div>
                                    :
                                    <div style={{ display: "flex", border: "2px solid #EEEEEE", borderRadius: 6, padding: 12 }}>
                                        <div
                                            style={{ display: "flex", cursor: "pointer" }}
                                            onClick={() => {
                                                downloadFile(reasonsInspectTaskFailureFileURL.fileUrl, "File reasons failure")
                                            }}
                                        >
                                            <Icon icon="fa:file" style={{ width: 70, height: 70, color: "#429321" }} />
                                            <label style={{ paddingLeft: 6, paddingRight: 30, cursor: "pointer" }}>
                                                File reasons failure
                                            </label>
                                        </div>
                                        {permission("task", "MTK-0021") ? // [MTK-0021] Delete attach file reasons inspect task failure
                                            <div
                                                style={{
                                                    zIndex: 2,
                                                    position: "absolute",
                                                    left: "84%",
                                                    top: "22%",
                                                    cursor: "pointer",
                                                    backgroundColor: "white",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: 6,
                                                    borderRadius: 20,
                                                    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)"
                                                }}
                                                onClick={async () => {
                                                    await handleReasonsInspectTaskFailureFileDelete(reasonsInspectTaskFailureFileURL.taskId)
                                                }}
                                            >
                                                <Icon icon="fluent:delete-24-regular" style={{ color: "red", width: 20, height: 20 }} />
                                            </div>
                                            : []
                                        }
                                    </div>
                                }
                            </div>

                            {permission("task", "MTK-0024") ? // [MTK-0024] Upload attach file reasons inspect task failure
                                <Upload
                                    {...optionReasonsInspectTaskFailureFile}
                                    accept='.pdf, .docx, .xlsx, .csv'
                                    style={{ width: "100%" }}
                                    maxCount={1}
                                    showUploadList={false}
                                >
                                    <Button
                                        type="primary"
                                        style={{ width: "100%" }}
                                        icon={reasonsInspectTaskFailureFileURL.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                    >อัพโหลดไฟล์</Button>
                                </Upload>
                                : []
                            }
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}