import { server } from "./../../../constants"
import { httpClient } from "./../../../utils/HttpClient"

const getEmployeeAuthSignInFetch = async (username, password) => { // Done
    try {
        const result = await httpClient.post("backoffice_auth/get-employee-auth-sign-in", { username, password })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getEmployeeAuthSignInFetch,

    // insert

    // update

    // delete
}