/* eslint-disable no-useless-concat */
import { httpClient } from "../../../../../utils/HttpClient"

const getEmployeeSearchFetch = async (value) => {
    try {
        const result = await httpClient.post("employee/get-employee-search", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getEmployeeLevelAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-employee-level-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getEmployeeDepartmentAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-employee-department-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getBankAllFetch = async () => {
    try {
        const result = await httpClient.get("employee/get-bank-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getEmployeePositionAllFetch = async (value) => {
    try {
        const result = await httpClient.post("category/get-employee-position-all", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertEmployeeFetch = async (employee, employee_auth) => {
    try {
        const result = await httpClient.post("employee/insert-employee", { employee, employee_auth })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateEmployeeByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("employee/update-employee-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteEmployeeByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("employee/delete-employee-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getEmployeeSearchFetch,
    getEmployeeLevelAllFetch,
    getEmployeeDepartmentAllFetch,
    getBankAllFetch,
    getEmployeePositionAllFetch,

    // insert
    insertEmployeeFetch,

    // update
    updateEmployeeByIdFetch,

    // delete
    deleteEmployeeByIdFetch
}
