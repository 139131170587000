/* eslint-disable import/no-anonymous-default-export */
import { 
    HTTP_SEARCH_BLOG_CATEGORY_FETCHING, 
    HTTP_SEARCH_BLOG_CATEGORY_SUCCESS, 
    HTTP_SEARCH_BLOG_CATEGORY_FAILED 
} from "../constants"

const initialState = {
    blogCategoryId : null,
    logo : null,
    name : null,
    isActive: false
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

        case HTTP_SEARCH_BLOG_CATEGORY_FETCHING:
            return { ...state, resultSearchBlogCategory : null, isFetching : true, isError : false }
            
        case HTTP_SEARCH_BLOG_CATEGORY_SUCCESS:
            return { ...state, resultSearchBlogCategory : payload, isFetching : false, isError : false }

        case HTTP_SEARCH_BLOG_CATEGORY_FAILED:
            return { ...state, resultSearchBlogCategory : null, isFetching : false, isError : true }

        default:
            return state
    }
}
