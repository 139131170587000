const permission = (menu, code) => {
    const auth = JSON.parse(sessionStorage.getItem('auth'))
    const roles = auth?.roles ?? []
    // console.log("roles : ", roles, menu)

    if (menu === "project") {
        return permissionManageProject(roles, code)
    } else if (menu === "task") {
        return permissionManageTask(roles, code)
    } else if (menu === "case") {
        return permissionmanageCase(roles, code)
    } else if (menu === "employee") {
        return permissionManageEmployee(roles, code)
    } else if (menu === "customer") {
        return permissionManageCustomer(roles, code)
    } else if (menu === "employee-cost") {
        return permissionManageEmployeeCost(roles, code)
    } else if (menu === "erp") {
        return permissionErp(roles, code)
    }

    return false
}

const permissionManageProject = (roles, code) => {
    // [MPJ-0001] Drag drop task
    // [MPJ-0002] Show all project details
    // [MPJ-0003] Edit project
    // [MPJ-0004] Delete project
    // [MPJ-0005] Title modal manage tasks all in project
    // [MPJ-0006] Edit project type
    // [MPJ-0007] Delete project type
    // [MPJ-0008] Show all project phase/round

    if (roles.includes(code)) {
        return true
    }

    return false
}

const permissionManageTask = (roles, code) => {
    // [MTK-0001] Assign tasks to any employees
    // [MTK-0002] Delete task
    // [MTK-0003] Search for a list of tasks for any employee
    // [MTK-0004] Show all task process list
    // [MTK-0005] Show all task details
    // [MTK-0006] Shows all task columns available for display
    // [MTK-0007] Show column payment process list
    // [MTK-0008] Show dropdown to select task owner
    // [MTK-0009] Show textArea for write feedback
    // [MTK-0010] Show Input number for enter employee cost
    // [MTK-0011] Show dropdown to select isActive of task
    // [MTK-0012] Dispay row task selection
    // [MTK-0013] Show column employee cost by task
    // [MTK-0014] Manage task approve
    // [MTK-0015] Show sum employee cost mode manage accounts 
    // [MTK-0016] Create payment schedule
    // [MTK-0017] Show confirm click form submit for update reasons inspect task failure
    // [MTK-0018] Write reasons inspect task failure
    // [MTK-0019] Delete attach image reasons inspect task failure
    // [MTK-0020] Delete attach video reasons inspect task failure
    // [MTK-0021] Delete attach file reasons inspect task failure
    // [MTK-0022] Upload attach image reasons inspect task failure
    // [MTK-0023] Upload attach video reasons inspect task failure
    // [MTK-0024] Upload attach file reasons inspect task failure
    // [MTK-0025] Show Input number for enter operation cost
    // [MTK-0026] Show column operation cost by task
    // [MTK-0027] Show sum operation cost mode manage accounts 
    // [MTK-0028] Show Input number for enter operation discount
    // [MTK-0029] Show column operation discount by task
    // [MTK-0030] Show sum operation discount mode manage accounts 

    if (roles.includes(code)) {
        return true
    }

    return false
}

const permissionmanageCase = (roles, code) => {
    // [MCS-0001] Edit case
    // [MCS-0002] Delete case
    // [MCS-0003] Clear reasons infomation inspect case failure
    // [MCS-0004] Show all case process list
    // [MCS-0005] Assign cases to any employees
    // [MCS-0006] Show dropdown to select case owner
    // [MCS-0007] Hilight search project
    // [MCS-0008] Add case
    // [MCS-0009] Dispay row case selection
    // [MCS-0010] Show sum employee expenses mode manage accounts
    // [MCS-0011] Create payment schedule
    // [MCS-0012] Show confirm click form submit for update reasons inspect case failure
    // [MCS-0013] Write reasons inspect case failure
    // [MCS-0014] Delete attach image reasons inspect case failure
    // [MCS-0015] Delete attach video reasons inspect case failure
    // [MCS-0016] Delete attach file reasons inspect case failure
    // [MCS-0017] Upload attach image reasons inspect case failure
    // [MCS-0018]z Upload attach video reasons inspect case failure
    // [MCS-0019] Upload attach file reasons inspect case failure

    if (roles.includes(code)) {
        return true
    }

    return false
}

const permissionManageEmployeeCost = (roles, code) => {
    // [MEE-0001] Show all employee expenses details
    // [MEE-0002] Edit employee expenses
    // [MEE-0003] Delete employee expenses
    // [MEE-0004] Search for a list of employee cost for any employee
    // [MEE-0005] Show sum employee expenses mode manage accounts
    // [MEE-0006] Show employee expenses detail mode manage accounts
    // [MEE-0007] Show column employee expenses list
    // [MEE-0008] Pay employees and change the status of the operation

    if (roles.includes(code)) {
        return true
    }

    return false
}

const permissionErp = (roles, code) => {
    // [ERP-0001] Manage project
    // [ERP-0002] Manage case
    // [ERP-0003] Manage employee
    // [ERP-0004] Manage customer
    // [ERP-0005] manage employee expenses
    // [ERP-0006] Manage document

    if (roles.includes(code)) {
        return true
    }

    return false
}

const permissionManageEmployee = (roles, code) => {

}

const permissionManageCustomer = (roles, code) => {

}

export {
    permission,
}