import { Icon } from '@iconify/react'

export const detactItemCreateLatest = (utcTime, rangeDay) => {
    // แปลง UTC time string เป็น Date object
    const givenTime = new Date(utcTime);

    // ตั้งค่า timezone เป็น UTC+7
    const thailandOffset = 7 * 60; // ชั่วโมง * นาที
    const localTime = new Date(givenTime.getTime() + (thailandOffset * 60 * 1000));

    // รับเวลาในไทยตอนนี้
    const currentTime = new Date();

    // คำนวณเวลาที่แตกต่างกัน
    const timeDifference = currentTime - localTime;

    // เช็คว่าเวลาที่กำหนดน้อยกว่า 2 วัน (48 ชั่วโมง)
    if (timeDifference < (rangeDay * 24 * 60 * 60 * 1000)) { // 48 ชั่วโมงในมิลลิวินาที
        return (
            <div style={{ paddingRight: 6, height: 15 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 36 36"><path fill="#429321" d="M36 32a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4z"/><path fill="#fff" d="M1.527 13.121c0-.85.544-1.326 1.275-1.326c.323 0 .85.255 1.071.561l5.388 7.191h.034v-6.426c0-.85.544-1.326 1.275-1.326s1.275.476 1.275 1.326v9.655c0 .85-.544 1.325-1.275 1.325c-.323 0-.833-.255-1.071-.561L4.11 16.434h-.033v6.341c0 .85-.544 1.325-1.275 1.325s-1.275-.476-1.275-1.325zm11.067.255c0-.85.425-1.479 1.326-1.479h4.215c.816 0 1.207.578 1.207 1.173c0 .578-.407 1.173-1.207 1.173h-2.992v2.481h2.788c.833 0 1.24.578 1.24 1.173c0 .578-.424 1.173-1.24 1.173h-2.788v2.584h3.145c.816 0 1.206.578 1.206 1.173c0 .578-.407 1.173-1.206 1.173h-4.386c-.748 0-1.309-.51-1.309-1.274v-9.35zm7.31.119a2.2 2.2 0 0 1-.068-.578c0-.544.459-1.122 1.207-1.122c.816 0 1.207.476 1.359 1.224l1.445 7.224h.034l2.21-7.445c.188-.612.697-1.003 1.326-1.003s1.139.391 1.326 1.003l2.209 7.445h.033l1.445-7.224c.152-.748.545-1.224 1.359-1.224c.748 0 1.207.578 1.207 1.122c0 .238-.016.357-.068.578l-2.158 9.281c-.17.714-.73 1.325-1.682 1.325c-.834 0-1.48-.544-1.684-1.24l-1.97-6.561H27.4l-1.972 6.561a1.735 1.735 0 0 1-1.683 1.24c-.952 0-1.514-.611-1.684-1.325z"/></svg>
            </div>
        )
    } else {
        return null
    }
}