/* eslint-disable no-useless-concat */
import { httpClient } from "../../../../../utils/HttpClient"

const getCustomerSearchFetch = async (value) => {
    try {
        const result = await httpClient.post("customer/get-customer-search", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCustomerByIdFetch = async (value) => {
    try {
        const result = await httpClient.post("customer/get-customer-by-id", { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPersonTypeAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-person-type-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPrefixAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-prefix-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getGenderAllFetch = async () => {
    try {
        const result = await httpClient.get("category/get-gender-all")
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertCustomerFetch = async (value) => {
    try {
        const result = await httpClient.post("customer/insert-customer", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateCustomerByIdFetch = async (value) => {
    try {
        const result = await httpClient.put("customer/update-customer-by-id", { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteCustomerByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete("customer/delete-customer-by-id" + `/${id}`)
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getCustomerSearchFetch,
    getPersonTypeAllFetch,
    getCustomerByIdFetch,
    getPrefixAllFetch,
    getGenderAllFetch,

    // insert
    insertCustomerFetch,

    // update
    updateCustomerByIdFetch,

    // delete
    deleteCustomerByIdFetch
}
